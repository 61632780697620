import React, { Component } from 'react'
import { withRouter } from '../App';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import logo from '../../assets/img/logo.png';
import * as ROUTES from '../../constants/routes';
import * as moment from 'moment-business-days';
import SideNav from '../Navbars/SideNav';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AccountBox, Assessment, AutoGraph, Dashboard, ExitToApp, ExpandMore, LiveHelp } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import * as momenttz from 'moment-timezone'
import CalendarIcon from '@mui/icons-material/CalendarToday'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Container, Backdrop, CircularProgress, Hidden } from '@mui/material';

import { firstBreakpoint, secondBreakpoint } from '../../constants/variables';

import Table from '../Table'
import DatePicker from 'react-datepicker';
// import { FALSE } from 'node-sass';

const holidayList = [
  "07-03-2020",
  "09-07-2020",
  "12-25-2020",
  "01-01-2021",
  "01-18-2021",
  "02-15-2021",
  "04-02-2021",
  "05-31-2021",
  "07-05-2021",
  "09-06-2021",
  "12-24-2021",
  "01-17-2022",
  "04-15-2022",
  "05-30-2022",
  "07-04-2022",
  "09-05-2022",
  "01-02-2023",
  "01-16-2023",
  "02-20-2023",
  "04-07-2023",
  "05-29-2023",
  "06-19-2023",
  "07-04-2023",
  "09-04-2023",
  "11-23-2023",
  "12-25-2023"
]

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

moment.updateLocale('us', {
  holidays: holidayList,
  holidayFormat: 'MM-DD-YYYY',
  workingWeekdays: [1, 2, 3, 4, 5],

});

const DateInput = ({ value, onClick }) => {
  return (
    <Grid onClick={onClick} container direction='row' justifyContent='flex-start' justifyContent='flex-start' alignItems='center' style={{ marginBottom: '10px' }}>
      <CalendarIcon style={{ width: 20, height: 20 }} />
      <div
        style={{ color: 'white', fontSize: '12px', paddingLeft: '5px' }}>
        {value}
      </div>
    </Grid>
  );};

const isWeekday = (date) => {
  var d = moment(date, 'MM-DD-YYYY');
  return d.isBusinessDay() && !d.isHoliday();
};

const isBMO = (time) => {
  var time = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "AM" : "PM";
    time[0] = +time[0] % 12 || 12;
  }
  time.join("");
  return time.includes("AM");
};

const onError = (e) => {
  // debugger;
}

class Earnings extends Component {
  
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.state = {
          loading: true,
          noResults: false,
          date: null,
          user: null,
          mobile: false,
          data: [],
          bmo: [],
          nonBmo: [],
          options: []
        };
      }

    GetCurrencyString(premium) {
      var si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'K' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'B' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' }
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      if(premium < 1) {
        return `${premium}`;
      };
      for (i = si.length - 1; i > 0; i--) {
        if (premium >= si[i].value) {
          break;
        }
      }
      return `${(premium / si[i].value).toFixed(2).replace(rx, "$1") + si[i].symbol}`;
    }

    async getData(date = null){
        if(date == null) return;
        this.setState({date, data: null, loading: true, noResults: false})
        var page = 0;
        var length = 0;
        var data = [];
        do{
          const url = `https://api.benzinga.com/api/v2.1/calendar/earnings?page=${page}&pagesize=1000&parameters[date]=${moment(date).format("YYYY-MM-DD")}&token=${"79a4b64d9c9444ddaf5b3ab583ad1e12"}`
          var response = await fetch(url, {headers: {
              'Accept': 'application/json'
            },})
          response = await response.json();
          if(response != undefined && response.earnings != undefined){
            data = data.concat(response.earnings);
            length = response.earnings.length;
            page += 1;
          }
        }while(length == 1000)

        if(moment().diff(date) >= 0){
          this.todayRef = this.props.firebase.activity(moment(date)).once('value', async snapshot => {
            try {
              const optionsObject = snapshot.val();
              const optionsList = Object.keys(optionsObject).map(key => ({
                ...optionsObject[key],
              }));

              var tickers = data.map(i => i.ticker);
              if(tickers.length == 0)
                this.setState({noResults: true});
              for (var i = 0, len = tickers.length; i < len; i++) {
                  const url = `https://api.benzinga.com/api/v1.1/logos?&symbols=${data[i].ticker}&token=${"c4bb77a9e77e426d9f2b04599356aff1"}`
                  var response = await fetch(url, {headers: {
                      'Accept': 'application/json'
                    },})
                  response = await response.json();
                  if(response !== undefined && response !== null && response.logos !== undefined && response.logos !== null && response.logos.length > 0)
                    data[i].logo = response.logos[0].files["90x60_white"];
                
                const arr = optionsList.filter(x => x.ticker == tickers[i]);
                const calls = arr.filter(x => x.put_call == "CALL").length;
                const puts = arr.filter(x => x.put_call == "PUT").length;
                data[i].isBMO = isBMO(data[i].time)
                data[i].calls = calls;
                data[i].puts = puts;
                data[i].flow = arr;
                data[i].eps_surprise_percent_abs = Math.abs(data[i].eps_surprise_percent);
              }
              var bmoData = data.filter(x=> x.isBMO);
              var nonBMO = data.filter(x => !x.isBMO);
              this.setState({
                bmo: bmoData,
                nonBmo: nonBMO,
                loading: false
              });
            } catch (ex) {
              //console.log(ex);
            }
          });
        }else{
          var tickers = data.map(i => i.ticker);
          if(tickers.length == 0)
            this.setState({noResults: true});
          for (var i = 0, len = tickers.length; i < len; i++) {
              const url = `https://api.benzinga.com/api/v1.1/logos?&symbols=${data[i].ticker}&token=${"c4bb77a9e77e426d9f2b04599356aff1"}`
              var response = await fetch(url, {headers: {
                  'Accept': 'application/json'
                },})
              response = await response.json();
              if(response !== undefined && response !== null && response.logos !== undefined && response.logos !== null && response.logos.length > 0)
                data[i].logo = response.logos[0].files["90x60_white"];
              
            data[i].isBMO = isBMO(data[i].time)
          }
          var bmoData = data.filter(x=> x.isBMO);
          var nonBMO = data.filter(x => !x.isBMO);
          this.setState({
            bmo: bmoData,
            nonBmo: nonBMO,
            loading: false
          });
        }
    }

    updateDimensions = () => {
      this.setState({'mobile': window.innerWidth < firstBreakpoint});
    };


    componentDidMount() {
        var date = momenttz().tz('America/Los_Angeles');
        if (!moment(date).isBusinessDay() && !moment(date).isHoliday()) date = moment(date).prevBusinessDay();
        const { authUser } = this.props;
        const user = {
          user_id: authUser ? authUser.uid : null,
          email: authUser ? authUser.email : null,
          name: authUser ? authUser?.name : null,
          avatar: authUser ? authUser?.photoURL : null
        };
        if (user.user_id) {
          this.setState({ user: user, date: date });
          this.props.firebase.doGetPreferences(user.user_id).on('value', snapshot => {
            var prefs = snapshot.val();
            if (!prefs) prefs = { 'audioAlerts': false, 'watchlist': [], 'useEtf': false };
            this.setState({ prefs });
          });
        }
       
        this.getData(date);
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }



    render() {
        // const mobile = false;
        const {user, bmo, nonBmo, date, loading, mobile, noResults} = this.state;
        const handleMenuLogOut = () => {
            this.props.firebase.doSignOut();
            this.props.history.push(ROUTES.LOGIN);
          };

        const { firebase } = this.props;


        return (
            <div>
                 {mobile ? (<Navbar className='nav-block' variant="dark" expand="lg">
          <Navbar.Brand href="/home">
            <div>
              <img src={logo} alt="Logo" height={40} />
            </div>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/home" style={{ minWidth: '80px' }}>
                <div>
                  <Dashboard className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Dashboard</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/flowcast" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>FlowCast</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/earnings" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Earnings</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/stockalerts" style={{ minWidth: '80px' }}>
                <div>
                  <AutoGraph className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Stock Alerts</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/account" style={{ minWidth: '80px' }}>
                <div>
                  <AccountBox className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>My Account</div>
                </div>
              </Nav.Link>
              <Nav.Link href="#" onClick={()=> window.$crisp.push(["do", "helpdesk:search"])} style={{ minWidth: '80px' }}>
                <div>
                  <LiveHelp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Help</div>
                </div>
              </Nav.Link>
              <Nav.Link style={{ minWidth: '80px' }}>
                <div onClick={handleMenuLogOut}>
                  <ExitToApp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Log Out</div>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>) :
          (<SideNav page={"earnings"} user={user} firebase={firebase}  />)
        }
        <div className='below-nav-block' style={{ margin: 0, paddingLeft: mobile ? 0 : 75, paddingRight: mobile ? 0 : 10, height: '100%', width: '100%', minHeight: '100vh', maxHeight: '100vh', minWidth: '100vw', overflowX: 'hidden' }}>
          <h3 className="flowcastHeader" style={{paddingLeft:'15px'}}>{`Earnings `}
          { date != undefined && date != null ?
          (<DatePicker  disabledKeyboardNavigation
                        style={{ justifyContent: 'start', alignItems: 'start', flex: 0, width: '20%', zIndex: 10000 }}
                        selected={new Date(date)}
                        onChange={this.getData}
                        customInput={<DateInput />}

                        style={{ borderRadius: '4px', height: 36, }}
                        minDate={new Date(2020, 6, 7, 16, 0, 0, 0)}
                        maxDate={moment(momenttz().tz('America/Los_Angeles')).add(3, 'months').toDate()}
                        popperClassName='some-custom-class'
                        popperPlacement={'bottom'}
                        disabledKeyboardNavigation
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px'
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport'
                          }
                        }} />) : null
            }
                        </h3> 
                        {noResults ? <span style={{color: 'red', margin: '20px'}}>No Notable Earnings for today</span> : null }
                        {loading ? (<CircularProgress color="secondary" style={{ position: 'absolute', left: '50%', top: '50%' }} />) :
            (<Grid container direction="row" justifyContent="flex-start" alignContent="space-around">
              <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{textAlign: 'center', fontWeight: 'bold',  marginLeft:'15px', marginRight: '15px', color: 'white', backgroundColor: 'rgb(26, 35, 68)', padding: '5px'}}>Before Market Open</div>
                {bmo ? bmo.map(d => (
                    <Grid item key={d.id}>
                      <Card className='earningsItem' style={{backgroundColor: 'unset'}}>
                        <div style={{width:'100%', backgroundColor: 'rgb(26, 35, 68)', display:'table', padding:'5px 10px'}}>
                          <div style={{fontWeight:'bold', display:'inline-block', float:'left', fontSize:'12px'}}>{`${d.ticker}`}</div>
                          <div style={{display:'inline-block', float:'right', fontSize:'12px'}}>{`${d.period} - ${d.period_year}`}</div>
                        </div>

                        <Accordion style={{backgroundColor:'#20293C', height:'100%'}}>
                          {/* {`${JSON.stringify(d)}`} */}
                          <AccordionSummary expandIcon={<ExpandMore style={{ color: 'black' }} />} aria-controls="panel1a-content" style={{ backgroundColor: "white" }}>
                            
                            <img src={d.logo} height={40} style={{marginRight: '20px'}} onError={e=> e.target.source = '../../assets/img/imageicon.png'}/>
                            <div style={{ margin: "auto", fontWeight:'bold', textAlign:'center', verticalAlign: 'center', color: 'rgb(26, 35, 68)'}}>{`${d.name}`}</div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{height:'100%', width: '100%', color: 'white'}}>
                          <table className='epsRevenueTable' style={{marginBottom:'10px', marginTop:'5px'}}>
                            <tbody>
                              <tr>
                                <td>
                                      <div className='epsRevenueHeaderTableHeader'>Calls</div>
                                      <div className='epsRevenueTableValue'>{d.calls != null && d.calls != undefined ? d.calls : "N/A"}</div>
                                    </td>
                                    <td>
                                      <div className='epsRevenueHeaderTableHeader'>Puts</div>
                                      <div className='epsRevenueTableValue'>{d.puts != null && d.puts != undefined ? d.puts : "N/A"}</div>
                                </td>
                            </tr>
                            </tbody>
                          </table>

                          <div style={{fontWeight:'bold'}}>EPS (USD) </div>
                          <table className='epsRevenueTable' style={{marginBottom:'10px'}}>
                            <tbody>
                            <tr>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Expected</div>
                                  <div className='epsRevenueTableValue'>{`${d.eps_est}` == `` ? `` : `${this.GetCurrencyString(d.eps_est)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Reported</div>
                                  <div className='epsRevenueTableValue'>{`${d.eps}` == `` ? `` : `${this.GetCurrencyString(d.eps)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Surprise</div>
                                  <div className='epsRevenueTableValue' style={{color: 'lime'}}>{`${d.eps_surprise_percent_abs}` == `` ? `` : `${(d.eps_surprise_percent_abs* 100).toFixed(2)}%`}</div>
                                </td>
                            </tr></tbody>
                          </table>

                          <div style={{fontWeight:'bold'}}>Revenue (USD) </div>
                          <table className='epsRevenueTable'>
                            <tbody>
                            <tr>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Expected</div>
                                  <div className='epsRevenueTableValue'>{`${d.revenue_est}` == `` ? `` : `${this.GetCurrencyString(d.revenue_est)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Reported</div>
                                  <div className='epsRevenueTableValue'>{`${d.revenue}` == `` ? `` : `${this.GetCurrencyString(d.revenue)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Surprise</div>
                                  <div className='epsRevenueTableValue' style={{color: 'lime'}}>{`${d.revenue_surprise_percent}` == `` ? `` : `${Math.abs((d.revenue_surprise_percent * 100)).toFixed(2)}%`}</div>
                                </td>
                            </tr>
                            </tbody>
                          </table>
                          {
                            d.flow != undefined && d.flow != null && d.flow.length > 0 ?
                          (<Table options={d.flow} date={date} user={user} firebase={firebase} early={false} getDate={this.getDate} getDateRange={this.getDate} isWeekday={isWeekday} />) : (<div style={{textAlign: 'center', height: '200px', width: '100%', verticalAlign: 'center', paddingTop: '90px'}}>No Flow Data Available</div>)
                          }
                          </div>
                         </AccordionDetails>
                        </Accordion>
                      </Card>
                    </Grid>
                )) : null} 
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{textAlign: 'center', fontWeight: 'bold', marginLeft:'15px', marginRight: '15px', color: 'white', backgroundColor: 'rgb(26, 35, 68)', padding: '5px'}}>After Market Close</div>
                {nonBmo ? nonBmo.map(d => (
                    <Grid item key={d.id}>
                      <Card className='earningsItem' style={{backgroundColor: 'unset'}}>
                        <div style={{width:'100%', backgroundColor: 'rgb(26, 35, 68)', display:'table', padding:'5px 10px'}}>
                          <div style={{fontWeight:'bold', display:'inline-block', float:'left', fontSize:'12px'}}>{`${d.ticker}`}</div>
                          <div style={{display:'inline-block', float:'right', fontSize:'12px'}}>{`${d.period} - ${d.period_year}`}</div>
                        </div>

                        <Accordion style={{backgroundColor:'#20293C', height:'100%'}}>
                          {/* {`${JSON.stringify(d)}`} */}
                          <AccordionSummary expandIcon={<ExpandMore style={{ color: 'black' }} />} aria-controls="panel1a-content" style={{ backgroundColor: "white" }}>
                            
                            <img src={d.logo} height={40} style={{marginRight: '20px'}} onError={i => i.target.style.display='none'} />
                            <div style={{ margin: "auto", fontWeight:'bold', textAlign:'center', verticalAlign: 'center', color: 'rgb(26, 35, 68)'}}>{`${d.name}`}</div>
                          </AccordionSummary>
                          <AccordionDetails>
                          <div className="earningsCardTableDiv" style={{height:'100%', width: '100%', color: 'white'}}>
                          <table className='epsRevenueTable' style={{marginBottom:'10px', marginTop:'5px'}}>
                            <tbody>
                            <tr>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Calls</div>
                                  <div className='epsRevenueTableValue'>{d.calls != null && d.calls != undefined ? d.calls : "N/A"}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Puts</div>
                                  <div className='epsRevenueTableValue'>{d.puts != null && d.puts != undefined ? d.puts : "N/A"}</div>
                                </td>
                            </tr>
                            </tbody>
                          </table>

                          <div style={{fontWeight:'bold'}}>EPS (USD) </div>
                          <table className='epsRevenueTable' style={{marginBottom:'10px'}}>
                            <tbody>
                            <tr>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Expected</div>
                                  <div className='epsRevenueTableValue'>{`${d.eps_est}` == `` ? `` : `${this.GetCurrencyString(d.eps_est)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Reported</div>
                                  <div className='epsRevenueTableValue'>{`${d.eps}` == `` ? `` : `${this.GetCurrencyString(d.eps)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Surprise</div>
                                  <div className='epsRevenueTableValue' style={{color: 'lime'}}>{`${d.eps_surprise_percent}` == `` ? `` : `${(d.eps_surprise_percent* 100).toFixed(2)}%`}</div>
                                </td>
                            </tr></tbody>
                          </table>

                          <div style={{fontWeight:'bold'}}>Revenue (USD) </div>
                          <table className='epsRevenueTable'>
                            <tbody>
                            <tr>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Expected</div>
                                  <div className='epsRevenueTableValue'>{`${d.revenue_est}` == `` ? `` : `${this.GetCurrencyString(d.revenue_est)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Reported</div>
                                  <div className='epsRevenueTableValue'>{`${d.revenue}` == `` ? `` : `${this.GetCurrencyString(d.revenue)}`}</div>
                                </td>
                                <td>
                                  <div className='epsRevenueHeaderTableHeader'>Surprise</div>
                                  <div className='epsRevenueTableValue' style={{color: 'lime'}}>{`${d.revenue_surprise_percent}` == `` ? `` : `${(d.revenue_surprise_percent * 100).toFixed(2)}%`}</div>
                                </td>
                            </tr>
                            </tbody>
                          </table>
                          {
                            d.flow != undefined && d.flow != null && d.flow.length > 0 ?
                          (<Table options={d.flow} date={date} user={user} firebase={firebase} early={false} getDate={this.getDate} getDateRange={this.getDate} isWeekday={isWeekday} />) : (<div style={{textAlign: 'center', height: '200px', width: '100%', verticalAlign: 'center', paddingTop: '90px'}}>No Flow Data Available</div>)
                          }
                          </div>
                         </AccordionDetails>
                        </Accordion>
                      </Card>
                    </Grid>
                )) : null} 
                </Grid>
            </Grid>)
    }
        
        </div>
        </div>
        )
    }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(compose(withRouter, withFirebase)(
    Earnings));