//#region Imports
import React, { Component, useRef, useEffect, useState, Fragment, memo } from 'react';
import memoize from 'memoize-one';
import useStateRef from 'react-usestateref'
import Sound from 'react-sound';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { AddBox, ArrowDownward, ImageSearchRounded, SearchRounded, CancelRounded, CallMade, VolumeUp, VolumeOff, LiveHelp, StarOutlined, Dashboard, Assessment, AccountBox, ExitToApp, AssessmentRounded, WbIridescentTwoTone, Remove, ArrowDownwardSharp, ArrowUpward, PlusOneRounded, ExpandMore, ExpandLess, RepeatRounded, AutoGraph } from '@mui/icons-material';
import * as moment from 'moment-business-days';
import * as momenttz from 'moment-timezone'
import { useTable, useBlockLayout, useSortBy, useFilters, useExpanded, useFlexLayout, useResizeColumns, useColumnOrder } from 'react-table'
import { FixedSizeList, VariableSizeList } from 'react-window'
import classNames from "classnames";
import { useExportData } from 'react-table-plugins'
import Papa from "papaparse";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { BreadcrumbItem } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Sidebar from "react-sidebar";
import { Filter, DefaultColumnFilter, SelectColumnFilter } from './filters';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ExportIcon from '../../assets/svg/CallMade.svg';
import NewsIcon from '../../assets/svg/newspaper.svg';
import ChatTabIcon from '../../assets/svg/messenger.svg';
import DoubleUpArrow from '../../assets/svg/DoubleUpArrow.svg';
import DoubleDownArrow from '../../assets/svg/DoubleDownArrow.svg';
import ArrowUpwardRounded from '../../assets/svg/HeaderArrowUp.png';
import ArrowDownwardRounded from '../../assets/svg/HeaderArrowDown.png';

import WidgetBot from '@widgetbot/react-embed'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Badge,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Avatar, CardContent, Grid, LinearProgress, Modal } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container, Backdrop, CircularProgress, Hidden } from '@mui/material';
import { AuthUserContext, withAuthorization } from '../Session';
import tableIcons from './tableicons';
import { TablePagination, TextField, InputAdornment, Tooltip } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import GaugeChart from 'react-gauge-chart'
import DatePicker from 'react-datepicker';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import bull from '../../assets/img/bull.png';
import bear from '../../assets/img/bear.png';
import sweep from '../../assets/img/sweep.png';
import trade from '../../assets/img/trade.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarIcon from '@mui/icons-material/CalendarToday'
import neutral from '../../assets/img/neutral.png';
import logo from '../../assets/img/logo.png';
import tableBranding from '../../assets/img/logoText.png';
import * as ROUTES from '../../constants/routes';
import { withRouter } from '../App';
import { withFirebase } from '../Firebase'
import { compose } from 'recompose';
import ReactTooltip from 'react-tooltip';
import _, { debounce } from 'lodash';
import { withAuthentication } from '../Session';
import alert from '../../assets/audio/alert.mp3'
import { CheckTreePicker } from 'rsuite';
import SideNav from '../Navbars/SideNav';

import { firstBreakpoint, secondBreakpoint } from '../../constants/variables';
import Decimal from 'decimal.js';
//#endregion

const holidayList = [
  "07-03-2020",
  "09-07-2020",
  "12-25-2020",
  "01-01-2021",
  "01-18-2021",
  "02-15-2021",
  "04-02-2021",
  "05-31-2021",
  "07-05-2021",
  "09-06-2021",
  "12-24-2021",
  "01-17-2022",
  "04-15-2022",
  "05-30-2022",
  "07-04-2022",
  "09-05-2022",
]

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

moment.updateLocale('us', {
  holidays: holidayList,
  holidayFormat: 'MM-DD-YYYY',
  workingWeekdays: [1, 2, 3, 4, 5],

});

//#region Consts
const redColor = '#EB4D5C'
const greenColor = '#3fb54d'
const orangeColor = '#FF8C00'
const purpleColor = '#A566CB'
const pinkColor = '#AD2F9D';
const greyColor = '#373d45';
const greenGradient = `linear-gradient(to right, ${greenColor}, #019CAD)`;
const redGradient = `linear-gradient(to right, #ff781f, ${redColor})`;
const orangeGradient = `linear-gradient(to right, ${orangeColor}, #ffcc00)`;
const purpleGradient = `linear-gradient(to right,#7366cb , ${purpleColor})`;
const pinkGradient = `linear-gradient(to top,${greyColor} , ${pinkColor}6B)`;
const greyGradient = `linear-gradient(to right, #949494 , #3f3f3f)`;
const rowGradient = `linear-gradient(to top,${greyColor} , #2b2e36)`;
const chartStyle = {
  alignContent: 'center', justifyContent: 'center'
};


const headerCenter = {
  display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'
};

const center = {
  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
}

const title = {
  fontSize: 12,
  flex: 1
};

const dataTitle = {
  fontSize: 16,
  flex: 1
};

const audioRef = React.createRef();
const tableRef = React.createRef();
//#endregion Consts

Number.prototype.toFixed = function (fixed) {
  var x = new Decimal(Number(this));
  return x.toFixed(fixed).replace(/\.00$/, '');
};

var reTriggerCount = 0;

class WatchListItem extends Component {
  constructor(props) {
    super(props);
    this.calculateUpdate = this.calculateUpdate.bind(this);
    this.state = {
      percentBearish: 0,
      percentBullish: 0,
      percentNeutral: 0,
      watchlistTickerLength: 0,
      item: null
    }
  }

  calculateUpdate(props) {
    if (props == undefined || props == null) return;
    this.setState({ item: props.item.ticker, percentBearish: props.item.percentBearish ?? 0, percentBullish: props.item.percentBullish ?? 0, percentNeutral: props.item.percentNeutral ?? 0, watchlistTickerLength: props.item.watchlistTickerLength ?? 0 })
  }

  componentDidMount() {
    this.calculateUpdate(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.watchlistTickerLength !== this.state.watchlistTickerLength || this.props.item.ticker != this.state.item)
      this.calculateUpdate(this.props);
  }

  render() {
    const { item, percentBearish, percentBullish, percentNeutral, watchlistTickerLength } = this.state;
    if (item === null) return (<div />)
    return (
      <Grid key={`key_ticker_${item}`} id={`id_ticker_${item}`} item direction="row" style={{ lineHeight: '30px', margin: '2.5px 0px', padding: '0px 5px', color: 'white', alignItems: 'center', justifyItems: 'center', width: '100%', height: '30px', background: 'unset', borderRadius: '5px', zIndex: '100', position: 'relative' }}>
        <Grid className="watchlistText" item onClick={() => this.props.Search(item)} style={{ fontSize: '12px', float: 'left', verticalAlign: 'center', fontWeight: 'bold' }}>{`${item}  (${watchlistTickerLength})`}</Grid>
        <div style={{ float: 'right', verticalAlign: 'center' }}><CancelRounded onClick={() => this.props.RemoveWatchlist(item)} fontSize="small" style={{ color: 'white' }} /></div>
        <ProgressBar className='wishlistProgressBar'>
          <ProgressBar key={`key_ticker_bull_${item}`} id={`ticker_bull_${item}`} className='wishlistProgressBarBullish' now={percentBullish} />
          <ProgressBar key={`key_ticker_neut_${item}`} id={`ticker_neut_${item}`} className='wishlistProgressBarNeutral' now={percentNeutral} />
          <ProgressBar key={`key_ticker_bear_${item}`} id={`ticker_bear_${item}`} className='wishlistProgressBarBearish' now={percentBearish} />
        </ProgressBar>
        {watchlistTickerLength > 0 ?
          <>
            <UncontrolledTooltip placement="top" target={`ticker_bull_${item}`} autohide={true} delay={0}>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{`${percentBullish + '% Bullish'}`}</div>
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target={`ticker_neut_${item}`} autohide={true} delay={0}>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{`${percentNeutral + '% Neutral'}`}</div>
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target={`ticker_bear_${item}`} autohide={true} delay={0}>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{`${percentBearish + '% Bearish'}`}</div>
            </UncontrolledTooltip>
          </>
          : null}
      </Grid>
    )
  }
}

class WatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      calculatedList: [],
      data: [],
      loading: false
    }
  }

  componentDidMount(props) {
    if (this.props.list && this.props.list != this.state.list) {
      var tempList = this.state.calculatedList ?? [];
      const list = this.props.list ?? [];
      for (var i = 0; i < list.length - 1; i++) {
        if (tempList[i] == null || tempList[i] == undefined) tempList[i] = {}
        tempList[i].ticker = list[i];
        const data = this.props.data ?? [];
        const item = list[i];
        const watchlistTickerData = data.filter((x) => x.ticker === item);
        tempList[i].watchlistTickerLength = watchlistTickerData.length;
        tempList[i].percentBearish = ((watchlistTickerData.filter((x) => x.sentiment == "BEARISH").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
        tempList[i].percentBullish = ((watchlistTickerData.filter((x) => x.sentiment == "BULLISH").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
        tempList[i].percentNeutral = ((watchlistTickerData.filter((x) => x.sentiment == "NEUTRAL").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
      }
      
      this.setState({ list: this.props.list.sort((a, b) => a.localeCompare(b)) ?? [], calculatedList: tempList.sort((a, b) => a.ticker.localeCompare(b.ticker)) ?? [] })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data != this.state.data || (this.props.list != undefined && this.state.calculatedList != undefined && this.props.list.length != this.state.calculatedList.length)) {
      const list = this.props.list ?? [];
      const data = this.props.data ?? [];
      var tempList = [];
      for (var i = 0; i < list.length; i++) {
        if (!tempList[i]) tempList[i] = {};
        tempList[i].ticker = list[i];
        const item = list[i];
        const watchlistTickerData = data.filter((x) => x.ticker === item);
        tempList[i].watchlistTickerLength = watchlistTickerData.length;
        tempList[i].percentBearish = ((watchlistTickerData.filter((x) => x.sentiment == "BEARISH").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
        tempList[i].percentBullish = ((watchlistTickerData.filter((x) => x.sentiment == "BULLISH").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
        tempList[i].percentNeutral = ((watchlistTickerData.filter((x) => x.sentiment == "NEUTRAL").length / tempList[i].watchlistTickerLength) * 100).toFixed(2);
      }
      
      this.setState({
        list: list.sort((a, b) => a.localeCompare(b)) ?? [],
        calculatedList: tempList.filter(x => x.ticker).sort((a, b) => a.ticker.localeCompare(b.ticker)) ?? [],
        data: data ?? []
      })
    }
  }

  render() {
    const { calculatedList } = this.state;
    const { loading } = this.props;

    return calculatedList.map((item, index) => (<WatchListItem key={index} item={item} Search={this.props.Search} RemoveWatchlist={this.props.RemoveWatchlist} />));
  }
}


function WatchSearch(props) {
  const [watchInput, setWatchInput] = React.useState(props.searchString || '');
  const AddItemKey = (e) => {
    props.keyPress(e);
    if (e.keyCode == 13) setWatchInput("");
  }
  const AddItemButton = () => {
    props.setWatchDoneInput(watchInput);
    props.AddWatchlistButton();
  }
  const Clear = () => {
    setWatchInput("");
    props.setWatchDoneInput("");
  }
  return (
    <>
      <TextField
        id={"watchlistAddSearch"}
        className='addWatch'
        value={watchInput || ''}
        style={{ alignItems: 'end', float: 'right', padding: "0px 15px 0px 15px", borderRadius: "10px", border: "1px solid #9A9A9A" }}
        onChange={(event) => setWatchInput(event.target.value)}
        onBlur={() => props.setWatchDoneInput(watchInput)}
        onKeyDown={AddItemKey}
        placeholder='Add to watchlist'
        variant='standard'
        InputProps={{
          disableUnderline: true,
          style: { color: 'white' },
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip >
                <AddIcon disabled={!watchInput && watchInput != ""} onClick={AddItemButton} fontSize="small" style={{ color: !watchInput && watchInput != "" ? "white" : "grey" }} />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <CancelRounded disabled={!watchInput && watchInput != ""} onClick={Clear} fontSize="small" style={{ color: !watchInput && watchInput != "" ? "white" : "grey" }} />
            </InputAdornment>
          ),
        }}
      />
      <UncontrolledTooltip placement="top" target={"watchlistAddSearch"} autohide={true} delay={0}>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Ex. TSLA or TSLA,AAPL,MSFT</div>
      </UncontrolledTooltip>
    </>
  )
}


//#region HomePageComponent
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.updateTop = this.updateTop.bind(this);
    this.getDate = this.getDate.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.getDateRange = this.getDateRange.bind(this);
    this.processDataRange = this.processDataRange.bind(this);
    this.todayRef = null;
    this.state = {
      loading: true,
      activity: [],
      tempActivity: [],
      news: [],
      newsIndex: 0,
      puts: 0,
      date: null,
      calls: 0,
      bears: 0,
      bulls: 0,
      open: false,
      early: false,
      prefs: { 'audioAlerts': false, wishlist: [] },
      user: null,
      updating: false,
      ticker: "",
      dataTicker: null,
      mobile: false,
      isVideo: false
    };
  }

  startTimer() {
    if (this.state.updating) return;
    this.setState({ updating: true });
    audioRef.current.play();
    setTimeout(() => {
      this.setState({ updating: false })
    }, 45000);
  }

  getDate(overrideDate = null) {
    var date = momenttz().tz('America/Los_Angeles');
    if (!moment(date).isBusinessDay() || moment(date).isHoliday()) date = moment(date).prevBusinessDay();

    if (overrideDate != null && overrideDate != undefined) {
      this.setState({
        activity: [],
        puts: 0,
        calls: 0,
        bulls: 0,
        bears: 0,
        loading: true
      });
      if (this.todayRef != null) {
        this.props.firebase.activity(date).off('value', this.todayRef);
        this.todayRef = null;
      }
      if (date.isSame(momenttz(overrideDate), 'day')) {
        this.todayRef = this.props.firebase.activity(new moment(overrideDate)).on('value', snapshot => {
          try {
            if (this.state.prefs.audioAlerts)
              this.startTimer();
            if (this.state.todaySelected) {
              const optionsObject = snapshot.val();
              const optionsList = Object.keys(optionsObject).map(key => ({
                ...optionsObject[key],
              }));
              var activity = this.state.tempActivity;
              activity = activity.concat(optionsList);
              this.setState({
                activity: activity.sort((a, b) => b.updated - a.updated),
                puts: activity.filter(x => x.put_call === 'PUT').length,
                calls: activity.filter(x => x.put_call === 'CALL').length,
                bulls: activity.filter(x => x.sentiment === 'BULLISH').length,
                bears: activity.filter(x => x.sentiment === 'BEARISH').length,
                tempActivity: [],
                loading: false,
                date: overrideDate,
              });
            } else {
              const optionsObject = snapshot.val();
              const optionsList = Object.keys(optionsObject).map(key => ({
                ...optionsObject[key],
              }));
              this.setState({
                activity: optionsList.sort((a, b) => b.updated - a.updated),
                puts: optionsList.filter(x => x.put_call === 'PUT').length,
                calls: optionsList.filter(x => x.put_call === 'CALL').length,
                bulls: optionsList.filter(x => x.sentiment === 'BULLISH').length,
                bears: optionsList.filter(x => x.sentiment === 'BEARISH').length,
                tempActivity: [],
                loading: false,
                date: overrideDate,
              });
            }
          } catch (ex) {
            //console.log(ex);
          }
        });
      } else {
        this.props.firebase.activity(new moment(overrideDate)).once('value', snapshot => {
          try {
            if (this.state.prefs.audioAlerts)
              this.startTimer();
            if (this.state.todaySelected) {
              const optionsObject = snapshot.val();
              const optionsList = Object.keys(optionsObject).map(key => ({
                ...optionsObject[key],
              }));
              var activity = this.state.tempActivity;
              activity = activity.concat(optionsList);
              this.setState({
                activity: activity.sort((a, b) => b.updated - a.updated),
                puts: activity.filter(x => x.put_call === 'PUT').length,
                calls: activity.filter(x => x.put_call === 'CALL').length,
                bulls: activity.filter(x => x.sentiment === 'BULLISH').length,
                bears: activity.filter(x => x.sentiment === 'BEARISH').length,
                tempActivity: [],
                loading: false,
                date: overrideDate,
              });
            } else {
              const optionsObject = snapshot.val();
              const optionsList = Object.keys(optionsObject).map(key => ({
                ...optionsObject[key],
              }));
              this.setState({
                activity: optionsList.sort((a, b) => b.updated - a.updated),
                puts: optionsList.filter(x => x.put_call === 'PUT').length,
                calls: optionsList.filter(x => x.put_call === 'CALL').length,
                bulls: optionsList.filter(x => x.sentiment === 'BULLISH').length,
                bears: optionsList.filter(x => x.sentiment === 'BEARISH').length,
                tempActivity: [],
                loading: false,
                date: overrideDate,
              });
            }
          } catch (ex) {
            //console.log(ex);
          }
        });
      }
    }

    else return date
  }

  processDataRange(snapshot) {
    try {
      const optionsObject = snapshot.val();
      const optionsList = Object.keys(optionsObject).map(key => ({
        ...optionsObject[key],
      }));
      var activity = this.state.tempActivity;
      activity = activity.concat(optionsList);
      this.setState({ tempActivity: activity });
    } catch (ex) {
      //console.log(ex);
    }
  }

  getDateRange(start, end) {
    var date = momenttz().tz('America/Los_Angeles');
    if (!moment(date).isBusinessDay() || moment(date).isHoliday()) date = moment(date).prevBusinessDay();
    if (this.todayRef != null) {
      this.props.firebase.activity(date).off('value', this.todayRef);
      this.todayRef = null;
    }
    var activityTempVar = [];
    this.setState({ loading: true, tempActivity: [] }, async () => {

      // If today is not in date range, set to today in range

      if (moment(end).isSame(date, 'day')) {
        this.setState({ todaySelected: true });
      } else {
        this.setState({ todaySelected: false });
      }

      var tempDate = moment(start);
      while (tempDate.isSameOrBefore(moment(end))) {
        if (moment(tempDate).isSame(date, 'day')) {
          this.getDate(tempDate);
          return;
        } else {
          const snapshot = await this.props.firebase.activity(tempDate).once('value');
          const optionsObject = snapshot.val();
          const optionsList = Object.keys(optionsObject).map(key => ({
            ...optionsObject[key],
          }));
          activityTempVar = activityTempVar.concat(optionsList);
          this.setState({ tempActivity: activityTempVar });
        }
        tempDate = tempDate.businessAdd(1, 'day');
      }
      
      if (this.state.prefs.audioAlerts)
        this.startTimer();
      this.setState({
        activity: activityTempVar.sort((a, b) => b.updated - a.updated),
        puts: activityTempVar.filter(x => x.put_call === 'PUT').length,
        calls: activityTempVar.filter(x => x.put_call === 'CALL').length,
        bulls: activityTempVar.filter(x => x.sentiment === 'BULLISH').length,
        bears: activityTempVar.filter(x => x.sentiment === 'BEARISH').length,
        tempActivity: [],
        loading: false,
      });
    });
  }

  componentDidMount() {
    var date = this.getDate(null);
    var { authUser } = this.props;
    const user = {
      user_id: authUser ? authUser.uid : null,
      email: authUser ? authUser.email : null,
      name: authUser ? authUser?.name : null,
      avatar: authUser ? authUser?.photoURL : null
    };
    if (user.user_id) {
      this.setState({ user: user });
      this.props.firebase.doGetPreferences(user.user_id).on('value', snapshot => {
        var prefs = snapshot.val();
        if (!prefs) prefs = { 'audioAlerts': false, 'watchlist': [], 'useEtf': false };
        this.setState({ prefs });
      });
    };
    this.setState({ loading: true, date });
    try {
      this.props.firebase.news().on('value', snapshot => {
        try {
          this.setState({ "news": Object.values(snapshot.val()).filter(x => x.text.length < 200) ?? [{ 0: { "text": "No new news" } }], "newsIndex": 0 })
        } catch (ex) {
          //console.log(ex);
        }
      });
    } catch (ex) {
      //console.log(ex)
    }
    const today = momenttz().tz('America/Los_Angeles');;
    var dt = moment(date);
    var dtsixthirty = moment(dt.format("YYYY-MM-DD")).set('hour', 6).set('minute', 30);
    if (dt.isBefore(dtsixthirty) && dt.day == today.day && dt.month == today.month && dt.year == today.year && moment(today).isBusinessDay() && !moment(today).isHoliday()) {
      var reload = () => {
        //console.log("Reloading");
        window.location.reload();
      }
      window.setInterval(reload, dtsixthirty.diff(dt, 'milliseconds'));
      this.setState({ early: true })
    } else {
      this.todayRef = this.props.firebase.activity(date).on('value', snapshot => {
        try {
          const optionsObject = snapshot.val();
          const optionsList = Object.keys(optionsObject).map(key => ({
            ...optionsObject[key],
          }));
          if (this.state.prefs.audioAlerts)
            this.startTimer();
          this.setState({
            activity: optionsList.sort((a, b) => b.updated - a.updated),
            puts: optionsList.filter(x => x.put_call === 'PUT').length,
            calls: optionsList.filter(x => x.put_call === 'CALL').length,
            bulls: optionsList.filter(x => x.sentiment === 'BULLISH').length,
            bears: optionsList.filter(x => x.sentiment === 'BEARISH').length,
            loading: false,
            date
          });
        } catch (ex) {
          //console.log(ex);
        }
      });
    }
  }

  //#region Render Progress Bars on Side -----------------------------------
  renderPutToCall() {
    const current = this.state.activity;
    const put = this.state.puts;
    const call = this.state.calls;
    const percent = call === 0 ? 0 : (put / call).toFixed(2);
    const graphPercent = isNaN(percent) ? 0 : percent;

    const temp = {
      series: [graphPercent * 100],
      options: {
        chart: {
          height: 150,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        colors: ['#2979ff'],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '60%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              strokeWidth: '79%',
              margin: 0, // margin is in pixels
              background: '#273A2D',
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '12px'
              },
              value: {
                formatter: function (val) {
                  return (`${(parseFloat(val) / 100).toFixed(2)}`);
                },
                color: '#FFFFFF',
                offsetY: 5,
                fontSize: '12px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: [`#2979ff6B`],
            stops: [-135, 225]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      }
    };
    const value = graphPercent * 100
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={9} alignItems="flex-end">
          <p style={{ color: "white" }}>Put to Call</p>
          <ProgressBar now={value} className={"maxed-sweepscore"} style={{ height: "16px" }} />
        </Grid>
        <Grid container item alignItems="flex-end" direction="column" xs={3}>
          <Grid item style={{ display: 'block', color: "white", fontSize: "13px" }}>{graphPercent}</Grid>
        </Grid>
      </Grid>
    )
  }
  renderCalls() {
    const current = this.state.activity;
    const put = this.state.puts;
    const call = this.state.calls;
    const percent = isNaN((call / (put + call)).toFixed(2)) ? 0 : (call / (put + call)).toFixed(2);
    const temp = {
      series: [percent * 100],
      options: {
        chart: {
          margin: 0,
          padding: 0,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        colors: [greenColor],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '60%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              strokeWidth: '79%',
              margin: 0, // margin is in pixels
              background: '#273A2D',
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '12px'
              },
              value: {
                formatter: function (val, opts) {
                  return `${parseInt(val)}%`;
                },
                color: '#FFFFFF',
                offsetY: 5,
                maxValue: 1.0,
                fontSize: '12px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: [`${greenColor}6B`],
            stops: [-135, 225]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      }
    };
    // const value = percent*100
    // return (
    //   <div>
    //     <ProgressBar now={value} variant="info" style={{height: "16px"}}/>
    //   </div>
    // )

    const value = percent * 100
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={9}>
          <p style={{ color: "white" }}>Call total</p>
          <ProgressBar now={value} className={"maxed-sweepscore"} style={{ height: "16px" }} />
        </Grid>
        <Grid container item alignItems="flex-end" direction="column" xs={3}>
          <Grid item style={{ display: 'block', color: "white", fontWeight: 'bold', size: "16px", marginBottom: "2px" }}>{call}</Grid>
          <Grid item style={{ display: 'block', color: "white", fontSize: "13px" }}>{value.toFixed(1)}%</Grid>
        </Grid>
      </Grid>
    )
  }

  renderPuts() {
    const current = this.state.activity;
    const put = this.state.puts;
    const call = this.state.calls;
    const percent = (put == 0 && call == 0) ? 0 : isNaN((put / (put + call)).toFixed(2)) ? 0 : ((put / (put + call)).toFixed(2));

    const temp = {
      series: [percent * 100],
      options: {
        chart: {
          height: 125,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        colors: [redColor],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '60%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              strokeWidth: '79%',
              margin: 0, // margin is in pixels
              background: '#273A2D',
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '12px'
              },
              value: {
                formatter: function (val) {
                  return `${parseInt(val)}%`;
                },
                color: '#FFFFFF',
                offsetY: 5,
                fontSize: '12px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            gradientToColors: [`${redColor}6B`],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      }
    };

    const value = percent * 100
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={9}>
          <p style={{ color: "white" }}>Put total</p>
          <ProgressBar now={value} className={"maxed-sweepscore"} style={{ height: "16px" }} />
        </Grid>
        <Grid container item alignItems="flex-end" direction="column" xs={3}>
          <Grid item style={{ display: 'block', color: "white", fontWeight: 'bold', size: "16px", marginBottom: "2px" }}>{put}</Grid>
          <Grid item style={{ display: 'block', color: "white", fontSize: "13px" }}>{value.toFixed(1)}%</Grid>
        </Grid>
      </Grid>
    )


  }

  renderSentimentContent() {
    const bulls = this.state.bulls;
    const bears = this.state.bears;
    const isBearish = bears > bulls;

    const temp = {
      series: [(isBearish ? (bears / (bulls + bears)) : (bulls / (bulls + bears))) * 100],
      options: {
        chart: {
          height: 125,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        colors: [isBearish ? redColor : greenColor],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '60%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              strokeWidth: '79%',
              margin: 0, // margin is in pixels
              background: '#273A2D',
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '12px'
              },
              value: {
                formatter: function (val) {
                  return (`${val.toFixed(1)}%`);
                },
                color: '#FFFFFF',
                offsetY: 5,
                fontSize: '12px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: [isBearish ? `${redColor}6B` : `${greenColor}6B`],
            stops: [-135, 225]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      }
    };
    const value = (bulls == 0 && bears == 0) ? 0 : ((isBearish ? (bears / (bulls + bears)) : (bulls / (bulls + bears))) * 100);
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={9}>
          <p style={{ color: "white" }}>Sentiment</p>
          <ProgressBar now={value} className={"maxed-sweepscore"} style={{ height: "16px" }} />
        </Grid>
        <Grid container item alignItems="flex-end" direction="column" xs={3}>
          <Grid item style={{ display: 'block', color: "white", fontWeight: 'bold', size: "16px", marginBottom: "2px" }}>{isBearish ? 'Bearish' : 'Bullish'}</Grid>
          <Grid item style={{ display: 'block', color: "white", fontSize: "13px" }}>{value.toFixed(1)}%</Grid>
        </Grid>
      </Grid>
    )

    // if(bears < bulls){
    //   return( <Chart options={temp.options} series={temp.series} type='radialBar' height={125} width={125}  /> )
    // }else if(bears > bulls){
    //   return(<Chart options={temp.options} series={temp.series} type='radialBar' height={125} width={125}  />)
    // }else if(bulls == bears){
    //   return( <img src={neutral} width={103} height={103}/> )
    // }else return (<div>N/A</div>)
  }
  //#endregion -------------------------------------------------------------

  updateTop(data) {
    this.setState({
      puts: data.filter(x => x.put_call === 'PUT').length,
      calls: data.filter(x => x.put_call === 'CALL').length,
      bulls: data.filter(x => x.sentiment == "BULLISH").length,
      bears: data.filter(x => x.sentiment == "BEARISH").length,
    });
  };

  isWeekday = (date) => {
    var d = moment(date, 'MM-DD-YYYY');
    return d.isBusinessDay() && !d.isHoliday() && moment().diff(date) >= 0;
  };


  componentWillUnmount() {
    try {
      this.props.firebase.news().off();
      var date = this.state.date;
      if (date != null)
        this.props.firebase.activity(date.toString()).off();
    } catch (ex) {
      //console.log(ex);
    }
  }

  render() {
    const { activity, loading, date, prefs, user, updating, puts, calls, bulls, bears, open, ticker, early, mobile } = this.state;

    const isBearish = bears > bulls;
    const time = momenttz();
    const tzName = moment.tz.guess();
    const abbr = moment.tz(tzName).zoneAbbr();
    const { firebase } = this.props;
    const handleMenuLogOut = () => {
      this.props.firebase.doSignOut();
      this.props.history.push(ROUTES.LOGIN);

    };

    const handleOpen = (data) => {
      this.setState({ 'dataTicker': data, 'modalOpen': true })
    };

    const handleVideoOpen = () => {
      this.setState({ 'modalOpen': true, 'isVideo': true })
    };

    const handleClose = () => {
      this.setState({ 'dataTicker': null, 'modalOpen': false, 'isVideo': false })
    };

    const setMobile = (isMobile) => {
      this.setState({ 'mobile': isMobile })
    }

    const routes = [
      { path: "/home", name: "Home", icon: bear, layout: "" },
      { path: "/snapshot", name: "Snapshot", icon: bear, layout: "" },
      { path: "/account", name: "Account Settings", icon: bear, layout: "" }
    ]
    const updatePrefs = (event) => {
      if (!prefs) prefs = { 'audioAlerts': false, 'watchlist': [], 'useEtf': false };
      prefs.audioAlerts = !prefs.audioAlerts;
      firebase.doSetPreferences(user.user_id, prefs);
    };

    return (
      <div id="main-outer-div">
        {mobile ? (<Navbar className='nav-block' variant="dark" expand="lg">
          <Navbar.Brand href="/home">
            <div>
              <img src={logo} alt="Logo" height={40} />
            </div>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/home" style={{ minWidth: '80px' }}>
                <div>
                  <Dashboard className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Dashboard</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/flowcast" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>FlowCast</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/earnings" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Earnings</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/stockalerts" style={{ minWidth: '80px' }}>
                <div>
                  <AutoGraph className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Stock Alerts</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/account" style={{ minWidth: '80px' }}>
                <div>
                  <AccountBox className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>My Account</div>
                </div>
              </Nav.Link>
              <Nav.Link href="#" onClick={() => window.$crisp.push(["do", "helpdesk:search"])} style={{ minWidth: '80px' }}>
                <div>
                  <LiveHelp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Help</div>
                </div>
              </Nav.Link>
              <Nav.Link style={{ minWidth: '80px' }}>
                <div onClick={handleMenuLogOut}>
                  <ExitToApp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Log Out</div>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>) :
          (<SideNav page={"home"} user={user} firebase={firebase} prefs={prefs} updatePrefs={updatePrefs} openVideo={handleVideoOpen} />)
        }

        <div className='below-nav-block' style={{ margin: 0, paddingLeft: mobile ? 0 : 75, paddingRight: mobile ? 0 : 10, height: '100%', width: '100%', minHeight: '100vh', maxHeight: '100vh', minWidth: '100vw', overflowX: 'hidden' }}>
          {prefs && prefs.audioAlerts && playAlert()}
          <StockTable news={this.state.news} puttocall={this.renderPutToCall()} put={this.renderPuts()} call={this.renderCalls()} sentiment={this.renderSentimentContent()} options={activity} user={user} firebase={firebase} date={date} prefs={prefs} updateTop={this.updateTop} isWeekday={this.isWeekday} style={{ maxWidth: 400, height: "100%" }} getDate={this.getDate} getDateRange={this.getDateRange} loading={loading} handleOpen={handleOpen} handleClose={handleClose} early={early} mobile={mobile} setMobile={setMobile} />
        </div>
        {(this.state.modalOpen != undefined && this.state.modalOpen != null) &&
          (<ChartPopup open={this.state.modalOpen} handleClose={handleClose} data={this.state.dataTicker} isVideo={this.state.isVideo} />)}
      </div>
    )
  }
}

const ChartPopup = ({ handleClose, open, data, isVideo = false }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: "translate(-50%, -50%)"
    }
  }));
  const classes = useStyles();


  const body =
    <div style={{ color: "black" }} className={[classes.paper]}>
      {!isVideo ? (<TradingViewWidget symbol={data}
        theme={Themes.DARK} />) : (<iframe width="750" height="425" src="https://www.youtube.com/embed/z50EUMu41gU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full-screen" allowfullscreen></iframe>)
      }
    </div>;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
}
//#endregion HomePageComponent

//#region StockTable

function ChartColumn(handleOpen, data, Search, useRange) {
  const id = `chart_${data.row.original.id}`;
  const filtered = data.data.filter(x => x.ticker == data.row.original.ticker && x.date_expiration == data.row.original.date_expiration && x.strike_price == data.row.original.strike_price && x.option_activity_type == "SWEEP");
  const count = filtered.length || 1;
  const bullPct = filtered.filter(x => x.sentiment == "BULLISH").length / filtered.length;
  const neutPct = filtered.filter(x => x.sentiment == "NEUTRAL").length / filtered.length;
  const bearPct = filtered.filter(x => x.sentiment == "BEARISH").length / filtered.length;
  const displayString = neutPct > .5 ? `Repeated ${count.toFixed(2)} times. ${(neutPct * 100).toFixed(2)}% Neutral` : bullPct > .5 ? `Repeated ${count.toFixed(2)} times. ${(bullPct * 100).toFixed(2)}% Bullish` : `Repeated ${count.toFixed(2)} times. ${(bearPct * 100).toFixed(2)}% Bearish`;
  return (
    <div>
      <Grid container direction="row" justifyContent="start" alignItems="center" style={{ paddingRight: '2px', textAlign: 'start' }}>
        <Grid item ><AssessmentRounded id={id} style={{ color: 'white', }} onClick={() => handleOpen(data.row.original.ticker)} /></Grid>
        <Grid item justifyContent="center" alignContent="center" style={{ width: '18px', height: '18px' }} >{count > 1 && !useRange && data.row.original.option_activity_type == "SWEEP" ? bullPct == bearPct ? (<RepeatRounded id={`${id}_repeat`} onClick={() => Search(data.row.original.ticker)} style={{ borderRadius: 2, width: '18px', height: '18px', background: `linear-gradient(90deg, rgba(12,209,125,1) 0%, rgba(248,114,64,1) 100%, rgba(0,212,255,1) 100%)` }} />) : (<RepeatRounded id={`${id}_repeat`} onClick={() => Search(data.row.original.ticker)} style={{ borderRadius: 2, width: '18px', height: '18px', background: neutPct > .5 ? "#838383" : bullPct > .5 ? '#0CD17D' : '#F87240' }} />) : null}</Grid>
      </Grid>
      <UncontrolledTooltip placement="top" target={id} delay={0}>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{`View Chart for ${data.row.original.ticker}`}</div>
      </UncontrolledTooltip>
      {count > 1 && !useRange && data.row.original.option_activity_type == "SWEEP" ? (<UncontrolledTooltip placement="top" target={`${id}_repeat`} delay={0}>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{displayString}</div>
      </UncontrolledTooltip>) : null}
    </div>
  )
}

function FormatTicker(data, Search) {

  return (
    <div className="tickerColumn" onClick={() => Search(data)} style={{ margin: 'auto', justifyContent: "space-between", paddingRight: '10px', textAlign: 'center' }}>
      {data}
    </div>
  )
}

function DateRow(dateTime, useRange) {
  return (
    <div style={{ justifyContent: 'start' }}>
      {useRange ? moment.unix(dateTime).tz("America/New_York").format(' M/D h:mm a') : moment.unix(dateTime).tz("America/New_York").format('h:mm a')}
    </div>
  )
};

function FormatInfo(rowData) {
  const formatted = `${rowData.size} @ ${parseFloat(rowData.price).toFixed(2)}`;
  return (<div>{formatted}</div>)
}

function FormatCallPut(rowData) {
  const id = `putcall_${rowData.row.original.id}`;
  var data = rowData.row.original.description_extended;
  if (!data.includes("Ref")) {
    data = data.concat(`; ${rowData.row.original.size} @ $${(parseFloat(rowData.row.original.cost_basis / rowData.row.original.size) / 100).toFixed(2)} vs ${rowData.row.original.open_interest} OI`);
  }
  const callPut = rowData.row.original.put_call;

  return (
    <div>
      <div id={id} className={callPut === 'CALL' ? 'callBadge' : 'putBadge'} style={{ color: '#FFFFFF', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 60, display: 'inline-block' }}>{callPut}</div>
      <UncontrolledTooltip placement="top" target={id} delay={0}>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{data}</div>
      </UncontrolledTooltip>
    </div>
  )
}

function FormatType(data) {
  const id = `type_${data.row.original.id}`;
  if (data.row.original.option_activity_type == 'SWEEP') {
    return (
      <div>
        <div id={id} className={'sweepBadge'} style={{ color: '#FFFFFF', backgroundColor: '#28ABDD', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 68, display: 'inline-block' }}>SWEEP</div>
        <UncontrolledTooltip placement="top" target={id} delay={0} style={{ width: 300 }}>
          <div >
            <div>A sweep order is a type of market order that fills by taking all liquidity at the best price until the entire order is filled. This signals an aggressive buyer or seller.</div>
          </div>
        </UncontrolledTooltip>
      </div>);
  }
  if (data.row.original.option_activity_type == 'TRADE') {
    return (
      <div>
        <div id={id} className={'tradeBadge'} style={{ color: '#FFFFFF', backgroundColor: '#685AD2', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 68, display: 'inline-block' }}>TRADE</div>
        <UncontrolledTooltip placement="top" target={id} delay={0}>
          <div>
            <div>Trade refers to Block Trade where an entire is set to fill at a certain price point. Less aggressive buyer or seller.</div>
          </div>
        </UncontrolledTooltip>
      </div>);
  }
  return (<div>{data.row.original.option_activity_type}</div>)
}

function GetPremiumString(premium) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'MM' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (premium >= si[i].value) {
      break;
    }
  }
  return `$${(premium / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol}`;
}

function FormatPremium(premium) {
  return <div style={{ textAlign: "center" }}>{GetPremiumString(premium)}</div>;
}

function FormatAggr(rowData) {
  const value = (parseFloat(rowData.value) < 0 ? 0 : parseFloat(rowData.value) > 1 ? 1 : parseFloat(rowData.value)) * 100;
  return (
    <div style={{ paddingTop: 6, paddingLeft: 10, paddingRight: 10 }}>
      <ProgressBar id={`sweepscore_${rowData.row.original.id}`} now={value} className={parseFloat(rowData.value) > 1 ? "maxed-sweepscore" : "normal-sweepscore"} />
      <UncontrolledTooltip placement="right" target={`sweepscore_${rowData.row.original.id}`} delay={0}>
        {<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>SweepScore: {(parseFloat(rowData.value) * 100).toFixed}/100</div>}
      </UncontrolledTooltip>
    </div>
  )
}
function FormatAggrExpand(sweepscore) {
  const value = (parseFloat(sweepscore) < 0 ? 0 : parseFloat(sweepscore) > 1 ? 1 : parseFloat(sweepscore)) * 100;
  return (
    <div style={{ color: 'white', fontSize: '12px' }}>
      {value} / 100
    </div>
  )
}

function FormatRef(description) {
  const str = description.match(/Ref=\$\d*.\d*/g)[0].replace("Ref=", "");
  return (
    <div style={{ color: 'white', fontSize: '12px' }}>
      {str}
    </div>
  )
}


function FormatSentiment(rowData, fromTable = true) {
  const neutral = rowData.value == 'NEUTRAL';
  const bullish = rowData.value == 'BULLISH';
  const label = neutral ? 'NEUTRAL' : rowData.value;
  var data = fromTable ? rowData.row.original.description_extended : rowData.original.description_extended;
  const size = fromTable ? rowData.row.original.size : rowData.original.size;
  const cost = fromTable ? rowData.row.original.cost_basis : rowData.original.cost_basis
  const OI = fromTable ? rowData.row.original.open_interest : rowData.original.open_interest;
  if (!data.includes("Ref")) {
    data = data.concat(`; ${size} @ $${(parseFloat(cost / size) / 100).toFixed(2)} vs ${OI} OI`);
  }

  return (
    <div style={{ display: 'flex', height: 30, flexDirection: 'row', justifyContent: 'center' }}>
      {rowData == 'null' ? 'N/A'
        :
        (
          <div>
            <div className={neutral ? 'neutralBadge' : bullish ? 'bullBadge' : 'bearBadge'} id={`sentiment_${fromTable ? rowData.row.original.id : rowData.original.id}`} boxShadow={3} style={{ color: '#FFFFFF', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 80, boxShadow: '0px 0px 1px black' }}>{label}</div>
            <UncontrolledTooltip placement="right" target={`sentiment_${fromTable ? rowData.row.original.id : rowData.original.id}`} delay={0}>
              {<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{data}</div>}
            </UncontrolledTooltip>
          </div>
        )}
    </div>)
}

function FormatUOA(rowData) {
  const value = parseFloat(rowData.row.original.open_interest_value);
  const totalVolume = rowData.data.filter(x => x.ticker == rowData.row.original.ticker).reduce((a, b) => (a.volume, b.volume));
  const totalOI = rowData.data.filter(x => x.ticker == rowData.row.original.ticker).reduce((a, b) => (a.open_interest, b.open_interest));
  var str = `Volume/Open Interest: ${rowData.row.original.volume} / ${rowData.row.original.open_interest}`;
  if (parseFloat(rowData.row.original.volume) > parseFloat(rowData.row.original.open_interest)) str += "; Exceeded open interest for the day in this single trade";
  if (totalVolume > totalOI) str += "; Exceeded open interest for the day over multiple trades";
  return (
    <div>
      <div id={`UOA_${rowData.row.original.id}`} style={{ textAlign: "center" }}>
        {rowData.row.original.open_interest != 0 ? value.toFixed(2) : "No OI"}
      </div>
      <UncontrolledTooltip flip={true} placement="top" target={`UOA_${rowData.row.original.id}`} delay={0}>
        {str}
      </UncontrolledTooltip>
    </div>
  )
}

function FormatExpiration(rowData) {
  const expiration = rowData.row.original.date_expiration;
  const splitString = expiration.split("-");
  const date = new Date(splitString[0], parseInt(splitString[1]) - 1, parseInt(splitString[2]));
  const now = moment(new Date().setHours(0, 0, 0, 0));
  const duration = moment.duration(now.diff(date));
  const diff = duration.asDays() * -1;
  return (
    <div>
      <div id={`expiration_${rowData.row.original.id}`} style={{ textAlign: "center" }}>
        {`${splitString[1]}/${splitString[2]}/${splitString[0].substr(2)}`}
      </div>
      <UncontrolledTooltip flip={true} placement="top" target={`expiration_${rowData.row.original.id}`} delay={0}>
        {diff !== 0 ? diff > 0 ? (<span>{`Expires in ${diff.toFixed(0)} day(s)`}</span>) : <span>{`Expired ${Math.abs(diff.toFixed(0))} day(s) ago`}</span> : <span>{`Expires today`}</span>}
      </UncontrolledTooltip>
    </div>
  )
}

function FormatStrike(rowData) {
  const ref = rowData.row.original.description_extended.split("Ref=$")[1];
  return (
    <div>
      <div id={`strike_${rowData.row.original.id}`} style={{ textAlign: "center" }}>
        {(<span style={{ padding: '1px', width: '20px', fontWeight: 'bold' }}>{rowData.row.original.displayText}</span>)} {parseFloat(rowData.value).toFixed(1)}
      </div>
      <UncontrolledTooltip flip={true} placement="top" target={`strike_${rowData.row.original.id}`} delay={0}>
        {rowData.row.original.displayText != "N/A" ? rowData.row.original.displayText == "Out" ? (<span>{`Out of the money Ref=$${ref} vs $${rowData.value}`}</span>) : <span>{`In the money Ref=$${ref} vs $${rowData.value}`}</span> : <span>{`On The Money`}</span>}
      </UncontrolledTooltip>
    </div>
  )
}

const DateInput = ({ value, onClick }) => (
  <Grid onClick={onClick} container direction='row' justify='flex-start' justifyContent='flex-start' alignItems='center' style={{ marginBottom: '10px' }}>
    <CalendarIcon style={{ width: 20, height: 20 }} />
    <div
      style={{ color: 'white', fontSize: '12px', paddingLeft: '5px' }}>
      {value}
    </div>
  </Grid>
);

const playAlert = () => {
  return (
    <audio ref={audioRef} src={alert} />
  )
}

const calculateTimeLeft = () => {
  var tempDate = momenttz().tz('America/Los_Angeles');
  if (!moment(tempDate).isBusinessDay() || moment(tempDate).isHoliday()) tempDate = moment().prevBusinessDay();
  var dt = moment(tempDate);
  var dtsixthirty = moment(dt.format("YYYY-MM-DD")).set('hour', 6).set('minute', 30);
  var countDown = dtsixthirty.diff(dt, 'milliseconds');
  if (countDown < 0) return '';

  let timeLeft = {};

  if (countDown > 0) {
    timeLeft = {
      hours: Math.floor((countDown / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((countDown / 1000 / 60) % 60),
      seconds: Math.floor((countDown / 1000) % 60)
    };
  }

  return `${timeLeft.hours} H : ${timeLeft.minutes} M : ${timeLeft.seconds} S`;
}

var tempCount = {};


const StockTable = ({ news, puttocall, put, call, sentiment, options, date, user, firebase, prefs, updateTop, getDate, getDateRange, isWeekday, handleOpen, loading, early, mobile, setMobile }) => {
  const updatePrefs = (event) => {
    if (!prefs) prefs = { 'audioAlerts': false, 'watchlist': [], 'useEtf': false, 'fullMobile': false };
    prefs.audioAlerts = !prefs.audioAlerts;
    firebase.doSetPreferences(user.user_id, prefs);
  };

  var tempDate = momenttz().tz('America/Los_Angeles');
  if (!moment(tempDate).isBusinessDay() || moment(date).isHoliday()) tempDate = moment().prevBusinessDay();
  var dt = moment(date);
  var dtsixthirty = moment(dt.format("YYYY-MM-DD")).set('hour', 6).set('minute', 30);
  var countDown = (moment(dt).isBusinessDay() && !moment(dt).isHoliday()) ? dtsixthirty.diff(dt, 'milliseconds') : -1;

  const [tempRows, setTempRows] = React.useState([]);
  useEffect(() => {
    updateTop(tempRows.map(x => x.original));
  }, [tempRows])

  const [useRange, setUseRange, rangeRef] = useStateRef(false);
  const [filterInput, setFilterInput] = useState('');
  const [timer, setTimer] = useState(countDown);
  const [watchInput, setWatchInput] = useState(null);
  const [watchList, setWatchList] = useState([]);
  const [useWatch, setUseWatch] = useState(false);
  const [useEtf, setUseEtf, useEtfRef] = useStateRef(true);
  const [useFilterExpired, setUseFilterExpired] = useState(false);
  const [startDate, setStartDate] = useState(tempDate);
  const [endDate, setEndDate] = useState(null);
  const [localState, setLocalState] = useState(options);
  const [listLoading, setListLoading] = useState(true);
  const [watchListLoading, setWatchListLoading] = useState(false);
  const [showFilters, setShowFilters, showFiltersRef] = useStateRef(false);
  const [showFull, setShowFull, showFullRef] = useStateRef(false);
  const [showTab, setShowTab, showTabRef] = useStateRef('watchlist');
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [date]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setMobile(window.innerWidth < firstBreakpoint);
    }
    window.addEventListener("resize", handleWindowResize);
    setMobile(window.innerWidth < firstBreakpoint);

    window.onresize(); // called to initially set the height.
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (options === localState) {
      if (prefs.watchlist == watchList)
        return
      setWatchList(prefs.watchlist);
      return;

    }
    setLocalState(options);
    setWatchList(prefs.watchlist);
  }, [options, prefs.watchlist])

  useEffect(() => {
    if (prefs != undefined && prefs.useEtf != undefined)
      setUseEtf(prefs.useEtf);
    if (prefs != undefined && prefs.fullMobile != undefined)
      setShowFull(prefs.fullMobile);
    if (prefs != undefined && prefs.watchlist != watchList)
      setWatchList(prefs.watchlist);
  }, [prefs.useEtf, prefs.fullMobile, prefs.watchlist])


  useEffect(() => {
    if (listLoading === loading) return
    setListLoading(loading);
  }, [loading])

  const updateDate = (updateDate) => {
    setListLoading(true);
    getDate(updateDate);
  }

  const updateDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start != null && end != null) {
      setListLoading(true);
      getDateRange(start, end);
    }
  }


  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }


  const renderNews = (news) => {
    if (news == undefined || news == null) return (<div></div>)
    if (news != undefined)
      return <div style={{ fontSize: 13 }}>{new String(news.text).split(" ").map((elem, index) => (elem.startsWith("$") && isNumeric(elem.replace(/\,/g, ''))) || elem.startsWith("#") ? <a style={{ color: "white", fontWeight: "bold" }} href={`https://twitter.com/search?q=${elem}`} target="_blank">{elem} </a> : elem.startsWith("http") || elem.startsWith("www") ? <a style={{ color: "white", fontWeight: "bold", textDecoration: 'underline', textDecorationColor: 'white' }} href={elem.startsWith("http") ? elem : `http://${elem}`} target="_blank">{elem} </a> : <span style={{ color: "silver" }}>{elem} </span>) || ""}</div>;
    return (<div></div>)
  }


  const getDayTag = (dateExpiration) => {
    const now = moment(new Date().setHours(0, 0, 0, 0));
    const expiration = dateExpiration;
    const splitString = expiration.split("-");
    const dateExpirationConst = new Date(splitString[0], parseInt(splitString[1]) - 1, parseInt(splitString[2]));
    const duration = moment.duration(now.diff(dateExpirationConst));
    const diff = duration.asDays() * -1;
    if (diff <= 3 && diff >= 0)
      return "3 Day";
    if (diff <= 5 && diff >= 0)
      return "5 Day";
    return null;
  }

  const isExpired = (dateExpiration) => {
    const now = moment(new Date().setHours(0, 0, 0, 0));
    const expiration = dateExpiration;
    const splitString = expiration.split("-");
    const dateExpirationConst = new Date(splitString[0], parseInt(splitString[1]) - 1, parseInt(splitString[2]));
    const isExpired = now.isAfter(dateExpirationConst);
    return isExpired ? 'true' : 'false';
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const Search = (stockString) => {
    setFilterInput(stockString);
  }
  const SetUpWatchlist = () => {
    if (!prefs.watchlist) prefs.watchlist = [];
    prefs.favorites = null;
    prefs.fullMobile = showFullRef.current;
  }

  const AddToWatchList = (stockString, moreComing) => {
    var list = prefs.watchlist ?? []
    if (list.length == 52 || stockString.length == 0 || containsObject(stockString.toUpperCase(), list)) return;
    setListLoading(true);
    setWatchListLoading(true);
    SetUpWatchlist();
    list.push(stockString.toUpperCase());
    prefs.watchlist = list
    prefs.fullMobile = showFullRef.current;
    setWatchList(list);
    firebase.doSetPreferences(user.user_id, prefs);
    if (moreComing) return;
    setListLoading(false);
    setWatchListLoading(false);
  }

  const RemoveWatchlist = (item) => {
    setWatchListLoading(true);
    var list = []
    if (prefs && prefs.watchlist) {
      list = prefs.watchlist.filter(i => i != item) ?? [];
    }
    setWatchList(list);
    prefs.watchlist = list;
    firebase.doSetPreferences(user.user_id, prefs);
    setWatchListLoading(false);
  }

  const ShowFilters = () => {
    setShowFilters(!showFiltersRef.current)
  }

  const FormatMore = React.useCallback((rowData) => {
    if (prefs == null || prefs == undefined || prefs.watchlist == null || prefs.watchlist == undefined)
      SetUpWatchlist();

    if (rowData.original.IsWatched) {
      return (
        <div className="removeFColumn">
          <Remove id={`remove_${rowData.original.id}`} key={rowData.original.id} onClick={() => RemoveWatchlist(rowData.original.ticker)} style={{ color: 'white' }} />
          <UncontrolledTooltip target={`remove_${rowData.original.id}`} delay={0} position="top">
            Remove from Watchlist
          </UncontrolledTooltip>
        </div>)
    }

    return (
      <div className="addColumn">
        <AddIcon id={`add_${rowData.original.id}`} key={rowData.original.id} onClick={() => AddToWatchList(rowData.original.ticker, false)} style={{ color: 'white' }} />
        <UncontrolledTooltip target={`add_${rowData.original.id}`} delay={0} position="top">
          Add To Watchlist
        </UncontrolledTooltip>
      </div>)

  });

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: 'center' }}></div>,
        accessor: 'ticker',
        id: (rowData) => `chart`,
        Cell: rowData => <div style={{ paddingLeft: 2 }}>{ChartColumn(handleOpen, rowData, Search, useRange)}</div>,
        sortType: "basic",
        disableSortBy: true,
        width: 125,
        disableExport: true,
        isVisible: width > secondBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Time (EST)</div>,
        color: 'white',
        accessor: 'updated',
        Cell: rowData => DateRow(rowData.value, useRange),
        disableSortBy: false,
        getColumnExportValue: (column) => "Date Timestamp",
        getCellExportValue: (row, column) => `${moment.unix(row.original.updated).tz("America/New_York").format('M/D h:mm a')}`,
        isVisible: true
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Ticker</div>,
        accessor: 'ticker',
        Cell: rowData => <div>{FormatTicker(rowData.row.original.ticker, Search)}</div>,
        sortType: "basic",
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: (rows, ids, filterValue) => {
          if (!!filterValue) {
            var tempRows = rows.filter(x => x.original.ticker === filterValue);
            if (tempRows.length === 0)
              tempRows = rows.filter(x => x.original.ticker.includes(filterValue));
            if (tempRows.length === 0)
              return rows;
            return tempRows;
          }
          return rows;
        },
        getColumnExportValue: (column) => "Ticker",
        isVisible: true,
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Type</div>,
        accessor: 'option_activity_type',
        id: 'option_activity_type',
        Cell: rowData => FormatType(rowData),
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getColumnExportValue: (column) => "Activity Type",
        isVisible: showFullRef.current ? true : width > firstBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Call/Put</div>,
        accessor: `put_call`,
        id: 'put_call',
        Cell: rowData => FormatCallPut(rowData),
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getColumnExportValue: (column) => "Put or Call",
        isVisible: true
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Sentiment</div>,
        accessor: 'sentiment',
        id: 'sentiment',
        Cell: rowData => FormatSentiment(rowData),
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getColumnExportValue: (column) => "Sentiment",
        isVisible: showFullRef.current ? true : width > secondBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>SweepScore</div>,
        accessor: 'aggressor_ind',
        id: 'sweepscore',
        Cell: rowData => FormatAggr(rowData),
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: (rows, ids, filterValue) => {
          if (filterValue == "All" || filterValue == undefined || filterValue == null)
            return rows;
          if (filterValue == "Above at ask")
            return rows.filter(x => x.original.aggressor_ind >= 1.01);
          if (filterValue == "At the ask")
            return rows.filter(x => x.original.aggressor_ind == 1.00);
          if (filterValue == "Near the ask")
            return rows.filter(x => x.original.aggressor_ind >= 0.75 && x.original.aggressor_ind < 1);
          if (filterValue == "At the mid")
            return rows.filter(x => x.original.aggressor_ind > 0.25 && x.original.aggressor_ind < 0.75);
          if (filterValue == "Near the bid")
            return rows.filter(x => x.original.aggressor_ind > 0 && x.original.aggressor_ind < 0.26);
          if (filterValue == "At the bid")
            return rows.filter(x => x.original.aggressor_ind == 0);
          if (filterValue == "Below the bid")
            return rows.filter(x => x.original.aggressor_ind < 0);
        },
        getColumnExportValue: (column) => "SweepScore",
        width: 150,
        isVisible: showFullRef.current ? true : width > secondBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }} >Vol/OI Ratio</div>,
        accessor: 'open_interest_value',
        id: 'OI',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: rowData => FormatUOA(rowData),
        disableSortBy: false,
        getColumnExportValue: (column) => "Vol/OI Ratio",
        getCellExportValue: (row, column) => `${row.original.volume}/${row.original.open_interest}`,
        isVisible: showFullRef.current ? true : width > firstBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Strike</div>,
        accessor: 'strike_price',
        id: 'strike',
        disableSortBy: false,
        Cell: rowData => FormatStrike(rowData),
        Filter: SelectColumnFilter,
        filter: (rows, ids, filterValue) => {
          if (filterValue == "All" || filterValue == undefined || filterValue == null)
            return rows;
          if (filterValue == "All OTM")
            return rows.filter(x => x.original.displayText == "Out");
          if (filterValue == "All ITM")
            return rows.filter(x => x.original.displayText == "In");
          return rows.filter(x => x.original.strike_price == filterValue.replace(`/^\D+/g`, ''))
        },
        getColumnExportValue: (column) => "Strike Price",
        isVisible: showFullRef.current ? true : width > firstBreakpoint
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Expiration</div>,
        accessor: 'date_expiration',
        id: 'date_expiration',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: rowData => FormatExpiration(rowData),
        disableSortBy: false,
        getColumnExportValue: (column) => "Expiration",
        getCellExportValue: (row, column) => `${row.original.date_expiration_format}`,
        isVisible: true
      },
      {
        Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Premium</div>,
        accessor: 'cost_basis',
        id: 'premium',
        Cell: rowData => FormatPremium(rowData.row.original.cost_basis),
        Filter: SelectColumnFilter,
        filter: (rows, ids, filterValue) => {
          if (filterValue == "All" || filterValue == undefined || filterValue == null)
            return rows;
          if (filterValue == "< 10k")
            return rows.filter(x => x.original.cost_basis <= 10000);
          if (filterValue == "10k-50k")
            return rows.filter(x => x.original.cost_basis >= 10000 && x.original.cost_basis <= 50000);
          if (filterValue == "50k-100k")
            return rows.filter(x => x.original.cost_basis >= 50000 && x.original.cost_basis <= 100000);
          if (filterValue == "100k-200k")
            return rows.filter(x => x.original.cost_basis >= 100000 && x.original.cost_basis <= 200000);
          if (filterValue == "200k-500k")
            return rows.filter(x => x.original.cost_basis >= 200000 && x.original.cost_basis <= 500000);
          if (filterValue == "500k-1M")
            return rows.filter(x => x.original.cost_basis >= 500000 && x.original.cost_basis <= 1000000);
          return rows.filter(x => x.original.cost_basis >= 1000000);
        },
        sortType: (rowA, rowB, columnId, desc) => {
          if (Number(rowA.original.cost_basis) > Number(rowB.original.cost_basis)) return 1;
          else if (Number(rowA.original.cost_basis) < Number(rowB.original.cost_basis)) return -1;
          return 0;
        },
        disableSortBy: false,
        getColumnExportValue: (column) => "Premium",
        isVisible: showFullRef.current ? true : width > firstBreakpoint
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Details</div>,
        id: (rowData) => `details`,
        accessor: (rowData) => `${rowData.size} @ ${parseFloat(rowData.price).toFixed(2)}`,
        disableSortBy: true,
        getColumnExportValue: (column) => "Details",
        isVisible: showFullRef.current ? true : width > firstBreakpoint
      },
      {

        Header: () => (<div style={{ backgroundColor: "#333E66", width: '100%', height: '100%' }}>
          {showFiltersRef.current ? (<div><div style={{ textAlign: 'center' }}>Hide</div>
            <img src={DoubleUpArrow} alt="Logo" style={{ borderRadius: "0" }} /></div>) : (<div><div style={{ textAlign: 'center' }}>Filter</div>
              <img src={DoubleDownArrow} alt="Logo" style={{ borderRadius: "0" }} /></div>)}

        </div>),
        id: 'IsWatched',
        accessor: 'IsWatched',
        Cell: ({ row }) => (
          <div>
            {((mobile && !showFullRef.current) && width < secondBreakpoint) ? row.isExpanded ? (<ExpandLess alt="Logo" style={{ borderRadius: "0" }} />) : (<ExpandMore alt="Logo" style={{ borderRadius: "0" }} />) : FormatMore(row)}
          </div>),
        disableSortBy: false,
        disableExport: true,
        isVisible: true
      },
      {
        id: 'IsETF',
        accessor: 'IsETF',
        disableSortBy: false,
        disableExport: true,
        isVisible: false
      },
      {
        id: 'IsExpired',
        accessor: 'IsExpired',
        disableSortBy: false,
        disableExport: true,
        isVisible: false
      },

    ],
    [localState, width, useRange, showFull, prefs]
  )

  const data = React.useMemo(() => {
    options.forEach(
      x => {
        x.IsWatched = prefs?.watchlist?.includes(x.ticker)
        x.IsETF = `${x.underlying_type === "ETF"}`;
        x.dayFilter = getDayTag(x.date_expiration);
        x.IsExpired = isExpired(x.date_expiration);
        const date = x.date_expiration.split('-');
        const value = parseFloat(x.volume) / parseFloat(x.open_interest);
        x.open_interest_value = value;
        x.date_expiration_format = date[1] + "/" + date[2] + "/" + date[0];
        x.IsETF = `${x.underlying_type === "ETF"}`;
        const ref = x.description_extended.split("Ref=$")[1];
        const putCall = x.put_call;
        var displayText = "N/A";
        if (ref != null && ref != undefined && x.strike_price != null) {
          if (putCall == "PUT") {
            displayText = parseFloat(ref) > parseFloat(x.strike_price) ? "Out" : "In";
          }
          if (putCall == "CALL") {
            displayText = parseFloat(ref) < parseFloat(x.strike_price) ? "Out" : "In";
          }
        }
        x.displayText = displayText;
        if (x.cost_basis >= 1000000)
          x.isGolden = "Golden";
        if (x.formattedDateTime == undefined || x.formattedDateTime == null)
          x.formattedDateTime = moment.unix(x.updated).tz("America/New_York").format('h:mm a')
      }
    );
    return [...options];
  }, [options, prefs.watchlist]
  );

  const generateSortingIndicator = column => {
    const active = column.isSorted;
    return (<div style={{ opacity: active ? '100%' : '50%', paddingLeft: 5 }}>{column.canSort ? active ? (column.isSortedDesc ? (<img src={ArrowDownwardRounded} alt="Logo" style={{ width: '15', height: '15', borderRadius: "0" }} />) : (<img src={ArrowUpwardRounded} alt="Logo" style={{ width: '15', height: '15', borderRadius: "0" }} />)) : "" : ""}</div>);
  }


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preFilteredRows,
    prepareRow,
    exportData,
    filters,
    setAllFilters,
    visibleColumns,
    toggleRowExpanded,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      getExportFileBlob,
      initialState: {
        sortBy: [
          {
            id: 'updated',
            desc: true
          }
        ],
        hiddenColumns: columns.filter(column => !column?.isVisible).map(column => column.id)
      }
    },
    useFilters,
    useSortBy,
    useExpanded,
    useBlockLayout,
    useExportData,
  )

  useEffect(() => {
    if (!!filterInput) {
      setFilter('ticker', filterInput.toUpperCase())
    } else {
      setFilter('ticker', undefined)
    }
  }, [filterInput])

  useEffect(() => {
    setFilter('IsWatched', useWatch)
  }, [useWatch])

  useEffect(() => {
    setFilter('IsETF', useEtf ? [] : 'false')
  }, [useEtf])

  useEffect(() => {
    setFilter('IsExpired', useFilterExpired ? 'false' : [])
  }, [useFilterExpired])

  useEffect(() => {
    if (rows.length !== tempRows.length)
      setTempRows(rows);
  }, [rows])


  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }

  const filterByWatchlist = () => {
    if (!useWatch && prefs.watchlist.length > 0) {
      setUseWatch(true);
    } else {
      setUseWatch(false);
    }
  }

  const filterHideExpired = () => {
    if (!useFilterExpired) {
      setUseFilterExpired(true);
    } else {
      setUseFilterExpired(false);
    }
  }

  const filterByETF = () => {
    const current = useEtfRef.current;
    setUseEtf(!current);
    prefs.useEtf = !current;
    firebase.doSetPreferences(user.user_id, prefs);
  }

  const ShowFullMobileTable = () => {
    setShowFull(!showFullRef.current);
    prefs.fullMobile = showFullRef.current;
    var i = rows.findIndex((row) => row.isExpanded)
    if (i != -1) {
      rows[i].toggleRowExpanded();
      tableRef.current.resetAfterIndex(i);
    }
    firebase.doSetPreferences(user.user_id, prefs);
  }

  const useDateRange = () => {
    setUseRange(!rangeRef.current);
    var tempDate = momenttz().tz('America/Los_Angeles');
    if (!moment(tempDate).isBusinessDay() || moment(date).isHoliday()) tempDate = moment().prevBusinessDay();
    if (!rangeRef.current) {
      updateDate(tempDate);
    } else {
      setStartDate(tempDate)
      setEndDate(null);
    }
  }


  const keyPress = (e) => {
    if (e.keyCode == 13) {
      if (e.target.value.includes(",")) {
        var values = e.target.value.split(",");
        values.map((value, i) => {
          if (values.length - 1 != i) AddToWatchList(value.trim(), true);
          else AddToWatchList(value.trim(), false);
        })
      } else {
        AddToWatchList(e.target.value, false);
      }
      setWatchInput("");
    }
  }

  const AddWatchlistButton = () => {
    AddToWatchList(watchInput);
    setWatchInput("");
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      const click = () => {
        if (width > secondBreakpoint || showFullRef.current) return;
        row.toggleRowExpanded();
        tableRef.current.resetAfterIndex(index);
      }
      return (
        <Fragment key={row.getRowProps().key}>
          <div className="data-row-outer-div" style={{ ...style }}  >

            <tr key={row.getRowProps().key} {...row.getRowProps()} className={!showFullRef.current ? "tr main-data-row" : mobile ? "tr full-main-data-row" : "tr main-data-row"}>
              {row.cells.map(cell => {
                const cellStyle = cell.getCellProps().style;
                if (cell.row.original.cost_basis >= 1000000) {
                  return (
                    <td onClick={cell.column.exportValue != "Ticker" ? click : null} {...cell.getCellProps()} style={{ ...cellStyle, color: '#deb723', fontSize: '12px' }} className={!showFullRef.current ? "td text-center row-td" : mobile ? "td text-center full-row-td" : "td text-center row-td"}>
                      {cell.render("Cell")}
                    </td>
                  );
                }
                else {
                  return (
                    <td onClick={cell.column.exportValue != "Ticker" ? click : null} {...cell.getCellProps()} style={{ ...cellStyle, color: 'white', fontSize: '12px' }} className={!showFullRef.current ? "td text-center row-td" : mobile ? "td text-center full-row-td" : "td text-center row-td"}>
                      {cell.render("Cell")}
                    </td>
                  )
                }
              })}
            </tr>
            {!showFullRef.current && row.isExpanded && (<div style={{ width: '100vw' }}>
              <Grid container direction="row" xs={12} justifyContent="center" alignContent="start">
                <Grid item style={{ width: '100vw' }}>
                  <Card>
                    <CardContent>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Type:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{row.original.option_activity_type[0] + row.original.option_activity_type.substring(1).toLowerCase()}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Sentiment:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{row.original.sentiment[0] + row.original.sentiment.substring(1).toLowerCase()}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Strike:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{`${row.original.displayText} $${parseFloat(row.original.strike_price).toFixed(1)}`}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Premium:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{GetPremiumString(row.original.cost_basis)}</div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardFooter>
                      <Grid container justifyContent="space-between" alignItems="flex-start">
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Vol/OI:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{`${row.original.volume} / ${row.original.open_interest}`}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>SweepScore:</div>
                          {FormatAggrExpand(row.original.aggressor_ind)}
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Ref:</div>
                          {FormatRef(row.original.description_extended)}
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ color: 'white', fontSize: '13px', fontWeight: 'bold' }}>Details:</div>
                          <div style={{ color: 'white', fontSize: '12px' }}>{`${row.original.size} @ ${parseFloat(row.original.price).toFixed(2)}`}</div>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                </Grid>
              </Grid>
            </div>)}          </div>
        </Fragment>

      );
    },
    [prepareRow, rows, width]
  );


  function getExportFileBlob({ columns, data, fileType, fileName }) {
    var today = moment().format("MMM Do YY");
    if (prefs.lastDownloaded == null) {
      prefs.lastDownloaded = today;
      prefs.downloadCount = 1;
    }
    else {
      if (prefs.lastDownloaded == today && prefs.downloadCount < 20) {
        prefs.downloadCount++;
      }
      else if (prefs.lastDownloaded == today && prefs.downloadCount == 20) {
        return false;
      }
      else {
        prefs.lastDownloaded = today; prefs.downloadCount = 1;
      }
    }
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      firebase.doSetPreferences(user.user_id, prefs);
      return new Blob([csvString], { type: "text/csv" });
    }
    return false;
  }

  function getDownloadsRemaining() {
    var remaining = 20;
    var today = moment().format("MMM Do YY");
    if (prefs.lastDownloaded == today)
      remaining = remaining - prefs.downloadCount;
    return "You can export to csv 20 times/day. " + remaining.toString() + " remaining.";
  }

  return (
    <Grid className="allMiddleContent" container justify='center' style={{ marginTop: 5 }} spacing={1}>

      <Hidden mdUp>
        <Grid item sm={6} xs={12}>
          <Accordion style={{ backgroundColor: "#20293C", marginLeft: 5, marginRight: 5 }}>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: 'white' }} />} aria-controls="panel1a-content" style={{ backgroundColor: "rgb(26, 35, 68)", marginBottom: '5px' }}>
              <Grid container
                className="mobile-watchlist-header"
                sm={6} xs={12}
                direction="row"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                justify="space-between"
                alignItems="center">
                <Grid item onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}>
                  <div style={{ alignItems: 'end', float: 'right', padding: "0px 15px 0px 15px", color: 'white' }}>Market Overview</div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Card className='progress-bar-content' style={{ height: 256, border: '3px solid #3651B9', marginBottom: '5px' }}>
                <CardBody style={{ backgroundColor: "#3A4DBC2B", padding: "5px 15px" }}>
                  <Grid container>
                    <Grid item style={{ width: '100%', paddingTop: "10px" }}>
                      <div>
                        {puttocall}
                      </div>
                    </Grid>
                    <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                      <div>
                        {put}
                      </div>
                    </Grid>
                    <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                      <div>
                        {call}
                      </div>
                    </Grid>
                    <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                      <div>
                        {sentiment}
                      </div>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Accordion style={{ backgroundColor: "#20293C", marginLeft: 5, marginRight: 5 }}>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: 'white' }} />} aria-controls="panel1a-content" style={{ backgroundColor: "rgb(26, 35, 68)", marginBottom: '5px' }}>
              <Grid container
                className="mobile-watchlist-header"
                sm={6} xs={12}
                direction="row"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                justify="space-between"
                alignItems="center">
                <Grid item onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}>
                  <WatchSearch searchString={watchInput} setWatchDoneInput={setWatchInput} keyPress={keyPress} AddWatchlistButton={AddWatchlistButton} />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid className="mobile-watchlist-body" container
                direction="row"
                justify="flex-start"
                alignItems="center">
                {
                  watchList != null && watchList != undefined ? watchList.sort((a, b) => a.localeCompare(b)).map((item, i) => {
                    return (
                      <Grid container direction="row" sm={2} xs={4} style={{ color: 'white', alignItems: 'center', justifyItems: 'center' }}>
                        <CancelRounded onClick={() => RemoveWatchlist(item)} fontSize="small" style={{ color: "#28ABDD", paddingRight: 2 }} />
                        <Grid item onClick={() => Search(item)} style={{ fontSize: '12px' }}>{`${item}(${data.filter((x) => x.ticker === item).length})`}</Grid>
                      </Grid>
                    )
                  }) : <div></div>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Hidden>

      {/* Data Grid */}
      <Grid item lg={10} md={10} sm={12} xs={12}>
        <Card className="main-card-outer" style={{ minWidth: 702 }}>
          <CardHeader style={{ backgroundColor: "#1A2344", padding: "5px 10px 5px 20px" }}>

            <Grid container direction="column" justify="center" alignItems="center" style={{ display: 'flex' }}>
              <Grid container direction="row" justifyContent="space-between" style={{ display: 'flex' }}>

                <Hidden mdDown>
                  <Grid item justify="flex-start" style={{ marginTop: "auto", marginBottom: "auto" }} >
                    <img src={tableBranding} height={19.58} width={100} />
                  </Grid>

                </Hidden>


                <Hidden mdUp>
                  <Grid item alignContent="flex-end" justify="flex-end" className="grid-header-left" style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <div style={{ display: "inside-block", justifyContent: "center", alignContent: "center" }}>
                      {useRange || date != null && date != undefined && moment(date).businessDiff(momenttz().tz('America/Los_Angeles')) >= 1 ?
                        (<span className="grid-header-switch-span">
                          Hide Expired
                          <Switch
                            color='primary'
                            checked={useFilterExpired}
                            onChange={filterHideExpired}
                            name="checkedC"
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </span>) : null
                      }
                      <span className="grid-header-switch-span">
                        Watchlist
                        <Switch
                          color='primary'
                          checked={useWatch}
                          onChange={filterByWatchlist}
                          name="checkedA"
                          inputProps={{ 'aria-label': 'secondary checkbox' }} />
                      </span>
                      <span className="grid-header-switch-span">
                        ETFs
                        <Switch
                          color='primary'
                          checked={useEtfRef.current}
                          onChange={filterByETF}
                          name="checkedB"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </span>
                      <span className="grid-header-switch-span">
                        Full
                        <Switch
                          color='primary'
                          checked={showFull}
                          onChange={ShowFullMobileTable}
                          name="checkedC"
                          inputProps={{ 'aria-label': 'secondary checkbox' }} />
                      </span>
                      {/* <Hidden only={['xs']}>
                        <div color="link" id="exportCSVCount" style={{ color: "white" }}
                          onClick={() => { exportData("csv", false); }}>
                          <img src={ExportIcon} alt="Logo" style={{ width: '100%', borderRadius: "0" }} />
                        </div>
                        <UncontrolledTooltip target="exportCSVCount" delay={0} position="top">
                          {getDownloadsRemaining()}
                        </UncontrolledTooltip>
                      </Hidden> */}

                    </div>
                  </Grid>

                </Hidden>

                <Grid item justifyContent="flex-start" style={{ marginTop: "auto", marginBottom: "auto" }} className="dateContainer">
                  <div style={{ display: 'inline-block' }}>
                    {!useRange ?
                      (<DatePicker
                        disabledKeyboardNavigation
                        style={{ justifyContent: 'start', alignItems: 'start', flex: 0, width: '20%', zIndex: 10000, borderRadius: '4px', height: 36, }}
                        selected={new Date(date)}
                        onChange={updateDate}
                        customInput={<DateInput />}
                        filterDate={isWeekday}
                        minDate={new Date(2020, 6, 7, 16, 0, 0, 0)}
                        maxDate={moment().prevBusinessDay()}
                        popperClassName='some-custom-class'
                        popperPlacement={mobile ? 'bottom' : 'right-end'}
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px'
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport'
                          }
                        }} />) :
                      (<DatePicker
                        disabledKeyboardNavigation
                        style={{ justifyContent: 'start', alignItems: 'start', flex: 0, width: '20%', zIndex: 10000 }}
                        selected={new Date(startDate)}
                        startDate={new Date(startDate)}
                        endDate={endDate}
                        onChange={updateDateRange}
                        customInput={<DateInput />}
                        filterDate={isWeekday}
                        selectsRange={true}
                        shouldCloseOnSelect={true}
                        style={{ borderRadius: '4px', height: 36, }}
                        minDate={new Date(2020, 6, 7, 16, 0, 0, 0)}
                        maxDate={moment().prevBusinessDay()}
                        popperClassName='some-custom-class'
                        popperPlacement={mobile ? 'bottom' : 'right-end'}
                        disabledKeyboardNavigation
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px'
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport'
                          }
                        }} />)
                    }
                  </div>
                  <div style={{ display: 'inline-block', paddingLeft: '20px', verticalAlign: 'bottom' }}>
                    Select Range
                    <Switch
                      color='primary'
                      checked={useRange}
                      onChange={useDateRange}
                      name="checkedR"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                </Grid>

                <Grid item justifyContent="center" style={{ marginTop: "auto", marginBottom: "auto", width: width < secondBreakpoint + 150 ? '100%' : 'unset' }}>
                  <TextField
                    style={{ padding: "0px 15px 0px 15px", borderRadius: "10px", border: "1px solid #9A9A9A", width: width < secondBreakpoint + 150 ? '100%' : 'unset' }}
                    autoFocus={true}
                    className='searchField'
                    value={filterInput || ''}
                    onChange={(event) => Search(event.target.value)}
                    placeholder='Search (EX: TSLA)'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      style: { color: 'white' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip >
                            <SearchRounded fontSize="small" style={{ color: "white" }} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <CancelRounded disabled={!filterInput && filterInput != ""} onClick={() => Search("")} fontSize="small" style={{ color: !filterInput && filterInput != "" ? "white" : "grey" }} />
                        </InputAdornment>
                      ),
                    }} />
                </Grid>


                <Hidden mdDown>
                  <Grid item justifyContent="flex-end" className="grid-header-left" style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <div style={{ display: "inside-block", justifyContent: "center", alignContent: "center" }}>
                      {useRange || date != null && date != undefined && moment(date).businessDiff(momenttz().tz('America/Los_Angeles')) >= 1 ?
                        (<span style={{ marginLeft: 25 }}>
                          Hide Expired
                          <Switch
                            color='primary'
                            checked={useFilterExpired}
                            onChange={filterHideExpired}
                            name="checkedC"
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </span>) : null
                      }
                      <span >
                        Watchlist
                        <Switch
                          color='primary'
                          checked={useWatch}
                          onChange={filterByWatchlist}
                          name="checkedD"
                          inputProps={{ 'aria-label': 'secondary checkbox' }} />
                      </span>
                      ETFs
                      <Switch
                        color='primary'
                        checked={useEtf}
                        onChange={filterByETF}
                        name="checkedE"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <Hidden only={['xs']}>
                        <div
                          color="link"
                          id="exportCSVCount"
                          style={{ color: "white", display: "inline-block" }}
                          onClick={() => { exportData("csv", false); }}
                          size="large">
                          <img src={ExportIcon} alt="Logo" style={{ width: '25px', height: '25px', borderRadius: "0" }} />
                        </div>
                        <UncontrolledTooltip target="exportCSVCount" delay={0} position="top">
                          {getDownloadsRemaining()}
                        </UncontrolledTooltip>
                      </Hidden>

                    </div>
                  </Grid>
                </Hidden>

              </Grid>
            </Grid>
          </CardHeader>
          <CardBody style={{ minHeight: '75px' }} className={!showFullRef.current ? "main-card-body" : mobile ? "full-main-card-body" : "main-card-body"}>
            <div>
              {
                (
                  <Fragment>
                    {listLoading ? (<CircularProgress color="secondary" style={{ position: 'absolute', left: '50%', top: '50%' }} />) :
                      (
                        <div>
                          <VariableSizeList
                            ref={tableRef}
                            height={796}
                            itemCount={rows.length}
                            itemSize={(index) => mobile && !showFullRef.current && rows[index].isExpanded ? 175 : 25}
                            overscanCount={50}
                            birdirectionalOverscan={true}
                            className={!showFullRef.current ? "fixed-size-list" : mobile ? "full-fixed-size-list" : "fixed-size-list"}
                            innerElementType={({ children, style, ...rest }) => (
                              <>
                                <div className="header">
                                  <div>
                                    {headerGroups.map((headerGroup) => {
                                      var sorted = headerGroup.headers.filter((header) => header.isSorted == true);
                                      if (sorted.length == 0) {
                                        headerGroup.headers[0].isSorted = true;
                                        headerGroup.headers[0].isSortedDesc = true;
                                      } else if (sorted.length > 1) {
                                        headerGroup.headers[0].isSorted = false;
                                        headerGroup.headers[0].isSortedDesc = undefined;
                                      }
                                      return (
                                        <tr {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', color: 'white', overflow: 'visible', width: '100%' }} className={!showFullRef.current ? "tr header-container" : mobile ? "tr full-header-container" : "tr header-container"}>
                                          {headerGroup.headers.map((column, i) => {
                                            const id = `header_${i}`;
                                            const weight = column.isSorted ? "bold" : "normal"
                                            return (
                                              <td {...column.getHeaderProps()} className={!showFullRef.current ? `th row-td` : mobile ? `th full-row-td` : `th row-td`} onClick={() => headerGroup.headers.length - 1 == i ? ShowFilters() : null}>
                                                <div style={{ display: 'inline-block', paddingTop: '10px' }} >
                                                  <div id={id} {...column.getSortByToggleProps()} style={{ float: 'left', fontWeight: weight }}>{column.render("Header")}</div>
                                                  <div {...column.getSortByToggleProps()} style={{ float: 'right' }} >{generateSortingIndicator(column)}</div>
                                                  {column.canSort ? (
                                                    <UncontrolledTooltip placement="top" target={id} autohide={true} delay={0}>
                                                      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Click to sort by {column.exportValue}</div>
                                                    </UncontrolledTooltip>) : ("")
                                                  }
                                                </div>
                                                {showFilters ? column.filter ? (<Filter column={column} />) : null : null}
                                              </td>
                                            )
                                          }
                                          )}
                                        </tr>
                                      )
                                    })}
                                  </div>
                                </div>
                                <div className="data-grid-block" style={{ height: 796, width: '100%' }}>
                                  <div {...getTableBodyProps()} {...rest} style={style} className={!showFullRef.current ? 'data-row-group' : mobile ? 'full-data-row-group' : 'data-row-group'}>
                                    {children}
                                  </div>
                                </div>
                              </>
                            )}
                          >
                            {RenderRow}
                          </VariableSizeList>
                        </div>)}
                  </Fragment>)}
            </div>
          </CardBody>
        </Card>
      </Grid>
      {/* Side Progress Bars and Tab Content */}
      <Grid className='right-dashboard-content' item lg={2} md={2} sm={12} xs={12}>
        <Hidden only={['xs']}>
          <Card className='progress-bar-content' style={{ height: 256, border: '3px solid #3651B9', marginBottom: '15px' }}>
            {
              (early && !listLoading) ?
                (<div style={{ color: 'white', flex: 1, paddingLeft: '10px', paddingTop: '10px', justifyContent: 'center', alignSelf: 'center' }}><div>{`Oops, you're an early bird! No options data available until the market opens for today, change date for previous data.`}</div>
                  <div className='countdownTimer'>Market Opens In...</div>
                  <div className='countdownTimer'>{timer}</div></div>)
                :
                (!early && !listLoading && (data == null || data == undefined || data.length == 0)) ?
                  <div style={{ color: '#ff0033', paddingLeft: 10, flex: 1, justifyContent: 'center', alignSelf: 'center' }}>No unusual activity for this ticker today.</div>
                  :
                  (<CardBody style={{ backgroundColor: "#3A4DBC2B", padding: "5px 15px" }}>
                    <Grid container>
                      <Grid item style={{ width: '100%', paddingTop: "10px" }}>
                        <div>
                          {puttocall}
                        </div>
                      </Grid>
                      <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                        <div>
                          {put}
                        </div>
                      </Grid>
                      <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                        <div>
                          {call}
                        </div>
                      </Grid>
                      <Grid item style={{ width: '100%', paddingTop: "15px" }}>
                        <div>
                          {sentiment}
                        </div>
                      </Grid>
                    </Grid>
                  </CardBody>)
            }
          </Card>
          <Card className='tab-content' style={{ height: "calc((100vh - 293px))" }}>
            <CardHeader className="tab-content-header" style={{ backgroundColor: "#1A2344", height: "74px", minHeight: "74px" }}>
              <div className={'tab-container'}>
                <div className={showTabRef.current == 'chat' ? 'selected-tab' : 'unselected-tab'} onClick={() => setShowTab('chat')}>
                  {/* <ChatIcon style={{color:'white'}}/> */}
                  <img src={ChatTabIcon} alt="Logo" style={{ orderRadius: "0" }} />
                  <p>Chat</p>
                </div>
                <div className={showTabRef.current == 'watchlist' ? 'selected-tab' : 'unselected-tab'} onClick={() => setShowTab('watchlist')}>
                  <VisibilityIcon className="watchTab" style={{ color: 'white' }} />
                  <p>Watchlist</p>
                </div>
                <div className={showTabRef.current == 'news' ? 'selected-tab' : 'unselected-tab'} onClick={() => setShowTab('news')}>
                  {/* <AnnouncementIcon style={{color:'white'}}/> */}
                  <img src={NewsIcon} alt="Logo" style={{ borderRadius: "0" }} />
                  <p>News</p>
                </div>
              </div>
            </CardHeader>
            <CardBody style={{ padding: "0", height: "calc((100vh - 293px) - 74px)" }}>
              {showTabRef.current == 'chat' ? (<div style={{ display: 'flex', flexDirection: 'column' }}>
                <WidgetBot
                width='100%' style={{ height: 'calc((100vh - 293px) - 74px)' }}
                  server="730195990643998740"
                  channel="732316263333757038"
                />
              </div>) : null}
              {showTabRef.current == 'watchlist' ? (<div className="watchlist-container" style={{ padding: "20px 10px" }}>
                <Grid container direction="column" justify="center" alignItems="center" >
                  <WatchSearch searchString={watchInput} setWatchDoneInput={setWatchInput} keyPress={keyPress} AddWatchlistButton={AddWatchlistButton} />
                  <Grid container direction="row" justify="flex-start" alignItems="center" xs={12} style={{ maxHeight: 'calc((100vh - 293px) - 140px)', overflow: 'scroll', paddingBottom: '70px' }}>
                    <WatchList data={localState ?? []} list={watchList ?? []} Search={Search} RemoveWatchlist={RemoveWatchlist} loading={watchListLoading} />
                  </Grid>
                </Grid>
              </div>) : null}
              {showTabRef.current == 'news' ? (<div className="news-container" style={{ padding: "5px 10px 20px 20px" }}>
                {<Grid container className='visibleContainer'>
                  <Card className="card-stats news-block" style={{ height: '550px' }}>
                    <CardBody style={{ overflow: "scrolled" }} >
                      <Row>
                        <div>
                          {news.map((item, i) => {
                            return (
                              <Grid item style={{ paddingBottom: '15px', color: 'white' }}>
                                {renderNews(item)}
                                <hr />
                              </Grid>
                            )
                          })}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Grid>}
              </div>) : null}
            </CardBody>
          </Card>
        </Hidden>
      </Grid>

    </Grid>
  );
}

//#endregion

const condition = authUser => !!authUser;

export default withAuthorization(condition)(compose(withRouter, withFirebase)(
  HomePage));

//<AuthUserContext.Consumer> {authUser => (<HomePage user={authUser}>{...props}</HomePage>)}</AuthUserContext.Consumer>///