import React, {Component, useEffect, useState} from 'react';
import { AuthUserContext, withAuthentication, withAuthorization } from '../Session';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { createTheme } from '@mui/material/styles';
import PrimarySearchAppBar, { SecondarySearchAppBar } from '../Home/menu';
import { Grid, Button, Paper, Avatar, TextField, IconButton, LinearProgress, Modal } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { AccountBox, Assessment, Dashboard, LiveHelp, HelpOutlineRounded, ExitToApp, AutoGraph } from '@mui/icons-material';
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
import typeStyles from "../../assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withRouter } from '../App';
import HomeIcon from '@mui/icons-material/Home';
import * as ROUTES from '../../constants/routes';
import { useHistory, useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/img/logo.png';
import ImageUploader from 'react-images-upload';
import { boxShadow } from '../../assets/jss/material-kit-react';

import SideNav from '../Navbars/SideNav';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { firstBreakpoint, secondBreakpoint } from '../../constants/variables';

import Alert from '@mui/material/Alert';
import { string } from 'prop-types';

const Chargebee = require( '@chargebee/chargebee-js-react-wrapper')

// const useStyles = makeStyles(styles, typeStyles);
const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
}));

// Manage Account on Chargebee widget
function AccountPageManage(props) {
    const classes = useStyles();
    return(
        <Button variant="contained"  
            className={classes.button} 
            size="large" 
            href="javascript:void(0)" 
            data-cb-type="portal"
            style={{ whiteSpace: 'nowrap', backgroundColor: "#28ABDD", color:"white"}}>
            Manage Billing Account
        </Button>
    )
}

// Terms and Conditions
function TermsAndConditions(props) {

    const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: "translate(-50%, -50%)"
    }
    }));
    const classes = useStyles();
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const body = (
        <div style={{color: "black"}} className={[classes.paper, "termsModal"].join(" ")}>
            <TermsText/>
        </div>
    );
    return(
        <div>
        <Button variant="contained"  
            onClick={handleOpen}
            className={classes.button} 
            size="large" 
            style={{ whiteSpace: 'nowrap', backgroundColor: "rgb(230, 80, 2)", color:"white", width:'257.66px', marginTop: '10px'}}>
            Terms & Conditions
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            {body}
        </Modal>
      </div>
    )
}

//Text Field for the account page.
function AccountLabel (type, label, labelValue, labelName, showButton, handleChange){
    return (
        <TextField 
            id="filled-basic" 
            className="accountTextfield"
            label={label} 
            type={type}
            variant="filled" 
            value={labelValue}
            name={labelName}
            onChange={(e)=> {
                showButton(true);
                handleChange(e);
            }}
            />
    )
}

// Avatar upload row
function AccountAvatarUpload({showButton, handleChange}) {
    const classes = useStyles();

    return (
    <ImageUploader
        buttonText='Choose Image'
        singleImage={true}
        withIcon={false}
        label='Max file size: 5mb'
        fileContainerStyle={{
            backgroundColor:"transparent",
            boxShadow: "none",
            textAlign: "center",
            padding:"0",
            margin:"0"
        }}
        labelStyles={{
            color: "white",
            fontWeight: 'bold'
        }}
        buttonStyles={{
            backgroundColor: "transparent",
            color: "#28ABDD",
            fontWeight: 'bold'
        }}
        buttonText="+ UPLOAD IMAGE"
        onChange={(e)=> {
            showButton(true);
            handleChange(e);
        }}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}/>
    );
}

// The Account Page Paper
function AccountPageInfo({props, handleChange, state, handleUpload, handleUploadPassword, handleClose}){
    const classes = useStyles();

    const navigate = useNavigate();
    const [showButton, setShowButton] = useState(false);
    const [showButtonPassword, setShowButtonPassword] = useState(false);

    const redirectHome = () => navigate(ROUTES.HOME);
    return (
        <Paper xs={12} className='accountOuter'
                style={{fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif",}}>

            <Grid 
                    container 
                    className='accountInner'
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>

                <Grid item className="avatarSection">
                    <div className="avatarSectionContainer">
                        <div style={{paddingBottom: '20px'}}>
                            <Avatar src={state.url} className={classes.large} style={{marginLeft: 'auto',marginRight: 'auto', width: '100px', height: '100px'}}
                            avatarStyle={{ borderWidth: 3, borderColor: 'white', borderRadius: 5, borderStyle:'solid' }}  />
                        </div>
                        <div style={{paddingBottom: '20px'}}>
                            <AccountAvatarUpload showButton={setShowButton} handleChange = {handleChange}/>
                        </div>
                        <div style={{paddingBottom: '20px'}}>
                            {AccountLabel("", "Display Name", state.displayName, "displayName", setShowButton, handleChange)}
                        </div>
                        <div>
                            { state.showLoading &&(
                                <LinearProgress variant="indeterminate" />
                            )}
                            { showButton &&(
                                <Grid item style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <Button
                                        className="accountSaveButton"
                                        variant="contained" size="medium" color="primary" 
                                        onClick={()=> {
                                            handleUpload();
                                            setShowButton(false);
                                        }}
                                        >
                                        SAVE
                                    </Button>
                                </Grid>)
                            }
                        </div>
                    </div>
                </Grid>

                <Grid item className="detailsSection"> 

                    {/* Header */}
                    <div className="manageHeader"  style={{paddingBottom: '20px'}}>
                        <h1 style={{color:"white", display:"flex", justifyContent: "left", margin:"0"}}>Manage Account</h1> 
                    </div>
                    
                    {/* Update Password */}
                    <div style={{paddingBottom: '20px'}}>
                        <Accordion className='accountAccordion'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>UPDATE PASSWORD</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='accountPasswordDetails'>
                                <Typography>
                                    {AccountLabel("password", "Current Password", state.currentPw, "currentPw", setShowButtonPassword, handleChange)}
                                    {AccountLabel("password", "New Password", state.newPw, "newPw", setShowButtonPassword, handleChange)}
                                    { showButtonPassword &&(
                                            <Button
                                                className="accountSaveButton"
                                                variant="contained" size="medium" color="primary" 
                                                style={{ verticalAlign: "text-top"}}
                                                onClick={()=> {
                                                    handleUploadPassword();
                                                    setShowButtonPassword(false);
                                                }}>
                                                SAVE
                                            </Button>
                                    )}
                                    { state.showLoadingPassword &&(
                                        <LinearProgress style={{marginTop: "20px"}} variant="indeterminate" />
                                    )}
                                    { state.passwordSaved && (
                                        <Alert onClose={() => {handleClose()}} className="passwordSavedAlertSuccess" severity="success">Password Saved!</Alert>
                                    )}
                                    { state.passwordError && (
                                        <Alert onClose={() => {handleClose()}} className="passwordSavedAlertError" severity="error">Something went wrong updating your password. Please try again.</Alert>
                                    )}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* Manage Billing Account */}
                    <div style={{paddingBottom: '20px'}}>
                        <Accordion className='accountAccordion'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>MANAGE BILLING ACCOUNT</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <AccountPageManage />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    
                    {/* Terms and Conditions */}
                    <div style={{paddingBottom: '20px'}}>
                        <Accordion className='accountAccordion termsAccordion'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>TERMS AND CONDITIONS</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <TermsText/>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                
                </Grid>

            </Grid>

        </Paper>
    );
}

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            url: this.props.authUser.photoURL == null ? "" : this.props.authUser.photoURL,
            progress: 0,
            showLoading:false,
            showLoadingPassword: false,
            passwordSaved: false,
            passwordError: false,
            displayName: this.props.authUser.displayName == null ? "" : this.props.authUser.displayName,
            text: null,
            currentPw: null,
            newPw: null,
            mobile: false
        }; 

    }

    handleChange = e => {
        if(e[0] != null){
            const image = e[0];
            const text = e[0].name;
            this.setState({
                image: image,
                text: text
            });
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    handleUpload = () => {
        if(this.state.image != null){
            this.setState({
                showLoading: true,
            });
            this.props.firebase.doUploadAvatar(this.state.image, this.avatarCallback);
        }
        if(this.state.displayName != null)
            this.props.firebase.doSetProfile(this.state.displayName);
    }

    handleUploadPassword = () => {
        if(this.state.newPw != null && this.state.currentPw != null)
        {
            this.setState({
                showLoadingPassword: true,
                passwordSaved: false,
            });
            this.props.firebase.doReauthenticateUser(this.props.authUser.email, this.state.currentPw, this.state.newPw, this.passwordCallback);
        }
    }

    handleClose = () => {
        this.setState({
            passwordSaved: false,
            passwordError: false
        });
    }

    avatarCallback = (photoURL) => {
        this.setState({
            url: photoURL,
            showLoading: false,
            text: null
        });
    }

    passwordCallback = (response) => {
        if(response){
            this.setState({
                currentPw: null,
                newPw: null,
                showLoadingPassword: false,
                passwordSaved: true
            });
        }
        else{
            this.setState({
                currentPw: null,
                newPw: null,
                passwordSaved: false,
                showLoadingPassword: false,
                passwordError: true
            });
        }
        
    }

    updateDimensions = () => {
        this.setState({'mobile': window.innerWidth < firstBreakpoint});
      };
    
    componentDidMount(){
        window.Chargebee.registerAgain();
        // if(this.props.authUser.uid){
            // this.props.firebase.doGetPreferences(this.props.authUser.uid).on('value', snapshot =>{
            // var prefs = snapshot.val();
            // if(!prefs.avatarURL) {
            //     prefs.avatarURL = "";
            //     this.props.firebase.doSetPreferences(this.props.authUser.uid, prefs);
            // }
            // this.setState({prefs}, () => {
            // })});
        // };
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    render() { 
        const {prefs,mobile} = this.state;
        const handleMenuLogOut = () => {
            this.props.firebase.doSignOut();
            this.props.history.push(ROUTES.LOGIN);
            
          };
        return (
            <div className="accountPageSizing" style={{margin:0, padding:0, height:'100%', width:'100%', minHeight:'100vh', minWidth:'100vw', overflowX: 'hidden'}}>
                { mobile ? (<Navbar className='nav-block'  bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand href="/home">
                        <div>
                        <img src={logo} alt="Logo" height={40}/>
                        </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="/home" style={{minWidth: '80px'}}>
                                    <div>
                                        <Dashboard className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                        <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Dashboard</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/flowcast" style={{minWidth: '80px'}}>
                                    <div>
                                        <Assessment className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                        <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>FlowCast</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/earnings" style={{ minWidth: '80px' }}>
                                    <div>
                                    <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                    <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Earnings</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/stockalerts" style={{ minWidth: '80px' }}>
                                    <div>
                                    <AutoGraph className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                    <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Stock Alerts</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/account" style={{minWidth: '80px'}}>
                                    <div>
                                    <AccountBox className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                    <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>My Account</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="#" onClick={()=> window.$crisp.push(["do", "helpdesk:search"])} target="_blank" style={{minWidth: '80px'}}>
                                    <div>
                                        <LiveHelp className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                        <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Help</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link style={{minWidth: '80px'}}>
                                <div onClick={handleMenuLogOut}>
                                        <ExitToApp className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                        <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Log Out</div>
                                    </div>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>) :
                    (<SideNav page={"flowcast"} />)
                }
                <Grid container justifyContent='center' spacing={2} style={{paddingTop: '20px'}}>
                    <AccountPageInfo props={this.props} handleChange = {this.handleChange} state={this.state} handleUpload = {this.handleUpload} handleUploadPassword = {this.handleUploadPassword} handleClose = {this.handleClose} />
                </Grid>
            </div>
        );
    }

}

function TermsText(props){
    return (
        <div>
            <h3 className='termsText'>Terms Agreement and Privacy</h3>
            <h4 className='termsText'>Effective date: Jan 1, 2020</h4>
            <h4 className='termsText'>Legal</h4>
            <p className='termsText'>Terms of Service and Refund Policy</p>
            <p className='termsText'>I. REGISTRATION</p>
            <p className='termsText'>This User Agreement between Authorized User (“You”) and Sweep Cast, LLC (sweepcast.com ) (“the Site”) and any of our Site affiliates, agencies, sponsors, licensees or content partners (hereafter, collectively referred to as “SweepCast”, “We”, or “Us”) governs your use of these information services and web sites (collectively referred to as “the Tools”), including any document, content, services, functions or features accessible on or through the Site (the “Products”). Your use of the Site and the Tools is subject to the terms and conditions hereof. By using the Site or any other website owned, operated, licensed, or controlled by Us, you agree to be bound by the terms of the User Agreement. Other provisions that govern your use of the Site may be set forth in online notices appearing in connection with certain Products (collectively, the “Additional Terms”), all of which are incorporated by reference into this User Agreement. Your use of any Products that are subject to Additional Terms constitutes your acceptance of the respective Additional Terms.</p>
            <p className='termsText'>If you do NOT agree with any such Additional Terms, do NOT use the Product associated with them. You may review the User Agreement at any time by clicking on the “User Agreement” link that appears on the Site.</p>
            <p className='termsText'>A.) BY REGISTERING FOR OR USING THIS SITE, THE USER ACCEPTS ALL OF THESE TERMS (the “TERMS OF SERVICE”). USE OF THIS SITE WILL CONSTITUTE THE USER’S ACCEPTANCE OF ALL OF SUCH TERMS OF SERVICE.</p>
            <p className='termsText'>IF YOU DO NOT AGREE TO THESE “TERMS OF SERVICE”, PLEASE DO NOT USE THIS SITE. The Terms of Service for your continued use of this Site will be available at https://sweepcast.com/privacy. Please check this area before using this Site whenever a change has been indicated. Changes to the User Agreement posted on the home page of the Site is an effective notice to the User of the change in such terms and conditions. If you do not agree to these changes in the Terms of Service as they may occur, please arrange to terminate your registration with Us immediately (i) by notifying Us of your unwillingness to accept the changes to the Terms of Service and (ii) by discontinuing your use of the Site. Your continued use of Site after such changes in the Terms and Services will signify your acceptance of the change in the terms and conditions.</p>
            <p className='termsText'>B). YOU AGREE TO PAY THE PREDETERMINED SUBSCRIPTION FEE FOR YOUR USE TO THE SITE.</p> 
            <p className='termsText'>C). YOU AGREE NOT TO SHARE ACCESS TO YOUR ACCOUNT AND NOT TO REDISTRIBUTE ANY DATA YOU RECEIVE THROUGH YOUR USE OF THE SITE OR PRODUCTS.</p>           
            <p className='termsText'>You may not copy, modify, reproduce, republish, distribute, transmit or use the Site or the Products for commercial or other purposes, except to the extent required in order for you to use the Site and the Products in the manner expressly provided herein. You may NOT share your password with anyone. Our security system checks for simultaneous logins from multiple locations. If it is found that you are sharing your account, the system will auto-lock the account for a 24-hour period as required by our data provider agreements. You may not utilize automated systems or software to extract data from the Site.</p>
            <p className='termsText'>D.) If registering for use of the Site through the online registration process, the User verifies that the information provided by the user is accurate and correct to the best of the User’s knowledge.</p>
            <p className='termsText'>E.) We will store a confirmation in our database acknowledging your acceptance of these terms and conditions. You should understand that, upon acceptance of terms and condition, your use of the Site will always be subject to and pursuant to this Agreement. You may review this Agreement at any time by clicking on “Terms of Use” from our home page.</p>
            <p className='termsText'>F.) This Site and all its contents are made available to you, with the agreement that you will not copy or reproduce the contents of this Site, except for your own personal or internal company use, in any form and that you will not sell, lease, loan, or otherwise make them available to third parties or anyone else without the express written permission of the author. You may not utilize automated systems or software to extract data from the Site. You may not scrape or extract data from the Site for use in any external applications. If you are interested in licensing the use of these contents, contact us.</p>
            <p className='termsText'>Legal action will be taken where deemed appropriate if it is found that you are redistributing or reselling the information on the Site.</p>
            <p className='termsText'>II. DIGITAL GOODS (NO REFUND POLICY)</p>
            <p className='termsText'>A.) Since services are non­tangible, irrevocable, digital goods; we do not issue refunds, which you are solely responsible for understanding. During checkout, upon clicking the final submit button, your purchase is charged to the credit/debit card that you supplied. All sales are final.</p>
            <p className='termsText'>B.) You cannot cancel a purchase or receive a refund for a purchase. You can cancel future recurring subscription payments at any time by cancellation through your account profile page. You may cancel at any time from your Account Profile page.</p>
            <p className='termsText'>C.) If you are under a free trial period, you will be charged at the end of your trial unless cancelled. We do not provide refunds. All sales are final. it is your responsibility to manage your subscription accordingly.</p>
            <p className='termsText'>D.) All plans auto-renew. If you do not wish to renew, you must cancel 24 hours prior to your renewal date.</p>
            <p className='termsText'>E.) You agree to not abuse the free trial or payment system(s). You are entitled to ONE free trial period. Our system automatically checks if you have already signed up for a free trial. If it is found that you are abusing the free trial system, your free trial period is immediately removed from future accounts and is transitioned to a normal non-trial account.</p>
            <p className='termsText'>III. CHAT ROOM; CODE OF CONDUCT</p>
            <p className='termsText'>You understand that the use of our chat room is a revocable privilege and subject to the Chat Code of Conduct rules outlined here. We reserve the right to ban any user from participating in our chat room that violates any of the rules outlined in Chat Code of Conduct.</p>
            <p className='termsText'>YOU AGREE TO NOT SOLICIT, PROMOTE, OR SHARE EXTERNAL PREMIUM DATA WITHIN THE CHAT GROUP.</p>
            <p className='termsText'>A.) You may not share or promote your own for-profit, paid, or non-public services or websites that you are affiliated with.</p>
            <p className='termsText'>B.) You may not share premium(paid) data from external sources including screenshots, non-public links, documents, or copyrighted material which you don’t solely own, have permission to, or are licensed to redistribute.</p>
            <p className='termsText'>C.) You may not promote, share, discuss, or suggest the use of competing services within the chat room.</p>
            <p className='termsText'>You Agree to not solicit members of the Site for any reason.</p>
            <p className='termsText'>IV. PRIVACY</p>
            <p className='termsText'>We respect your privacy rights! We will not sell or distribute your email address or other information without your express consent. Only SweepCast, our Agencies, Attorneys, and Partners are entitled to your information.</p>
            <p className='termsText'>V. COPYRIGHT; PROPRIETARY DATA; USE PROVISIONS</p>
            <p className='termsText'> Neither SweepCast nor any individual or organization that may be a source of content distributed through the Site makes any warranty as to the timeliness and accuracy of the information. The Products are provided for informational purposes only and do not constitute investment advice. Access to, transmission or receipt of, or reliance upon, Content from the Site does not create, and is not intended to create, an investor­ broker relationship between you or any other person or any Content Partner.</p>
            <p className='termsText'> A.) Except for the license granted in the User Agreement, all right, title, and interest in the Site and the Tools (including all trademarks, copyrights, and other intellectual property rights), in all languages, formats and media throughout the world, are and will continue to be the exclusive property of SweepCast and/or the Content Partners. You may not decompile, reverse engineer, disassemble, rent, lease, loan, sublicense, or create derivative works from the Site.</p>
            <p className='termsText'> VI. NO REPRESENTATIONS OR WARRANTIES</p>
            <p className='termsText'> Neither SweepCast, its Agencies, Attorneys, Members, Affiliates, nor any Content Partner makes any warranty or representation that the Information is accurate and timely. You understand that use of the Site and of the Products is undertaken at Your own risk. Any information or data provided by SWEEP CAST is not exhaustive or complete. NEITHER SWEEPCAST NOR ANY CONTENT PARTNER ASSUMES ANY LIABILITY FOR THE USE OR INTERPRETATION OF ANY PRODUCT. THE SITE AND THE PRODUCTS ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES WITH RESPECT TO PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON­INFRINGEMENT OF THIRD PARTY RIGHTS, ACCURACY, OMISSIONS, COMPLETENESS, CURRENTNESS, OR TIMELINESS. YOUR EXCLUSIVE REMEDY UNDER THIS AGREEMENT , IF ANY, FOR ANY CLAIM FOR DAMAGES RELATING TO YOUR USE OF THE SITE OR THE PRODUCTS, WHETHER ON THE BASIS OF CONTRACT, NEGLIGENCE, OR ANY OTHER BASIS, WILL BE LIMITED TO THE AGGREGATE AMOUNT OF CHARGES PAID BY YOU TO SWEEP CAST DURING THE 12­MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. YOU ACKNOWLEDGE THAT PROVISION OF THE SITE AND CONTENT ENTAILS THE LIKELIHOOD OF SOME HUMAN AND MACHINE ERRORS, DELAYS, INTERRUPTIONS AND LOSSES, INCLUDING THE INADVERTENT LOSS OF DATA.</p>
            <p className='termsText'> V. LIMITATION OF LIABILITY</p>
            <p className='termsText'> IN NO EVENT WILL THE CONTENT CREATOR, ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE WEBSITE, OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE WEBSITE, EVEN IF THE CONTENT CREATOR OR ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE LIABILITY OF THE CONTENT CREATOR AND ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE CONTENT CREATOR FOR THE WEBSITE IN THE IMMEDIATELY PRECEDING TWELVE MONTHS, BUT IN NO CASE WILL SUCH LIABILITY TO YOU EXCEED 12 MONTHS OF SUBSCRIPTION FEE OR $750 WHICHEVER IS LOWEST TOTAL AMOUNT. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE CONTENT CREATOR FOR THE WEBSITE, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM THE CONTENT CREATOR, REGARDLESS OF THE CAUSE OF ACTION.</p>
            <p className='termsText'>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
            <p className='termsText'>VI. DISCLAIMER</p>
            <p className='termsText'>There is a high degree of risk involved in trading stocks and options. Past results are not indicative of future returns. Sweep Cast LLC (SweepCast) is not a registered investment advisor nor is licensed as such with any federal or state regulatory agency. SweepCast does not manage client assets in any way. Information provided and opinions expressed on the website do not constitute investment advice. Any and all ideas expressed, research, tutorials, and instructional resources are for educational purposes only. The ideas expressed are solely the opinions of SweepCast. We advise everyone to know the risks involved with trading stocks and options. We encourage every visitor to the website to do his/her own research and to consult with his/her own financial advisor(s) prior to engaging in any investing activities, and to make sure he/she knows all the potential risks involved. Any investment decision that results in losses or gains made based on any information on this site, or related services is not the responsibility of SweepCast.</p>
            <p className='termsText'>Your use of ideas, systems, and/or data provided by SweepCast are at your own risk and it is your sole responsibility to evaluate the accuracy, completeness, and usefulness of the information. No users in the chatroom are allowed to give financial advice other than sharing their sole opinion. You should do your own research before taking any advice or ideas. No users, employees, visitors or anyone else on a SweepCast Chatroom, Forum or Email is giving financial advice.</p>          
            <p className='termsText'>Disclaimer</p>
            <p className='termsText'>The U.S. Securities and Exchange Commission (SEC) requires the following legal notices: All material presented on Sweep Cast LLC is not to be regarded as investment advice, but for general informational purposes only. Trading does involve risk, so caution must always be utilized. We cannot guarantee profits or freedom from loss. You assume the entire cost and risk of any trading you choose to undertake. You are solely responsible for making your own investment decisions. Sweep Cast LLC, its representatives, its principals, its moderators, and its members, are NOT registered as securities broker-dealers or investment advisors either with the U.S. Securities and Exchange Commission or with any state securities regulatory authority. Sweep Cast LLC does not manage client assets in any way. All information/ideas provided on Sweep Cast LLC products and services are for educational purposes only. We recommend consulting with a registered investment advisor, broker-dealer, and/or financial advisor.</p>           
            <p className='termsText'>Sweep Cast LLC requires that you are a nonprofessional subscriber to receive data through our service. If you are not, please let us know.</p>
            <p className='termsText'>Refund Policy</p>
            <p className='termsText'>Due to the nature of the services, there are no refunds available for any services at all. Annual memberships have no pro-rated refunds. Payment is final and as-is.</p>
        </div>
    )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(compose(withRouter, withFirebase, withStyles(styles))(AccountPage));