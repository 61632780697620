import React, { Component, useRef, useEffect, useState, Fragment, memo } from 'react';
import SideNav from '../Navbars/SideNav'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AddBox, ArrowDownward, ImageSearchRounded, SearchRounded, CancelRounded, CallMade, VolumeUp, VolumeOff, LiveHelp, StarOutlined, Dashboard, Assessment, AccountBox, ExitToApp, AssessmentRounded, WbIridescentTwoTone, Remove, ArrowDownwardSharp, ArrowUpward, PlusOneRounded, ExpandMore, ExpandLess, RepeatRounded, ArrowDownwardRounded, ArrowUpwardRounded, AutoGraph } from '@mui/icons-material';
import logo from '../../assets/img/logo.png';
import * as ROUTES from '../../constants/routes';
import { withRouter } from '../App';
import { withFirebase } from '../Firebase'
import { firstBreakpoint, secondBreakpoint } from '../../constants/variables';
import { Button, Chip, CircularProgress, Grid, Modal } from '@mui/material';
import { withAuthentication, withAuthorization } from '../Session';
import { compose } from 'recompose';
import DatePicker from 'react-datepicker';
import * as moment from 'moment-business-days';
import * as momenttz from 'moment-timezone';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { useTable, useBlockLayout, useSortBy, useFilters, useExpanded, useFlexLayout, useResizeColumns, useColumnOrder } from 'react-table'
import { FixedSizeList, VariableSizeList } from 'react-window'
import { Filter, DefaultColumnFilter, SelectColumnFilter } from '../Home/filters';
import {
  UncontrolledTooltip
} from "reactstrap";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { Time, Open, Price, High, Low, ProfitLoss, Ticker, ChartColumn, Direction } from './controls'
import { makeStyles } from '@mui/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

const holidayList = [
  "07-03-2020",
  "09-07-2020",
  "12-25-2020",
  "01-01-2021",
  "01-18-2021",
  "02-15-2021",
  "04-02-2021",
  "05-31-2021",
  "07-05-2021",
  "09-06-2021",
  "12-24-2021",
  "01-17-2022",
  "04-15-2022",
  "05-30-2022",
  "07-04-2022",
  "09-05-2022",
  "01-02-2023",
  "01-16-2023",
  "02-20-2023",
  "04-07-2023",
  "05-29-2023",
  "06-19-2023",
  "07-04-2023",
  "09-04-2023",
  "11-23-2023",
  "12-25-2023"
]


let timerId = null;

moment.updateLocale('us', {
  holidays: holidayList,
  holidayFormat: 'MM-DD-YYYY',
  workingWeekdays: [1, 2, 3, 4, 5],

});

const renderNews = (news, setModalOpen, setTicker) => {
  if (news == undefined || news == null) return null;
  if (news != undefined) {
    const ticker = news?.stocks != undefined ? news?.stocks[0].name.toString() : "";
    const tag = news?.tags != undefined ? news?.tags[0].name.toString() : "";
    const body = news?.body !== '' ? sanitizeTitle(news?.body) : sanitizeTitle(news?.title);
    const date = moment(news?.created).format('M/DD - h:mm a');
    const open = () => {
      setTicker(ticker);
      setModalOpen(true);
    }
    return (<Grid item lg={5} md={12} sm={12} style={{ marginLeft: 20, marginRight: 10, marginBottom: 10, color: 'white' }}>
      <div style={{ fontWeight: 'bold', fontSize: '20' }}>{date}</div>
      <div>
        <span style={{ fontWeight: 'bold', fontSize: '20' }}>{tag} - </span>
        <span><Chip clickable onClick={open} style={{ backgroundColor: 'lightslategray', color: 'white', fontWeight: 'bold' }} label={ticker} /></span>
      </div>
      <div>{body}</div>
      <br />
    </Grid>);
  }
  return (<div></div>)
}

const sanitizeTitle = (item) => {
  return `${item.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')}`
}

const generateSortingIndicator = column => {
  const active = column.isSorted;
  return (<div style={{ opacity: active ? '100%' : '50%', paddingLeft: 5 }}>{column.canSort ? active ? (column.isSortedDesc ? (<ArrowDownwardRounded style={{ width: '15', height: '15', borderRadius: "0" }} />) : (<ArrowUpwardRounded alt="Logo" style={{ width: '15', height: '15', borderRadius: "0" }} />)) : "" : ""}</div>);
}

const getClassName = rowData => {
  const body = rowData.row.original.body;
  return rowData.row.original.tag === "Price Spikes" ? body.includes(" UP ") ? "bullBadge" : body.includes(" DOWN ") ? "bearBadge" : "" : "";
}
const NewsTable = (props) => {
  const news = props.news;
  const data = React.useMemo(() => {
    var newArray = [];
    if (!news || news?.length === 0 || news?.length === undefined) return [];
    if (!!news) {
      newArray = news.map((newsItem) => {
        const ticker = newsItem?.stocks != undefined ? newsItem?.stocks[0].name.toString() : "";
        const tag = newsItem?.tags != undefined ? newsItem?.tags[0].name.toString() : "";
        const body = newsItem?.body !== '' ? sanitizeTitle(newsItem?.body) : sanitizeTitle(newsItem?.title);
        const date = moment(newsItem?.created).format('M/DD - h:mm a');
        return{ "ticker": ticker, "tag": tag, "body": body, "date": date };
      })
    }
    return newArray;
  }, [news])

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: 'left' }}></div>,
        sortType: "basic",
        id: 'chart',
        Cell: rowData => <div>{ChartColumn(props.setModalOpen, props.setTicker, rowData)}</div>,
        disableSortBy: true,
        width: '5%',
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Time</div>,
        accessor: 'date',
        sortType: "basic",
        Cell: rowData => <div style={{ textAlign: 'left' }}>{rowData.row.original.date}</div>,
        disableSortBy: false,
        width: '10%',
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left', width: '30px' }}>Ticker</div>,
        accessor: 'ticker',
        sortType: "basic",
        Cell: rowData => <div style={{width: '30px'}}>{rowData.row.original.ticker}</div>,
        disableSortBy: false,
        width: '5%',
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Alert Type</div>,
        accessor: 'tag',
        sortType: "basic",
        Cell: rowData => <div className={getClassName(rowData)} style={{ backgroundColor: 'grey', textAlign: 'center', color: '#FFFFFF', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', boxShadow: '0px 0px 1px black', width: '80px'}}>{rowData.row.original.tag}</div>,
        disableSortBy: false,
        width: '10%',
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Alert</div>,
        accessor: 'body',
        sortType: "basic",
        Cell: rowData => <div>{rowData.row.original.body}</div>,
        disableSortBy: false,
        width: '33%',
        disableExport: true,
      },
    ],
    []
  );



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn: { Filter: DefaultColumnFilter },
    autoResetPage: false,
    autoResetSortBy: false,
    autoResetFilters: false,
    initialState: {
      sortBy: [
        {
          id: 'time',
          desc: true
        }
      ]
    }
  },

    useFilters,
    useSortBy,
  )

  return (
    <table className='stockAlertsTable stockNewsTableInner' {...getTableProps()} style={{ backgroundColor: '#25305D', width: '100%', borderSpacing: '0px 4px' }}>
      <thead>
        {headerGroups.map(headerGroup => {
          var sorted = headerGroup.headers.filter((header) => header.isSorted == true);
          if (sorted.length == 0) {
            headerGroup.headers[0].isSorted = true;
            headerGroup.headers[0].isSortedDesc = true;
          } else if (sorted.length > 1) {
            headerGroup.headers[0].isSorted = false;
            headerGroup.headers[0].isSortedDesc = undefined;
          }
          return (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ color: 'white', overflow: 'visible', width: '100%' }}>
              {headerGroup.headers.map((column, i) => {
                const weight = column.isSorted ? "bold" : "normal"
                return (
                  <td {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })} className={`th`}>
                    <div style={{ display: 'inline-block', paddingTop: '10px' }} >
                      <div {...column.getSortByToggleProps()} style={{ float: 'left', fontWeight: weight }}>{column.render("Header")}</div>
                      <div {...column.getSortByToggleProps()} style={{ float: 'right' }} >{generateSortingIndicator(column)}</div>

                    </div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {(!!data && data?.length > 0) && rows.map((row, i) => {
          prepareRow(row)
          const id = `${row.original.id}`;
          return (
            <tr {...row.getRowProps({
              style: { minWidth: row.minWidth, width: row.width },
            })} style={{ backgroundColor: '#1F283D', color: 'white' }}>
              {row.cells.map(cell => {
                return <>
                  <td id={id} {...cell.getCellProps()} style={{ textAlign: 'left', color: 'white', width: cell.column.width }}>{cell.render('Cell')}</td>
                </>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function Table({ columns, data, firebase, date, setModalOpen, setTicker, updateDate }) {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn: { Filter: DefaultColumnFilter },
    autoResetPage: false,
    autoResetSortBy: false,
    autoResetFilters: false,
    initialState: {
      sortBy: [
        {
          id: 'time',
          desc: true
        }
      ]
    }
  },

    useFilters,
    useSortBy,
  )

  useEffect(()=>{
    if(!data && !timerId){
      setTimeout(() => {
      setLoading(false);
    }, 20000);
    }
    else if(!!data) {
      if(!timerId){
        clearTimeout(timerId);
        timerId = null;
      }
      setTimeout(() => {
        setLoading(false);
      }, 500)
    }
  }, [data]);

  useEffect(() => {
    firebase.benzingaNews().on('value', snapshot => {
      var x = snapshot.val();
      const newsList = Object.keys(x).map(key => ({
        ...x[key],
      }));
      setNews(newsList);
    });
  }, []);

  // Render the UI for your table
  return (
    <Grid container direction='row' spacing={3} style={{height: '100vh'}}>
      <Grid className='stockAlertsGrid' item lg={6} md={6} sm={12} style={{height: '100vh', width: '100%' }}>
      <h3 className="flowcastHeader" style={{ paddingLeft: '15px' }}>{`Stock Alerts `}
            {date != undefined && date != null &&
              (<DatePicker
                disabledKeyboardNavigation
                style={{ justifyContent: 'start', alignItems: 'start', flex: 0, width: '20%', zIndex: 10000, borderRadius: '4px', height: 36, }}
                selected={new Date(date)}
                onChange={updateDate}
                customInput={<DateInput />}
                filterDate={isWeekday}
                minDate={new Date(2022, 8, 18)}
                maxDate={moment().prevBusinessDay()} />)
            }
      </h3>
        <table className='stockAlertsTable' {...getTableProps()} style={{ backgroundColor: '#25305D', width: '100%' }}>
          <thead className="" >
            {headerGroups.map(headerGroup => {
              var sorted = headerGroup.headers.filter((header) => header.isSorted == true);
              if (sorted.length == 0) {
                headerGroup.headers[0].isSorted = true;
                headerGroup.headers[0].isSortedDesc = true;
              } else if (sorted.length > 1) {
                headerGroup.headers[0].isSorted = false;
                headerGroup.headers[0].isSortedDesc = undefined;
              }
              return (
                <tr {...headerGroup.getHeaderGroupProps()} style={{ color: 'white', overflow: 'visible', width: '100%' }}>
                  {headerGroup.headers.map((column, i) => {
                    const weight = column.isSorted ? "bold" : "normal"
                    return (
                      <td {...column.getHeaderProps()} className={`th`}>
                        <div style={{ display: 'inline-block', paddingTop: '10px' }} >
                          <div {...column.getSortByToggleProps()} style={{ float: 'left', fontWeight: weight }}>{column.render("Header")}</div>
                          <div {...column.getSortByToggleProps()} style={{ float: 'right' }} >{generateSortingIndicator(column)}</div>

                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {(!!data && data?.length > 0) && rows.map((row, i) => {
              prepareRow(row)
              const goldRow = ((row.original.cost > row.original.EOD) && row.original.type == "algSell") || ((row.original.cost < row.original.EOD) && row.original.type == "algBuy");
             const id = `${row.original.id}`;
              return (
                <tr {...row.getRowProps()} style={{ backgroundColor: '#1F283D', color: goldRow ? '#deb723' : 'white'}}>
                  {row.cells.map(cell => {
                    return <>
                      <td id={id} {...cell.getCellProps()} style={{ textAlign: 'left' }}>{cell.render('Cell')}</td>
                    </>
                  })}
                </tr>
              )
            })}
            <>
            {loading && (<CircularProgress color="secondary" style={{ position: 'absolute', left: '50%', top: '50%' }} />)}
            {(!loading && (!data || data?.length == 0)) && (<div style={{ color: 'red', textAlign: 'center', paddingTop: 20, fontWeight: 'bold', fontSize: 20 }}>No data for this day</div>)}
            </>
          </tbody>
        </table>
        {/* {(!data || data?.length == 0) && (<div style={{ color: 'red', textAlign: 'center', paddingTop: 20, fontWeight: 'bold', fontSize: 20 }}>No data for this day</div>)} */}
      </Grid>
      <Grid className='stockAlertsGrid stockAlertsGridNews' item container direction="row" lg={6} md={6} sm={12} style={{ height: '100vh' }}>
      <h3 className="flowcastHeader stockNewsHeader" style={{ paddingLeft: '15px', marginBottom:'20px' }}>{`Stock News `}</h3>
      <DatePicker disabled className="stockAlertsHiddenDatePicker" enabled='false' visible='false'></DatePicker>
        <NewsTable news={news} setTicker={setTicker} setModalOpen={setModalOpen} />
      </Grid>
    </Grid>
  )
};

const ChartPopup = ({ handleOpen, data, open }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: "translate(-50%, -50%)"
    }
  }));
  const classes = useStyles();


  const body = (
    <div style={{ color: "black" }} className={[classes.paper]}>
      <TradingViewWidget symbol={data}
        theme={Themes.DARK} />
    </div>
  );

  const handleClose = () => {
    handleOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
}

const DateInput = ({ value, onClick }) => {
  return (
    <Grid onClick={onClick} container direction='row' justifyContent='flex-start' alignItems='center' style={{ marginBottom: '10px' }}>
      <CalendarIcon style={{ width: 20, height: 20 }} />
      <div
        style={{ color: 'white', fontSize: '12px', paddingLeft: '5px' }}>
        {value}
      </div>
    </Grid>
  );
};

const isWeekday = (dateCheck) => {
  var d = moment(dateCheck, 'MM-DD-YYYY');
  return d.isBusinessDay() && !d.isHoliday() && moment().diff(dateCheck) >= 0;
};

const StockAlerts = (props) => {
  const navigate = useNavigate();
  const { firebase, authUser } = props;
  const user = {
    user_id: authUser ? authUser.uid : null,
    email: authUser ? authUser.email : null,
    name: authUser ? authUser?.name : null,
    avatar: authUser ? authUser?.photoURL : null
  };

  const handleMenuLogOut = () => {
    props.firebase.doSignOut();
    navigate(ROUTES.LOGIN);

  };

  const [mobile, setMobile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ticker, setTicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(null);

  const updateDate = (updateDate) => {
    setDate(updateDate)
  }

  useEffect(() => {
    var d = momenttz().tz('America/Los_Angeles');
    if (!moment(d).isBusinessDay() && !moment(d).isHoliday()) d = moment(d).prevBusinessDay();
    setDate(new Date(d));
  }, [props]);

  useEffect(() => {
    try {
      if (!date) {
        setData([])
        return;
      }
      const r = firebase.stockActivity(date)
      if (!r) {
        setData([]);
        return;
      }
      r.on('value', (snapshot) => {
        if (!snapshot) {
          setData([]);
          setLoading(false);
          return;
        }
        const snappy = snapshot.val();
        if (!snappy) {
          setData([]);
          setLoading(false);
          return;
        }
        const stockList = Object.keys(snappy).map(key => ({
          ...snappy[key],
        }));
        setData(stockList);
        setLoading(false);
      })
    } catch (ex) {
      setData([])
    }
  }, [date])

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: 'left' }}></div>,
        sortType: "basic",
        id: 'chart',
        Cell: rowData => <div>{ChartColumn(setModalOpen, setTicker, rowData)}</div>,
        disableSortBy: true,
        width: 125,
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Time</div>,
        accessor: 'time',
        sortType: "basic",
        Cell: rowData => <div>{Time(rowData)}</div>,
        disableSortBy: false,
        width: 125,
        disableExport: true,
      },

      {
        Header: () => <div style={{ textAlign: 'left' }}>Type</div>,
        accessor: 'type',
        sortType: "basic",
        Cell: rowData => <div>{Open(rowData)}</div>,
        disableSortBy: false,
        width: 125,
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Ticker</div>,
        accessor: 'ticker',
        sortType: "basic",
        Cell: rowData => <div>{Ticker(rowData)}</div>,
        disableSortBy: false,
        width: 125,
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>Alert Price</div>,
        accessor: 'cost',
        disableSortBy: false,
        Cell: rowData => <div>{Price(rowData)}</div>,
        width: 125,
        disableExport: true,
      },
      {
        Header: () => <div style={{ textAlign: 'left' }}>EOD</div>,
        accessor: 'EOD',
        sortType: "basic",
        Cell: rowData => <div>{Direction(rowData)}</div>,
        disableSortBy: false,
        width: 125,
        disableExport: true,
      },

      {
        Header: () => <div style={{ textAlign: 'left' }}>P/L</div>,
        id: 'P_L',
        sortType: "basic",
        Cell: rowData => <div>{ProfitLoss(rowData)}</div>,
        disableSortBy: false,
        width: 125,
        disableExport: true,
      }
    ],
    []
  )

  useEffect(() => {
    const handleWindowResize = () => {
      const value = window.innerWidth < firstBreakpoint;
      if(value != mobile)
        setMobile(window.innerWidth < firstBreakpoint);
    }
    window.addEventListener("resize", handleWindowResize);
    setMobile(window.innerWidth < firstBreakpoint);

    window.onresize(); // called to initially set the height.
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <div id="main-outer-div">
        {mobile ? (<Navbar className='nav-block' variant="dark" expand="lg">
          <Navbar.Brand href="/home">
            <div>
              <img src={logo} alt="Logo" height={40} />
            </div>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/home" style={{ minWidth: '80px' }}>
                <div>
                  <Dashboard className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Dashboard</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/flowcast" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>FlowCast</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/earnings" style={{ minWidth: '80px' }}>
                <div>
                  <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Earnings</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/stockalerts" style={{ minWidth: '80px' }}>
                <div>
                  <AutoGraph className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Stock Alerts</div>
                </div>
              </Nav.Link>
              <Nav.Link href="/account" style={{ minWidth: '80px' }}>
                <div>
                  <AccountBox className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>My Account</div>
                </div>
              </Nav.Link>
              <Nav.Link href="#" onClick={() => window.$crisp.push(["do", "helpdesk:search"])} style={{ minWidth: '80px' }}>
                <div>
                  <LiveHelp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Help</div>
                </div>
              </Nav.Link>
              <Nav.Link style={{ minWidth: '80px' }}>
                <div onClick={handleMenuLogOut}>
                  <ExitToApp className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                  <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Log Out</div>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>) :
          (<SideNav page={"stockalerts"} user={user} firebase={firebase} />)
        }

        <div className='below-nav-block' style={{ margin: 0, paddingLeft: mobile ? 0 : 75, paddingRight: mobile ? 0 : 10, height: '100%', width: '100%', minHeight: '100vh', maxHeight: '100vh', minWidth: '100vw', overflowX: 'hidden', overflowY: 'hidden' }}>
          {loading && (<CircularProgress color="secondary" style={{ position: 'absolute', left: '50%', top: '50%' }} />)}
          {!loading && (<Table columns={columns} data={data} firebase={firebase} setModalOpen={setModalOpen} setTicker={setTicker} updateDate={updateDate} date={date} />)}
        </div>
      </div>
      {(modalOpen) &&
        (<ChartPopup open={modalOpen} handleOpen={setModalOpen} data={ticker} isVideo={false} />)}
    </>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(compose(withRouter, withFirebase)(
  StockAlerts));

