export const LANDING = '/';
export const SIGN_UP = '/signup';
export const LOGIN = '/login';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const SNAPSHOT = '/flowcast';
export const EARNINGS = '/earnings';
export const STOCKALERTS = '/stockalerts';
export const FREEMIUM = '/freemium';