import React, { Component, useState } from 'react';
import { withRouter } from '../App';
import { compose } from "recompose";
import { IconButton } from '@mui/material';
import { withAuthentication, withAuthorization } from '../Session';
import logo from '../../assets/img/newlogo.png';
import {withFirebase} from '../Firebase'
import { VolumeUp, VolumeOff, Dashboard, Assessment, AccountBox, Equalizer, DateRange, Subject, AutoGraph, InfoRounded} from '@mui/icons-material';
import { Grid, Popover } from '@mui/material';
import * as ROUTES from '../../constants/routes';
import { UncontrolledTooltip } from 'reactstrap';

class SideNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true,
          activity: [],
          tempActivity: [],
          news: [],
          newsIndex: 0,
          puts: 0,
          date: null,
          calls: 0,
          bears: 0,
          bulls: 0,
          open: false,
          prefs : {'audioAlerts': false},
          user: null,
          updating: false,
          ticker: "",
          anchorEl: null
        };
      }

    componentDidMount(){
    }

    render(){
      const { anchorEl } = this.state;
      const handleClick = (event) => {
        this.setState({'anchorEl' : event.currentTarget});
      };

      const handleClose = () => {
        this.setState({'anchorEl' : null});
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
        return (
          <div className="side-bar">
            <a href="/home">
                <img src={logo} alt="Logo" style={{width: '100%'}}/>
            </a>
            <a href="/home">
              <Dashboard id="homeicon" className={"side-bar-icon" + (this.props.page == "home" ? ' icon-active' : ' icon-inactive')} style={{ marginTop:'50px'}}/>
              <UncontrolledTooltip placement="top" target={"homeicon"} autohide={true} delay={0}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Home</div>
              </UncontrolledTooltip>
            </a>
            <a href="/flowcast">
              <Subject id="flowcasticon" className={"side-bar-icon" + (this.props.page == "flowcast" ? ' icon-active' : ' icon-inactive')}  style={{ marginTop: '50px'}} />
              <UncontrolledTooltip placement="top" target={"flowcasticon"} autohide={true} delay={0}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>FlowCast</div>
              </UncontrolledTooltip>
            </a>
            <a href="/earnings">
              <DateRange id="dateicon" className={"side-bar-icon" + (this.props.page == "earnings" ? ' icon-active' : ' icon-inactive')}  style={{ marginTop: '50px'}} />
              <UncontrolledTooltip placement="top" target={"dateicon"} autohide={true} delay={0}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Earnings</div>
              </UncontrolledTooltip>
            </a>
            <a href="/stockalerts">
              <AutoGraph id="stockalerticon" className={"side-bar-icon" + (this.props.page == "stockalerts" ? ' icon-active' : ' icon-inactive')}  style={{ marginTop: '50px'}} />
              <UncontrolledTooltip placement="top" target={"stockalerticon"} autohide={true} delay={0}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Alerts</div>
              </UncontrolledTooltip>
            </a>
            <div className="account-icon">
              <InfoRounded className={"side-bar-icon"} style={{color:'white'}} onClick={this.props.openVideo} />
            {this.props.prefs && this.props.prefs.audioAlerts && (<VolumeUp className={"side-bar-icon"} style={{color:'white'}} onClick={this.props.updatePrefs}/>) || (<VolumeOff className={"side-bar-icon"} style={{color:'silver'}} onClick={this.props.updatePrefs}/>) }
                <AccountBox id="accounticon" onClick={handleClick} className={"side-bar-icon" + (this.props.page == "account" ? ' icon-active' : ' icon-inactive')} style={{padingTop: 20}}/>
                <UncontrolledTooltip placement="top" target={"accounticon"} autohide={true} delay={0}>
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Account</div>
                </UncontrolledTooltip>
            </div>
            <Popover 
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Grid className="accountNavPopover" container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Grid item style={{fontSize:'12px', color: '#9A9A9A', padding: '14px'}}><a href="/account">My Account</a></Grid>
                <Grid item style={{fontSize:'12px', color: '#9A9A9A', padding: '14px'}}><a href="#" onClick={()=> window.$crisp.push(["do", "helpdesk:search"])}>Help</a></Grid>
                <Grid item style={{fontSize:'12px', color: '#9A9A9A', padding: '14px'}}><a href="#" onClick={() => {
                  this.props.firebase.doSignOut();
                  this.props.history.push(ROUTES.LOGIN);
                }}>Logout</a></Grid>
              </Grid>
            </Popover>
          </div>
        );
    };
};

const condition = authUser => !!authUser;
 
export default withAuthorization(condition)(compose(withRouter, withFirebase)(
 SideNav));