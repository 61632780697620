import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Navigation from '../Navigation';
import LogInPage from '../Login';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account'
import Snapshot from '../Snapshot';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { withAuthentication } from '../Session';
import Earnings from '../Earnings';
import StockAlerts from '../StockAlerts';
import Freemium from '../Freemium';

const theme = createTheme();

// Warn if overriding existing method
if (Array.prototype.equals)
  console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array)
    return false;

  // compare lengths - can save a lot of time 
  if (this.length != array.length)
    return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i]))
        return false;
    }
    else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });

const App = () => {
  window.onresize = function () {
    document.body.height = window.innerHeight;
  }

  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "f1496ff1-b3a1-49a2-98f5-2b29d47a3f8e";
  (function () {
    var d = document;
    var s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  return (
    <AuthUserContext.Consumer>
      {
        authUser => {
          const user = {
            user_id: authUser ? authUser.uid : null,
            email: authUser ? authUser.email : null,
            name: authUser ? authUser?.name : null
          };
          window.$crisp.push(["set", "user:email", [user.email]]);
          return (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Router>
                  <Routes>
                    <Route path="/" element={<Navigate to={!!user.user_id ? ROUTES.HOME : ROUTES.LOGIN} />} />
                    <Route path={ROUTES.FREEMIUM} element={<Freemium/>} />
                    <Route path={ROUTES.LOGIN} element={!user.user_id ? (<LogInPage />) : (<Navigate to={ROUTES.HOME}/>)} />
                    <Route path={ROUTES.HOME} element={<ProtectedRoute user={user}><HomePage/></ProtectedRoute>} />
                    <Route path={ROUTES.ACCOUNT} element={<ProtectedRoute user={user}><AccountPage/></ProtectedRoute>} />
                    <Route path={ROUTES.SNAPSHOT} element={<ProtectedRoute user={user}><Snapshot/></ProtectedRoute>} />
                    <Route path={ROUTES.EARNINGS} element={<ProtectedRoute user={user}><Earnings/></ProtectedRoute>} />
                    <Route path={ROUTES.STOCKALERTS} element={<ProtectedRoute user={user}><StockAlerts/></ProtectedRoute>} />
                    <Route path={ROUTES.FREEMIUM} element={<Freemium/>} />
                  </Routes>
                </Router>
              </ThemeProvider>
            </StyledEngineProvider>
          );
        }}
    </AuthUserContext.Consumer>
  );

};

const ProtectedRoute = ({ user, children }) => {
  if (!user.user_id) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withAuthentication(App);

export {withRouter}