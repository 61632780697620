import { TrendingDownRounded, TrendingUpRounded, AssessmentRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import * as moment from 'moment';

const redColor = '#EB4D5C'
const greenColor = '#3fb54d'

const Time = (props) => {
    return (<div>{moment(props.value).format('h:mm a')}</div>)
};

const Open = (props) => {
    var text = "-"
    if(props.value === "algBuy") text = "Buy";
    if(props.value === "algSell") text = "Sell";
    return (<div style={{color: text === "Buy" ? greenColor : redColor}}>{text}</div>);
};

const Price = (props) => {
    return (<div>{parseFloat(props.value).toFixed(2)}</div>)
};

const Ticker = (props) => {
    return (<div>{props.value}</div>)
};

const High = (props) => {
    return (<div>{parseFloat(props.value).toFixed(2)}</div>)
};

const Low = (props) => {
    return (<div>{parseFloat(props.value).toFixed(2)}</div>)
};

const ProfitLoss = (props) => {
    var text = '-';
    if(!props.row.original.EOD || props.row.original.EOD === undefined) return (<div>{text}</div>);
    if(props.row.original.type === 'algBuy') return(<div>{(props.row.original.EOD - props.row.original.cost).toFixed(2)}</div>);
    if(props.row.original.type === 'algSell') return(<div>{(props.row.original.cost - props.row.original.EOD).toFixed(2)}</div>);
    return (<div>{parseFloat(props.row.original.cost - props.row.original.EOD).toFixed(2)}</div>)
};

const Direction = (props) => {
    var text = "-"
    if(props.value) text = parseFloat(props.value).toFixed(2);
    return (<div>{text}</div>);
};

const ChartColumn = (handleOpen, setTicker, props) => {
    const open = () => {
        setTicker(props.row.original.ticker);
        handleOpen(true);
    }
    return (
      <div>
        <Grid container direction="row" justifyContent="start" alignItems="center" style={{ paddingLeft: '2px', textAlign: 'start' }}>
         <Grid item ><AssessmentRounded style={{ color: 'white', }} onClick={open}/></Grid>
        </Grid>
      </div>
    )
  }

export { Time, Open, Price, Ticker, High, Low, ProfitLoss, ChartColumn, Direction }