import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from "@mui/material/InputAdornment";
import { Container, Backdrop, CircularProgress } from '@mui/material';
import Avatar from "@mui/material/Avatar"
import Icon from "@mui/material/Icon";
// @mui/icons-material
import Email from "@mui/icons-material/Email";
import People from "@mui/icons-material/People";
// core components
import Header from "../material-ui/Header/Header.js";
import HeaderLinks from "../material-ui/Header/HeaderLinks.js";
import Footer from "../material-ui/Footer/Footer.js";
import GridContainer from "../material-ui/Grid/GridContainer.js";
import GridItem from "../material-ui/Grid/GridItem.js";
import Button from "../material-ui/CustomButtons/Button.js";
import Card from "../material-ui/Card/Card.js";
import CardBody from "../material-ui/Card/CardBody.js";
import CardHeader from "../material-ui/Card/CardHeader.js";
import CardFooter from "../material-ui/Card/CardFooter.js";
import CustomInput from "../material-ui/CustomInput/CustomInput.js";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
import typeStyles from "../../assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import tableIcons from '../Home/tableicons';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withRouter } from '../App';
import { compose } from 'recompose';

import brand from "../../assets/img/faces/avatar.jpg";
import image from "../../assets/img/loginbackground.jpg";
import background from "../../assets/img/background.jpg"
import { LockOutlined, LockOpen } from "@mui/icons-material";
import PasswordForgetPage, { PasswordForgetForm } from "../PasswordForget/index.js";
import { useNavigate } from "react-router-dom";

const localUseStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const useStyles = makeStyles(styles, typeStyles);

function LoginPageBase(props) {

  const navigate = useNavigate();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const localClasses = localUseStyles();
  const { ...rest } = props;
  const [error, setError] = useState(0);
  const [email, setEmail] = useState(1);
  const [password, setPassword] = useState(2);
  const [open, setOpen] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  
  const toggleView = () => {
    setShowLogin(!showLogin);
  };

  const onReset = event => {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: 'https://sweepcast.app/login',
      handleCodeInApp: false
    };
    rest.firebase
      .doPasswordReset(email, actionCodeSettings)
      .then(() => {
        toggleView();
        setError('Password Reset Sent')
      })
      .catch(error => {
        setError(error);
       //console.log(error);
       handleClose();
      });
 
    event.preventDefault();
  };

  const onSubmit = event => {
    handleToggle();
    rest.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((response) => {
        handleClose();
      })
      .catch(error => {
       setError(error);
       //console.log(error);
       handleClose();
      });
 
    event.preventDefault();
  };

  const isInvalid = email === '';
  
  return ( 
    <div style={{width: '100vw'}}>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem style={{marginLeft:'auto', marginRight:'auto'}} xs={8} sm={8} md={4}>
              <Card className={classes[cardAnimaton]}>
              {showLogin ? (<form className={classes.form}>
                <CardHeader className={classes.cardHeader}>
                    Sign into your account
                    </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => 
                        {
                          setEmail(e.target.value);
                          setError(null);
                        },
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => 
                        {
                          setPassword(e.target.value);
                          setError(null);
                        },
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  {error != null && (
                      <div style={{display:'flex', flex: 1, color: 'red', justifyContent:'center', alignContent: 'center', padding:'0px 10px 10px 10px', textAlign: 'center', fontWeight:'bold'}}>{error.message}</div>
                  )}
                  <CardFooter className={classes.cardFooter}>
                    <Button variant="contained"
                            color="#3f79b5"
                            className={classes.button} 
                            size="lg" 
                            onClick={onSubmit}
                            endIcon={<LockOpen/>}>
                      Login
                    </Button>
                  </CardFooter>
                 <CardFooter className={classes.cardFooter}>
                      <Button variant="outlined"
                                color="#3f79b5"
                                className={classes.button} 
                                size="lg" 
                                onClick={toggleView}>
                          Reset Password
                      </Button>
                    </CardFooter>
                </form>
                ):
                (
                  <form>
                  <CardHeader className={classes.cardHeader}>
                    Send Password Reset
                  </CardHeader>
                  <CardBody>
                  <CustomInput
                      labelText="Email"
                      id="forgotEmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      className={classes.button} 
                      inputProps={{
                        onChange: (e) => 
                        {
                          setEmail(e.target.value);
                          setError(null);
                        },
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  {error != null && (
                    <div style={{display:'flex', flex: 1, color: 'red', justifyContent:'center', alignContent: 'center', padding:'0px 10px 10px 10px', textAlign: 'center', fontWeight:'bold'}}>{error.message}</div>
                  )}
                    <CardFooter className={classes.cardFooter}>

                      <Button variant="outlined"
                              color="#3f79b5"
                              size="lg" 
                              onClick={onReset}>
                        Reset Password
                      </Button>
                    </CardFooter>
                    <CardFooter className={classes.cardFooter}>
                      <Button variant="outlined"
                                color="#3f79b5"
                                className={classes.button} 
                                size="lg" 
                                onClick={toggleView}>
                          Return To Sign In
                      </Button>
                    </CardFooter>

                </form>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Backdrop className={localClasses.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
  );
}
const LoginPage = compose(
    withRouter,
    withFirebase,
  )(LoginPageBase);
  
  export default LoginPage;
  