import React, { Component, useState } from 'react';
import { withRouter } from '../App';
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthentication, withAuthorization } from '../Session';
import * as momenttz from 'moment-timezone'
import * as ROUTES from '../../constants/routes';
import * as moment from 'moment-business-days';
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Badge,
    Progress,
    Table,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";
  import { Dashboard, AccountCircle, AccountBox, LiveHelp, Assessment, ExitToApp, ArrowDropDown, ArrowDropUp, AutoGraph } from '@mui/icons-material';
import {UnmountClosed} from 'react-collapse';
import { Grid, Switch, CircularProgress } from '@mui/material';
import { BreadcrumbItem, Accordion } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import CalendarIcon from '@mui/icons-material/CalendarToday'
import logo from '../../assets/img/logo.png';
import SideNav from '../Navbars/SideNav';
import PrimarySearchAppBar, { SecondarySearchAppBar } from '../Home/menu';
import { useTable, useBlockLayout} from 'react-table';
import { FixedSizeList } from 'react-window';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { firstBreakpoint, secondBreakpoint } from '../../constants/variables';

const holidayList = [
  "07-03-2020",
  "09-07-2020",
  "12-25-2020",
  "01-01-2021",
  "01-18-2021",
  "02-15-2021",
  "04-02-2021",
  "05-31-2021",
  "07-05-2021",
  "09-06-2021",
  "12-24-2021",
  "01-17-2022",
  "04-15-2022",
  "05-30-2022",
  "07-04-2022",
  "09-05-2022",
]
moment.updateLocale('us', {
  holidays: holidayList,
  holidayFormat: 'MM-DD-YYYY',
  workingWeekdays: [1, 2, 3, 4, 5], 
});

const DateInput = ({ value, onClick }) => (
  <Grid onClick={onClick}  container direction='row' justifyContent='flex-start' justifyContent='flex-start' alignItems='center' style={{ marginBottom:'10px'}}>
      <CalendarIcon style={{width: 20, height: 20}}/> 
      <div
      style={{color: 'white', fontSize: '12px', paddingLeft:'5px'}}>
      {value}
      </div>
  </Grid>
);

const DateRangeInput = ({ value, onClick }) => {
return (
  <Grid onClick={onClick}  container direction='row' justifyContent='flex-start' justifyContent='flex-start' alignItems='center' style={{ marginBottom:'10px'}}>
      <CalendarIcon style={{width: 20, height: 20}}/> 
      <div
      style={{color: 'white', fontSize: '12px', paddingLeft:'5px'}}>
      {value}
      </div>
  </Grid>
);};

const redColor = '#EB4D5C'
const redGradient = `linear-gradient(to right, #ff781f, ${redColor})`;
const greenColor = '#3fb54d'
const greenGradient = `linear-gradient(to right, ${greenColor}, #019CAD)`;


class Snapshot extends Component {
    constructor(props) {
      super(props);
      this.updateDate = this.updateDate.bind(this);
      this.isWeekday = this.isWeekday.bind(this);
      this.state = {
        bulls: [],
        bears:[],
        tempBulls: [],
        tempBears:[],
        topBull: '',
        topBear: '',
        tempTopBull: '',
        tempTopBear: '',
        date: '',
        startDate: '',
        endDate: '',
        loading: true,
        showYesterday: true,
        showThisWeek: true,
        todayLabel: 'Today',
        selectedSort: 'today',
        useEtf: false,
        sweeps: false,
        useRange: false,
        user: null,
        mobile: false
      };
    }

    updateDimensions = () => {
      this.setState({'mobile': window.innerWidth < firstBreakpoint});
    };

    processData(date, snapshot){
      try
      {
        const optionsObject = snapshot.val();
        const optionsList = Object.keys(optionsObject).map(key => ({
            ...optionsObject[key],
        }));

        var bullsTemp = this.state.useEtf ? optionsList.filter((x)=> x.sentiment == "BULLISH") : optionsList.filter((x)=> x.sentiment == "BULLISH" && x.underlying_type == "STOCK");
        var bearsTemp = this.state.useEtf ? optionsList.filter((x)=> x.sentiment == "BEARISH") : optionsList.filter((x)=> x.sentiment == "BEARISH" && x.underlying_type == "STOCK");

        const bulls = this.state.sweeps ? bullsTemp.filter((x)=> x.option_activity_type == "SWEEP") : bullsTemp;
        const bears = this.state.sweeps ? bearsTemp.filter((x)=> x.option_activity_type == "SWEEP") : bearsTemp;

        var bullsDict = [];
        var bearsDict = [];

        bulls.forEach((item, index) =>{
            var value = bullsDict[item.ticker];
            if(value === null || value === undefined || value == 0)
                bullsDict[item.ticker] = [item];
            else
                bullsDict[item.ticker].push(item);
            // do something with "key" and "value" variables
        });
        bears.forEach((item, index) =>{
            var value = bearsDict[item.ticker];
            if(value === null || value === undefined || value == 0)
                bearsDict[item.ticker] = [item];
            else
                bearsDict[item.ticker].push(item);
        });

        bearsDict = Object.keys(bearsDict).sort(function keyOrder(k1, k2) {
            var sumBearsDict1 = bearsDict[k1].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
              var sumBearsDict2 = bearsDict[k2].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
            return (sumBearsDict2 - sumBearsDict1);
        }).reduce(function (result, key, i) {
            if(i > 20) return result;
            result[key] = bearsDict[key];
            return result;
        }, {});;
        bullsDict = Object.keys(bullsDict).sort(function keyOrder(k1, k2) {
            var sumBullsDict1 = bullsDict[k1].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
              var sumBullsDict2 = bullsDict[k2].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
            return (sumBullsDict2 - sumBullsDict1);
        }).reduce(function (result, key, i) {
            if(i > 20) return result;
            result[key] = bullsDict[key];
            return result;
        }, {});;

        const topBull = Object.keys(bullsDict).slice(0,1);
        const topBear = Object.keys(bearsDict).slice(0,1);
        this.setState({ bulls: bullsDict, bears: bearsDict, topBull, topBear, date, loading: false, startDate: date, endDate: ''});
        
        }catch(ex){
            //console.log(ex);
        }
    }

    processDataRange(date, snapshot){
      try
      {
        const optionsObject = snapshot.val();
        const optionsList = Object.keys(optionsObject).map(key => ({
            ...optionsObject[key],
        }));
        var bullsTemp = this.state.useEtf ? optionsList.filter((x)=> x.sentiment == "BULLISH") : optionsList.filter((x)=> x.sentiment == "BULLISH" && x.underlying_type == "STOCK");
        var bearsTemp = this.state.useEtf ? optionsList.filter((x)=> x.sentiment == "BEARISH") : optionsList.filter((x)=> x.sentiment == "BEARISH" && x.underlying_type == "STOCK");

        const bulls = this.state.sweeps ? bullsTemp.filter((x)=> x.option_activity_type == "SWEEP") : bullsTemp;
        const bears = this.state.sweeps ? bearsTemp.filter((x)=> x.option_activity_type == "SWEEP") : bearsTemp;
        
        var bullsDict = this.state.tempBulls;
        var bearsDict = this.state.tempBears;

        bulls.forEach((item, index) =>{
            var value = bullsDict[item.ticker];
            if(value === null || value === undefined || value == 0)
                bullsDict[item.ticker] = [item];
            else
                bullsDict[item.ticker].push(item);
            // do something with "key" and "value" variables
        });
        bears.forEach((item, index) =>{
            var value = bearsDict[item.ticker];
            if(value === null || value === undefined || value == 0)
                bearsDict[item.ticker] = [item];
            else
                bearsDict[item.ticker].push(item);
        });
        this.setState({ tempBulls: bullsDict, tempBears: bearsDict});
        if(this.state.startDate == '') this.setState({startDate: date})
        }catch(ex){
            //console.log(ex);
        }
    }

    setDateRange(){
      try
      {
        var bullsDict = this.state.tempBulls;
        var bearsDict = this.state.tempBears;

        bearsDict = Object.keys(bearsDict).sort(function keyOrder(k1, k2) {
            var sumBearsDict1 = bearsDict[k1].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
              var sumBearsDict2 = bearsDict[k2].reduce(function(previousValue, currentValue) {
                return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
              }, 0);
            return (sumBearsDict2 - sumBearsDict1);
        }).reduce(function (result, key, i) {
            if(i > 20) return result;
            result[key] = bearsDict[key];
            return result;
        }, {});;
        bullsDict = Object.keys(bullsDict).sort(function keyOrder(k1, k2) {
          var sumBullsDict1 = bullsDict[k1].reduce(function(previousValue, currentValue) {
              return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
            }, 0);
            var sumBullsDict2 = bullsDict[k2].reduce(function(previousValue, currentValue) {
              return parseFloat(previousValue) + parseFloat(Number(currentValue.cost_basis));
            }, 0);
          return (sumBullsDict2 - sumBullsDict1);
        }).reduce(function (result, key, i) {
            if(i > 20) return result;
            result[key] = bullsDict[key];
            return result;
        }, {});;

        const topBull = Object.keys(bullsDict).slice(0,1);
        const topBear = Object.keys(bearsDict).slice(0,1);
        this.setState({ bulls: bullsDict, bears: bearsDict, topBull, topBear, tempBulls: [], tempBears: [], loading: false});
        }catch(ex){
            //console.log(ex);
        }
    }

    getData(date, day){
        setTimeout(function() {
          this.props.firebase.activity(date).once('value', snapshot => {
              this.processData(date, snapshot);
          });
        }.bind(this), 10);
    }

    async changeDateRange(){
      this.setState({bulls: [], bears: [], loading: true});
      var date = moment(this.state.startDate);
      while(date <= moment(this.state.endDate)){
        var tempdate = moment(date);
        const snapshot = await this.props.firebase.activity(tempdate).once('value');
        this.processDataRange(tempdate, snapshot);
        date = date.businessAdd(1, 'day');
      }
      this.setDateRange();
    }

    isWeekday = (date) => {
      var d = moment(date, 'MM-DD-YYYY');
      return d.isBusinessDay() && !d.isHoliday() && moment().diff(date) >= 0;
    };

    updateDate = (updateDate) =>{
      this.getData(moment(updateDate), '')
    };

    updateDateRange = (dates) => {
      const [start, end] = dates;
      this.setState({startDate: start, endDate: end, tempBulls: [], tempBears: []}, ()=>{
        if(start != null && start != '' && start != undefined && end != null && end != '' && end != undefined)
          this.changeDateRange();
      });
    };
    
    componentDidMount(){
        var date = momenttz().tz('America/Los_Angeles');
        if(!moment(date).isBusinessDay() || moment(date).isHoliday()) date = moment(date).prevBusinessDay();
        this.getData(date, "today");

        const {authUser} = this.props;
        const user = {
          user_id: authUser ? authUser.uid : null,
          email: authUser ? authUser.email : null,
          name: authUser ? authUser?.name : null,
          avatar: authUser ? authUser?.photoURL : null
        };
      if(user.user_id){
        this.setState({user: user});
      };
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }


    render(){
        const {selectedSort, bulls, bears, topBull, topBear, bullsYesterday, bearsYesterday, topBullYesterday, topBearYesterday, useEtf, sweeps, useRange, date, startDate, endDate, loading, mobile} = this.state;
        const handleMenuLogOut = () => {
            this.props.firebase.doSignOut();
            this.props.history.push(ROUTES.LOGIN);
            
          };

          const setUseEtf = () => {
            this.setState({useEtf: !useEtf});
            if(useRange) this.changeDateRange();
            else this.getData(date, selectedSort);
          };

          const setUseSweeps = () => {
            this.setState({sweeps: !sweeps});
            if(useRange) this.changeDateRange();
            else this.getData(date, selectedSort);
          };

          const setUseRange = () => {
            if(useRange) this.updateDate(date);
            this.setState({useRange: !useRange});
          };

          const onChange = (dates) => {
            const [start, end] = dates;
            this.setState({startDate: start, endDate: end});
          };
        return (
          <div style={{ margin:0, height:'100vh', width:'100%', minHeight:'100vh', minWidth:'100vw', overflowX: 'hidden', backgroundColor: "#141B2B"}}>
             { mobile ? (<Navbar className='nav-block'  bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/home">
                  <div>
                    <img src={logo} alt="Logo" height={40}/>
                  </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="mr-auto">
                          <Nav.Link href="/home" style={{minWidth: '80px'}}>
                              <div>
                                  <Dashboard className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                  <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Dashboard</div>
                              </div>
                          </Nav.Link>
                          <Nav.Link href="/flowcast" style={{minWidth: '80px'}}>
                              <div>
                                  <Assessment className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                  <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>FlowCast</div>
                              </div>
                          </Nav.Link>
                          <Nav.Link href="/earnings" style={{ minWidth: '80px' }}>
                            <div>
                              <Assessment className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                              <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Earnings</div>
                            </div>
                          </Nav.Link>
                          <Nav.Link href="/stockalerts" style={{ minWidth: '80px' }}>
                            <div>
                              <AutoGraph className="navbarIcon" style={{ width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                              <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>Stock Alerts</div>
                            </div>
                          </Nav.Link>
                          <Nav.Link href="/account" style={{minWidth: '80px'}}>
                              <div>
                                <AccountBox className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>My Account</div>
                              </div>
                          </Nav.Link>
                          <Nav.Link href="#" onClick={()=> window.$crisp.push(["do", "helpdesk:search"])} target="_blank" style={{minWidth: '80px'}}>
                              <div>
                                  <LiveHelp className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                  <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Help</div>
                              </div>
                          </Nav.Link>
                          <Nav.Link style={{minWidth: '80px'}}>
                            <div onClick={handleMenuLogOut}>
                                  <ExitToApp className="navbarIcon" style={{width: '20px', height: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                                  <div style={{fontSize: 13, fontWeight:'bold', textAlign: 'center'}}>Log Out</div>
                              </div>
                          </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                </Navbar>) :
                (<SideNav page={"flowcast"} />)
              }
              <Grid className="below-nav-block" container spacing={1} direction="row" justifyContent="center">
                <Grid item style={{width:"90vw"}}>
                <h3 className="flowcastHeader">FlowCast</h3>
                {/* <hr className="flowcastHeaderLine"></hr> */}
                    <Card style={{backgroundColor:"#1A2344", marginTop: '20px', marginBottom: '0px', borderRadius: "0px"}} >
                        <CardHeader style={{padding: "10px"}}>
                            <Grid container direction="row" alignItems="flex-start" style={{display:"table"}}>
                              {this.state.date && (
                                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" style={{fontSize: 20, color:'white', fontWeight: 'bold', width:'fit-content', display:'inline-flex'}}>
                                    {this.state.date && this.state.date != undefined && this.state.date != null && this.state.date != '' && !useRange && (
                                    <DatePicker 
                                      disabledKeyboardNavigation
                                      style={{justifyContent: 'start', alignItems: 'start', flex: 0, width:'20%', zIndex: 10000, marginTop: "auto"}}
                                      selected={new Date(this.state.date)}
                                      customInput={<DateInput />}
                                      onChange={this.updateDate} 
                                      filterDate={this.isWeekday}
                                      minDate={new Date(2020, 6, 7, 16, 0, 0, 0)} 
                                      maxDate={moment().prevBusinessDay()}
                                      popperClassName='some-custom-class'
                                      popperPlacement='right-end'
                                      disabledKeyboardNavigation
                                      popperModifiers={{
                                        offset: {
                                          enabled: true,
                                          offset: '5px, 10px'
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          escapeWithReference: false,
                                          boundariesElement: 'viewport'
                                        }
                                      }}/> 
                                  )}
                                  {this.state.startDate && this.state.startDate != undefined && this.state.startDate != null && this.state.startDate != '' && useRange && (
                                    <DatePicker 
                                      disabledKeyboardNavigation
                                      style={{justifyContent: 'start', alignItems: 'start', flex: 0, width:'20%', zIndex: 10000}}
                                      selected={new Date(startDate)} 
                                      startDate={new Date(startDate)}
                                      endDate={endDate}
                                      customInput={<DateRangeInput />}
                                      onChange={this.updateDateRange} 
                                      filterDate={this.isWeekday}
                                      selectsRange={true}
                                      shouldCloseOnSelect={true}
                                      minDate={new Date(2020, 6, 7, 16, 0, 0, 0)} 
                                      maxDate={moment().prevBusinessDay()}
                                      popperClassName='some-custom-class'
                                      popperPlacement='right-end'
                                      disabledKeyboardNavigation
                                      popperModifiers={{
                                        offset: {
                                          enabled: true,
                                          offset: '5px, 10px'
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          escapeWithReference: false,
                                          boundariesElement: 'viewport'
                                        }
                                      }}/> 
                                  )}
                                  <Grid item style={{color:'white', fontSize: '14px', fontWeight: 'normal'}} alignItems="center" justifyContent="flex-start">
                                    Select Range
                                    <Switch
                                      color='primary'
                                      checked={useRange}
                                      onChange={setUseRange}
                                      name="checkedA"
                                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                  </Grid>
                                </Grid>
                              )}

                              <Grid className="sweepFlowcast" item  style={{color:'white', float:'right', display:"inline-flex", paddingLeft:'20px', verticalAlign: 'top' }} alignItems="center" justifyContent="flex-start">
                                  Sweeps
                                  <Switch
                                    color='primary'
                                    checked={sweeps}
                                    onChange={setUseSweeps}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                              </Grid>
                            
                              <Grid className="etfFlowcast" item  style={{color:'white', float:'right', textAlign:"end", display:"inline-flex" }} alignItems="center" justifyContent="flex-end">
                                  ETFs
                                  <Switch
                                    color='primary'
                                    checked={useEtf}
                                    onChange={setUseEtf}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                              </Grid>
                              
                            </Grid>
                        </CardHeader>
                    </Card>
                </Grid>
                {loading ? (<CircularProgress color="secondary" style={{position:'absolute', left: '50%', top: '50%'}} />) :
                  (<Grid item xs={11} sm={11} md={5} style={{paddingTop: "0px", paddingLeft: "0px"}}>
                        <Card style={{borderRadius: "0px"}}>
                            <CardHeader style={{backgroundColor:"#24305D", marginBottom: '5px', padding:"15px"}}>
                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid item style={{fontSize: 20, color:'white', fontWeight: 'bold'}}>
                                        Bullish Flow
                                    </Grid>
                                </Grid>
                            </CardHeader>
                            <CardBody style={{backgroundColor: "#1F283D"}}>
                                
                                { 
                                  selectedSort === "today" ? (<BullsList bulls={bulls} topBull={topBull} />)
                                    : (<BullsList bulls={bullsYesterday} topBull={topBullYesterday} />)
                                }
                            </CardBody>
                        </Card>
                    </Grid>)}
                {loading ? (<CircularProgress color="secondary" style={{position:'absolute', left: '50%', top: '50%'}} />) :
                  (<Grid item xs={11} sm={11} md={5} style={{paddingTop: "0px", paddingRight: "0px"}}>
                      <Card style={{borderRadius: "0px"}}>
                          <CardHeader style={{backgroundColor:"#24305D", marginBottom: '5px', padding:"15px"}}>
                              <Grid container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center">
                                  <Grid item style={{fontSize: 20, color:'white', fontWeight: 'bold'}}>
                                      Bearish Flow
                                  </Grid>
                              </Grid>
                          </CardHeader>
                          <CardBody style={{backgroundColor: "#1F283D"}}> 
                              { 
                                selectedSort === "today" ? 
                                      (<BearsList bears={bears} topBear={topBear} />)
                                  : (<BearsList bears={bearsYesterday} topBear={topBearYesterday} />)
                              }
                          </CardBody>
                      </Card>
                    </Grid>)}
              </Grid>
          </div>
        );
    }
  };

  function FormatMoney(rowData){
    const premium = rowData.value;
    var si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'K' },
      { value: 1E6, symbol: 'MM' },
      { value: 1E9, symbol: 'G' },
      { value: 1E12, symbol: 'T' },
      { value: 1E15, symbol: 'P' },
      { value: 1E18, symbol: 'E' }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (premium >= si[i].value) {
        break;
      }
    }
    return `$${(premium / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol}`;
  };

  const RenderBearRow = ({bears, topBear, stock, i}) => {
    var bearValue = bears[stock].length > 1 ? bears[stock].reduce(function(previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue.cost_basis);
      }, 0) : bears[stock][0].cost_basis;
    var topBearValue = bears[topBear].length > 1? bears[topBear].reduce(function(previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue.cost_basis);
      }, 0) : bears[topBear][0].cost_basis;
    const [width, setWidth] = useState('0%');
    requestAnimationFrame(() => {
      setWidth(`${( bearValue / topBearValue) * 100}%`);
    });
    const [open, setOpen] = useState(false);

    const columns = React.useMemo(
        () => [
          {
            Header: 'Type',
            accessor: 'option_activity_type',
          },
          {
            Header: 'Call/Put',
            accessor: `put_call`,
          },
          {
            Header: 'Strike',
            accessor: 'strike_price',
            Cell: rowData => FormatMoney(rowData)
          },
          {
            Header: 'Premium',
            accessor: 'cost_basis',
            Cell: rowData => FormatMoney(rowData)
          },
          {
            Header: 'Expiration',
            accessor: 'date_expiration'
          },
          {
            Header: 'Details',
            accessor: (rowData) => `${rowData.size} @ ${parseFloat(rowData.price).toFixed(2)}`
          }
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable(
        {
          columns,
          data: bears[stock],
          autoResetPage: false,
          autoResetSortBy: false,
        },
        useBlockLayout,
      )
    
      const RenderRow = React.useCallback(
        ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
              {...row.getRowProps({
                style
              })}
              className="tr"
            >
              {row.cells.map((cell) => {
                  const cellStyle = cell.getCellProps().style;
                  if(cell.row.original.cost_basis >= 1000000){
                    return (
                      <div {...cell.getCellProps()} style={{...cellStyle, color:'#deb723'}}  className="td text-left">
                        {cell.render("Cell")}
                      </div>
                    );
                    }
                  else
                    return(
                      <div {...cell.getCellProps()} style={{...cellStyle, color:'silver'}}  className="td text-left">
                      {cell.render("Cell")}
                    </div>
                  )
                })
              }
            </div>
          );
        },
        [prepareRow, rows]
      );
    
    return (
      <Grid key={`bear_${i}`} item style={{width:'100%'}}>
          <Accordion style={{width: '100%'}}>
              <Accordion.Header className="accordionToggle" onClick={() => setOpen(!open)} id={`bearacc_${i}`} eventKey={`bearacc_${i}`} style={{width:'100%', height:'28px', background:`linear-gradient(90deg, #F87240 ${width}, #46526e 0%`, position:'relative', borderRadius: '5px', marginBottom: '0px'}}>
                  <div style={{color: 'white', position:'absolute', top:4, left: 0, width:'100%', height:'28px', fontSize: '14px', fontWeight:'bold'}}>
                      {stock} - ${parseFloat(bearValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                  </div>
                  <div style={{color: "white"}}>{open ? (<ArrowDropUp style={{float: 'right'}} />) : (<ArrowDropDown style={{float: 'right'}} />)}</div>
                  <UncontrolledTooltip target={`bearacc_${i}`} delay={0} position="top" >
                      Click to show/hide details
                  </UncontrolledTooltip>
              </Accordion.Header>
              <Accordion.Body eventKey={`bearacc_${i}`} >
                  <div className="flowCastOuter"  style={{paddingTop: '10px', paddingBottom: '-10px'}}>
                      <div  {...getTableProps()} className="table flowCastInner">
                          <div >
                              {headerGroups.map((headerGroup) => {
                                  return(
                                  <div style={{display:'flex', color:'white', margin:0, padding:0}} className="tr text-left">
                                  {headerGroup.headers.map((column) => (
                                      <div {...column.getHeaderProps()} className={`th text-left`}>
                                          <div style={{float: 'left'}}>{column.render("Header")}</div>
                                      </div>
                                  ))}
                                  </div>
                              )})}
                              <hr style={{backgroundColor:'#272A2d'}} />
                          </div>

                          <div {...getTableBodyProps()}>
                              <FixedSizeList
                                  height={rows.length < 20 ? rows.length * 20 : 400}
                                  itemCount={rows.length}
                                  itemSize={20}
                                  overscanCount={25}
                                  birdirectionalOverscan={true} >
                                  {RenderRow}
                              </FixedSizeList>
                          </div>
                      </div>
                  </div>
              </Accordion.Body>
          </Accordion>
      </Grid>
    );
  };


  const RenderBullRow = ({bulls, topBull, stock, i}) => {

    var bullValue =  bulls[stock].length > 1 ? bulls[stock].reduce(function(previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue.cost_basis);
      }, 0) : bulls[stock][0].cost_basis;
    var topBullValue = bulls[topBull].length > 1 ? bulls[topBull].reduce(function(previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue.cost_basis);
      }, 0) : bulls[topBull][0].cost_basis;
    const [width, setWidth] = useState('0%');
    requestAnimationFrame(() => {
      setWidth(`${( bullValue / topBullValue) * 100}%`);
    });
    const [open, setOpen] = useState(false);
    
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'option_activity_type',
      },
      {
        Header: 'Call/Put',
        accessor: `put_call`,
      },
      {
        Header: 'Strike',
        accessor: 'strike_price',
        Cell: rowData => FormatMoney(rowData)
      },
      {
        Header: 'Premium',
        accessor: 'cost_basis',
        Cell: rowData => FormatMoney(rowData)
      },
      {
        Header: 'Expiration',
        accessor: 'date_expiration'
      },
      {
        Header: 'Details',
        accessor: (rowData) => `${rowData.size} @ ${parseFloat(rowData.price).toFixed(2)}`
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: bulls[stock],
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useBlockLayout,
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style
          })}
          className="tr"
        >
          {row.cells.map((cell) => {
              const cellStyle = cell.getCellProps().style;
              if(cell.row.original.cost_basis >= 1000000){
                return (
                  <div {...cell.getCellProps()} style={{...cellStyle, color:'#deb723'}}  className="td text-left">
                    {cell.render("Cell")}
                  </div>
                );
                }
              else
                return(
                  <div {...cell.getCellProps()} style={{...cellStyle, color:'silver'}}  className="td text-left">
                  {cell.render("Cell")}
                </div>
              )
            })
          }
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <Grid key={`bull_${i}`} item style={{width:'100%'}} >
        <Accordion>
            <Accordion.Header className="accordionToggle"  onClick={() => setOpen(!open)} id={`bullacc_${i}`} as={Card.Header} eventKey={`bullacc_${i}`} style={{width:'100%', height:'28px',background:`linear-gradient(90deg, #0CD17D ${width}, #46526e 0%`, position:'relative', borderRadius: '5px', marginBottom: '0px'}}>
                <div  style={{color: 'white', position:'absolute', top:4, left: 0, width:'100%', height:'28px', fontSize: '14px', fontWeight:'bold'}}>
                    {stock} - ${parseFloat(bullValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                </div>
                <div style={{color: "white"}}>
                  {open ? (<ArrowDropUp style={{float: 'right'}} />) : (<ArrowDropDown style={{float: 'right'}} />)}
                </div>
                <UncontrolledTooltip target={`bullacc_${i}`} delay={0} position="top" >
                  Click to show/hide details
                </UncontrolledTooltip>
            </Accordion.Header>
            <Accordion.Body eventKey={`bullacc_${i}`} >
            <div className='flowCastOuter' style={{paddingTop: '10px', paddingBottom: '-10px'}}>
                <div  {...getTableProps()} className="table flowCastInner" >
                    <div>
                        {headerGroups.map((headerGroup) => {
                            const styles = headerGroup.getHeaderGroupProps().style;
                            return(
                            <div style={{display:'flex', color:'white',}} className="tr text-left">
                            {headerGroup.headers.map((column) => (
                                <div {...column.getHeaderProps()} className={`th text-left`}>
                                    <div style={{float: 'left'}}>{column.render("Header")}</div>
                                </div>
                            ))}
                            </div>
                        )})}
                        <hr style={{backgroundColor:'#272A2d'}} />
                    </div>

                    <div {...getTableBodyProps()} style={{
                        border: "0"
                        }}>
                        <FixedSizeList
                            height={rows.length < 20 ? rows.length * 20 : 400}
                            itemCount={rows.length}
                            itemSize={20}
                            overscanCount={25}
                            birdirectionalOverscan={true} >
                            {RenderRow}
                        </FixedSizeList>
                    </div>
                </div>
            </div>
        </Accordion.Body>
        </Accordion>
    </Grid>
  );
  }

  function BearsList ({bears, topBear}) {
    return (
      <Grid container
      spacing={1}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      >
          {
          bears !== [] ? 
          Object.keys(bears).map(function(key, i) { 
             return (<RenderBearRow bears={bears} topBear={topBear} stock={key} i={i} />)
          }) : "oops"
          }
      </Grid>
    );
  };

  function BullsList ({bulls, topBull}) {
    return (
      <Grid container
      spacing={1}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      >
          {
          bulls !== [] ? 
          Object.keys(bulls).map(function(key, i) { 
              return (<RenderBullRow bulls={bulls} topBull={topBull} stock={key} i={i} />)
              }) : "oops"
          }
  </Grid>
    );
  };

const condition = authUser => !!authUser;
export default withAuthorization(condition)(compose(withRouter, withFirebase)(
    Snapshot));