//#region Imports
import React, { Component, useRef, useEffect, useState, Fragment, memo } from 'react';
import useStateRef from 'react-usestateref'
import { AddBox, ArrowDownward, ImageSearchRounded, SearchRounded, CancelRounded, CallMade, VolumeUp, VolumeOff, LiveHelp, StarOutlined, Dashboard, Assessment, AccountBox, ExitToApp, AssessmentRounded, WbIridescentTwoTone, Remove, ArrowDownwardSharp, ArrowUpward, PlusOneRounded, ExpandMore, ExpandLess } from '@mui/icons-material';
import * as moment from 'moment-business-days';
import * as momenttz from 'moment-timezone'
import { useTable, useBlockLayout, useSortBy, useFilters, useExpanded } from 'react-table'
import { FixedSizeList, VariableSizeList } from 'react-window'
import classNames from "classnames";
import { useExportData } from 'react-table-plugins'
import Papa from "papaparse";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { BreadcrumbItem } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Sidebar from "react-sidebar";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ExportIcon from '../../assets/svg/CallMade.svg';
import NewsIcon from '../../assets/svg/newspaper.svg';
import ChatTabIcon from '../../assets/svg/messenger.svg';
import DoubleUpArrow from '../../assets/svg/DoubleUpArrow.svg';
import DoubleDownArrow from '../../assets/svg/DoubleDownArrow.svg';
import ArrowUpwardRounded from '../../assets/svg/HeaderArrowUp.png';
import ArrowDownwardRounded from '../../assets/svg/HeaderArrowDown.png';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Badge,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Avatar, CardContent, Grid, LinearProgress, Modal } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container, Backdrop, CircularProgress, Hidden } from '@mui/material';
import { withAuthorization } from '../Session';
import tableIcons from '../Home/tableicons';
import { TablePagination, TextField, InputAdornment, Tooltip } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import GaugeChart from 'react-gauge-chart'
import DatePicker from 'react-datepicker';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import bull from '../../assets/img/bull.png';
import bear from '../../assets/img/bear.png';
import sweep from '../../assets/img/sweep.png';
import trade from '../../assets/img/trade.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarIcon from '@mui/icons-material/CalendarToday'
import neutral from '../../assets/img/neutral.png';
import logo from '../../assets/img/logo.png';
import tableBranding from '../../assets/img/logoText.png';
import { createTheme } from '@mui/material/styles';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import * as ROUTES from '../../constants/routes';
import { withRouter } from '../App';
import { withFirebase } from '../Firebase'
import { compose } from 'recompose';
import ReactTooltip from 'react-tooltip';
import _, { debounce } from 'lodash';
import { withAuthentication } from '../Session';
import alert from '../../assets/audio/alert.mp3'
import { CheckTreePicker } from 'rsuite';
import SideNav from '../Navbars/SideNav';

function FormatTicker(data) {

    return (
      <div className="tickerColumn" style={{ margin: 'auto', justifyContent: "space-between", paddingRight: '10px', textAlign: 'center' }}>
        {data}
      </div>
    )
  }

function DateRow(dateTime, useRange) {
  return (
    <div style={{ justifyContent: 'start' }}>
      {useRange ? moment.unix(dateTime).tz("America/New_York").format(' M/D h:mm a') : moment.unix(dateTime).tz("America/New_York").format('h:mm a')}
    </div>
  )
}

function FormatInfo(rowData) {
  const formatted = `${rowData.size} @ ${parseFloat(rowData.price).toFixed(2)}`;
  return (<div>{formatted}</div>)
}

function FormatCallPut(rowData) {
  const id = `putcall_${rowData.row.original.id}`;
  var data = rowData.row.original.description_extended;
  if (!data.includes("Ref")) {
    data = data.concat(`; ${rowData.row.original.size} @ $${(parseFloat(rowData.row.original.cost_basis / rowData.row.original.size) / 100).toFixed(2)} vs ${rowData.row.original.open_interest} OI`);
  }
  const callPut = rowData.row.original.put_call;

  return (
    <div>
      <div id={id} className={callPut === 'CALL' ? 'callBadge' : 'putBadge'} style={{ color: '#FFFFFF', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 60, display: 'inline-block' }}>{callPut}</div>
      <UncontrolledTooltip placement="top" target={id} delay={0}>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{data}</div>
      </UncontrolledTooltip>
    </div>
  )
}

function FormatType(data) {
  const id = `type_${data.row.original.id}`;
  if (data.row.original.option_activity_type == 'SWEEP') {
    return (
      <div>
        <Badge id={id} style={{ color: '#FFFFFF', backgroundColor: '#28ABDD', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 68 }}>Sweep</Badge>
        <UncontrolledTooltip placement="top" target={id} delay={0} style={{ width: 300 }}>
          <div >
            <div>A sweep order is a type of market order that fills by taking all liquidity at the best price until the entire order is filled. This signals an aggressive buyer or seller.</div>
          </div>
        </UncontrolledTooltip>
      </div>);
  }
  if (data.row.original.option_activity_type == 'TRADE') {
    return (
      <div>
        <Badge id={id} style={{ color: '#FFFFFF', backgroundColor: '#685AD2', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 68 }}>Trade</Badge>
        <UncontrolledTooltip placement="top" target={id} delay={0}>
          <div>
            <div>Trade refers to Block Trade where an entire is set to fill at a certain price point. Less aggressive buyer or seller.</div>
          </div>
        </UncontrolledTooltip>
      </div>);
  }
  return (<div>{data.row.original.option_activity_type}</div>)
}

function GetPremiumString(premium) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'MM' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (premium >= si[i].value) {
      break;
    }
  }
  return `$${(premium / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol}`;
}

function FormatPremium(premium) {
  return <div style={{ textAlign: "center" }}>{GetPremiumString(premium)}</div>;
}

function FormatAggr(rowData) {
  const value = (parseFloat(rowData.value) < 0 ? 0 : parseFloat(rowData.value) > 1 ? 1 : parseFloat(rowData.value)) * 100;
  return (
    <div style={{ paddingTop: 6, paddingLeft: 10, paddingRight: 10 }}>
      <ProgressBar now={value} className={parseFloat(rowData.value) > 1 ? "maxed-sweepscore" : "normal-sweepscore"} />
    </div>
  )
}
function FormatAggrExpand(sweepscore) {
  const value = (parseFloat(sweepscore) < 0 ? 0 : parseFloat(sweepscore) > 1 ? 1 : parseFloat(sweepscore)) * 100;
  return (
    <div style={{ paddingRight: '10px', paddingTop: '7px' }}>
      <ProgressBar now={value} className={parseFloat(sweepscore) > 1 ? "maxed-sweepscore" : "normal-sweepscore"} />
    </div>
  )
}

function FormatRef(description) {
  const str = description.match(/Ref=\$\d*.\d*/g)[0].replace("Ref=", "");
  return (
    <div style={{ color: 'white', fontSize: '12px' }}>
      {str}
    </div>
  )
}


function FormatSentiment(rowData, fromTable = true) {
  const neutral = rowData.value == 'NEUTRAL';
  const bullish = rowData.value == 'BULLISH';
  const label = neutral ? 'NEUTRAL' : rowData.value;
  var data = fromTable ? rowData.row.original.description_extended : rowData.original.description_extended;
  const size = fromTable ? rowData.row.original.size : rowData.original.size;
  const cost = fromTable ? rowData.row.original.cost_basis : rowData.original.cost_basis
  const OI = fromTable ? rowData.row.original.open_interest : rowData.original.open_interest;
  if (!data.includes("Ref")) {
    data = data.concat(`; ${size} @ $${(parseFloat(cost / size) / 100).toFixed(2)} vs ${OI} OI`);
  }

  return (
    <div style={{ display: 'flex', height: 30, flexDirection: 'row', justifyContent: 'center' }}>
      {rowData == 'null' ? 'N/A'
        :
        (
          <div>
            <div className={neutral ? 'neutralBadge' : bullish ? 'bullBadge' : 'bearBadge'} id={`sentiment_${fromTable ? rowData.row.original.id : rowData.original.id}`} boxShadow={3} style={{ color: '#FFFFFF', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 'bold', width: 80, boxShadow: '0px 0px 1px black' }}>{label}</div>
            <UncontrolledTooltip placement="right" target={`sentiment_${fromTable ? rowData.row.original.id : rowData.original.id}`} delay={0}>
              {<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{data}</div>}
            </UncontrolledTooltip>
          </div>
        )}
    </div>)
}

function FormatUOA(rowData) {
  const value = parseFloat(rowData.row.original.volume) / parseFloat(rowData.row.original.open_interest);
  const totalVolume = rowData.data.filter(x => x.ticker == rowData.row.original.ticker).reduce((a, b) => (a.volume, b.volume));
  const totalOI = rowData.data.filter(x => x.ticker == rowData.row.original.ticker).reduce((a, b) => (a.open_interest, b.open_interest));
  var str = `Volume/Open Interest: ${rowData.row.original.volume} / ${rowData.row.original.open_interest}`;
  if (parseFloat(rowData.row.original.volume) > parseFloat(rowData.row.original.open_interest)) str += "; Exceeded open interest for the day in this single trade";
  if (totalVolume > totalOI) str += "; Exceeded open interest for the day over multiple trades";
  return (
    <div>
      <div id={`UOA_${rowData.row.original.id}`} style={{ textAlign: "center" }}>
        {rowData.row.original.open_interest != 0 ? value.toFixed(2) : "No OI"}
      </div>
      <UncontrolledTooltip flip={true}  placement="top" target={`UOA_${rowData.row.original.id}`} delay={0}>
        {str}
      </UncontrolledTooltip>
    </div>
  )
}


function FormatExpiration(rowData) {
  const expiration = rowData.row.original.date_expiration;
  const splitString = expiration.split("-");
  const date = new Date(splitString[0], parseInt(splitString[1]) - 1, parseInt(splitString[2]));
  const now = moment(new Date().setHours(0, 0, 0, 0));
  const duration = moment.duration(now.diff(date));
  const diff = duration.asDays() * -1;
  return (
    <div>
      <div id={`expiration_${rowData.row.original.id}`} style={{ textAlign: "center" }}>
        {`${splitString[1]}/${splitString[2]}/${splitString[0].substr(2)}`}
      </div>
      <UncontrolledTooltip flip={true}  placement="top" target={`expiration_${rowData.row.original.id}`} delay={0}>
        {diff !== 0 ? diff > 0 ? (<span>{`Expires in ${diff.toFixed(0)} day(s)`}</span>) : <span>{`Expired ${Math.abs(diff.toFixed(0))} day(s) ago`}</span> : <span>{`Expires today`}</span>}
      </UncontrolledTooltip>
    </div>
  )
}

const DateInput = ({ value, onClick }) => (
  <Grid onClick={onClick} container direction='row' justifyContent='flex-start' justifyContent='flex-start' alignItems='center' style={{ marginBottom: '10px' }}>
    <CalendarIcon style={{ width: 20, height: 20 }} />
    <div
      style={{ color: 'white', fontSize: '12px', paddingLeft: '5px' }}>
      {value}
    </div>
  </Grid>
);


const StockTable = ({  options, date, user, firebase, early, getDate, getDateRange, isWeekday }) => {
    var tempDate = momenttz().tz('America/Los_Angeles');
    if (!moment(tempDate).isBusinessDay() || moment(date).isHoliday()) tempDate = moment().prevBusinessDay();
    const [useRange, setUseRange, rangeRef] = useStateRef(false);
    const [startDate, setStartDate] = useState(tempDate);
    const [endDate, setEndDate] = useState(null);
    const [state, setState, stateRef] = useStateRef(options);
    const [listLoading, setListLoading] = useState(false);
  
    useEffect(() => {
      setState(options);
    }, [options])
  
    const updateDate = (updateDate) => {
      setListLoading(false);
      getDate(updateDate);
    }
  
    const updateDateRange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start != null && end != null) {
        setListLoading(false);
        getDateRange(start, end);
      }
    }
  
    function isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
  
    const getDayTag = (dateExpiration) => {
      const now = moment(new Date().setHours(0, 0, 0, 0));
      const expiration = dateExpiration;
      const splitString = expiration.split("-");
      const dateExpirationConst = new Date(splitString[0], parseInt(splitString[1]) - 1, parseInt(splitString[2]));
      const duration = moment.duration(now.diff(dateExpirationConst));
      const diff = duration.asDays() * -1;
      if (diff <= 3 && diff >= 0)
        return "3 Day";
      if (diff <= 5 && diff >= 0)
        return "5 Day";
      return null;
    }
  
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
  
    const columns = React.useMemo(
      () => [
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Time (EST)</div>,
          color: 'white',
          accessor: 'updated',
          Cell: rowData => DateRow(rowData.value, useRange),
          disableSortBy: true,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Type</div>,
          accessor: 'option_activity_type',
          id: 'option_activity_type',
          Cell: rowData => FormatType(rowData),
          disableSortBy: true,
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Call/Put</div>,
          accessor: `put_call`,
          id: 'put_call',
          Cell: rowData => FormatCallPut(rowData),
          disableSortBy: true,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Sentiment</div>,
          accessor: 'sentiment',
          id: 'sentiment',
          Cell: rowData => FormatSentiment(rowData),
          disableSortBy: false,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>SweepScore</div>,
          accessor: 'aggressor_ind',
          id: 'sweepscore',
          Cell: rowData => FormatAggr(rowData),
          disableSortBy: true,
          width: 150,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }} >Vol/OI Ratio</div>,
          accessor: 'open_interest',
          id: 'OI',
          disableSortBy: false,
          Cell: rowData => FormatUOA(rowData),
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Strike</div>,
          accessor: 'strike_price',
          id: 'strike',
          disableSortBy: false,
          Cell: rowData => <div style={{ textAlign: "center" }}>{rowData.value}</div>,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Expiration</div>,
          accessor: 'date_expiration_format',
          Cell: rowData => FormatExpiration(rowData),
          disableSortBy: true,
          isVisible: true
        },
        {
          Header: () => <div className="pointerHover" style={{ textAlign: 'center' }}>Premium</div>,
          accessor: 'cost_basis',
          id: 'premium',
          Cell: rowData => FormatPremium(rowData.row.original.cost_basis),
          disableSortBy: true,
          isVisible: true
        },
      ],
      [state, useRange]
    )

  
    const {
      getTableProps,
      headerGroups,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data: options,
        autoResetPage: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        initialState: {
          sortBy: [
            {
              id: 'updated',
              desc: true
            }
          ],
          hiddenColumns: columns.filter(column => !column?.isVisible).map(column => column.id)
        }
      },
      useFilters,
      useSortBy,
      useExpanded,
      useBlockLayout,
      useExportData
    )
  
    const containsObject = (obj, list) => {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }
      return false;
    }
  
    const useDateRange = () => {
      setUseRange(!rangeRef.current);
      var tempDate = momenttz().tz('America/Los_Angeles');
      if (!moment(tempDate).isBusinessDay() || moment(date).isHoliday()) tempDate = moment().prevBusinessDay();
      if (!rangeRef.current) {
        updateDate(tempDate);
      } else {
        setStartDate(tempDate)
        setEndDate(null);
      }
    }
  
    const RenderRow = React.useCallback(
      ({ index, style }) => {
        const row = rows[index];
        prepareRow(row);
        return (
          <Fragment key={row.getRowProps().key}>
            <div style={{ ...style }}  >
              <tr  {...row.getRowProps()} className={"tr main-data-row"}>
                {row.cells.map(cell => {
                  const cellStyle = cell.getCellProps().style;
                  if (cell.row.original.cost_basis >= 1000000) {
                    return (
                      <td  {...cell.getCellProps()} style={{ ...cellStyle, color: '#deb723', fontSize: '12px' }} className={"td text-center row-td"}>
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  else {
                    return (
                      <td  {...cell.getCellProps()} style={{ ...cellStyle, color: 'white', fontSize: '12px' }} className={"td text-center row-td"}>
                        {cell.render("Cell")}
                      </td>
                    )
                  }
                })}
              </tr>
            </div>
          </Fragment>
        );
      },
      [prepareRow, rows]
    );
    return (
      <Grid className="allMiddleContent" container justifyContent="center" style={{ marginTop: 5, height: '200px' }} spacing={1}>
  
        {/* Data Grid */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className="main-card-outer">
            <CardBody style={{ minHeight: '75px' }} className={ "main-card-body"}>
              <div>
                {
                  (
                    <Fragment>
  
                      {listLoading ? (<CircularProgress color="secondary" style={{ position: 'absolute', left: '50%', top: '50%' }} />) :
                        (
                          <div>
                            <VariableSizeList
                              height={100}
                              width={'100%'}
                              itemCount={rows.length}
                              itemSize={(index) => 25}
                              overscanCount={50}
                              birdirectionalOverscan={true}
                              className={"fixed-size-list"}
                              innerElementType={({ children, style, ...rest }) => (
                                <>
                                <div className="header header-earnings">
                                  <div>
                                    {headerGroups.map((headerGroup) => {
                                      var sorted = headerGroup.headers.filter((header) => header.isSorted == true);
                                      if (sorted.length == 0) {
                                        headerGroup.headers[1].isSorted = true;
                                        headerGroup.headers[1].isSortedDesc = true;
                                      } else if (sorted.length > 1) {
                                        headerGroup.headers[1].isSorted = false;
                                        headerGroup.headers[1].isSortedDesc = undefined;
                                      }
                                      return (
                                        <table style={{display:'block'}}>
                                        <tr {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', color: 'white', overflow: 'visible', width: '100%' }} className={"tr header-container earnings-header-container"}>
                                          {headerGroup.headers.map((column, i) => {
                                            const id = `header_${i}`;
                                            const weight = column.isSorted ? "bold" : "normal"
                                            return (
                                              <td {...column.getHeaderProps()} className={`th row-td`}>
                                                <div style={{ display: 'inline-block', paddingTop: '10px' }} >
                                                  <div id={id}  style={{ float: 'left', fontWeight: weight }}>{column.render("Header")}</div>
                                                </div>
                                                {null}
                                              </td>
                                            )
                                          }
                                          )}
                                        </tr>
                                        </table>
                                      )
                                    })}
                                  </div>
                                </div>
                                  <div className="data-grid-block data-grid-block-earnings" style={{ height: "140px", width: '100%', overflow:'scroll' }}>
                                    <div {...getTableBodyProps()} {...rest} style={style} className={'data-row-group'}>
                                      {children}
                                    </div>
                                  </div>
                                </>
                              )}
                            >
                              {RenderRow}
                            </VariableSizeList>
                          </div>)}
                    </Fragment>)}
              </div>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }

export default StockTable;