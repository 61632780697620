import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import "firebase/compat/storage";
import "firebase/compat/remote-config";
import "firebase/compat/analytics";
import * as moment from 'moment-business-days';
import * as momenttz from 'moment-timezone'

const holidayList = [
    "07-03-2020",
    "09-07-2020",
    "12-25-2020",
    "01-01-2021",
    "01-18-2021",
    "02-15-2021",
    "04-02-2021",
    "05-31-2021",
    "07-05-2021",
    "09-06-2021",
    "12-24-2021",
    "01-17-2022",
    "04-15-2022",
    "05-30-2022",
    "07-04-2022",
    "09-05-2022",
    "01-02-2023",
    "01-16-2023",
    "02-20-2023",
    "04-07-2023",
    "05-29-2023",
    "06-19-2023",
    "07-04-2023",
    "09-04-2023",
    "11-23-2023",
    "12-25-2023"
  ]
  
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  
  moment.updateLocale('us', {
    holidays: holidayList,
    holidayFormat: 'MM-DD-YYYY',
    workingWeekdays: [1, 2, 3, 4, 5],
  
  });
  

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
 
class Firebase {
    constructor() {
        app.initializeApp(config);
    
        this.auth = app.auth();
        this.db = app.database();
        this.storage = app.storage();
        this.remoteConfig = app.remoteConfig();
    }

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
        localStorage.removeItem('authUser');
        this.auth.signOut();
    };

    doGetRemoteConfig =() => {
        return this.remoteConfig;
    }

    // doReauthenticateUser = (email,currentPw, newPw, passwordCallback) => {
    //     try{
    //         this.auth.signInWithEmailAndPassword(email, currentPw).then(response =>{
    //             try{
    //                 this.doPasswordUpdate(newPw).then(response => {
    //                     passwordCallback(true);
    //                     return;
    //                 })
    //             }
    //             catch{
    //                 passwordCallback(false);
    //                 return;
    //             }
    //         });
    //     }
    //     catch{
    //         passwordCallback(false);
    //         return;
    //     }
    // }

    doReauthenticateUser = (email,currentPw, newPw, passwordCallback) => {
        this.auth.signInWithEmailAndPassword(email,currentPw).then(() => {
            this.doPasswordUpdate(newPw).then(response => {
                passwordCallback(true);
                return;
            })
        }).catch(error => {
            //console.log(error);
            passwordCallback(false);
            return;
        });
    }

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
 
    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    doGetPreferences = uid => {
        var ref = this.db.ref(`UserPreferences/`);
        return this.db.ref(`UserPreferences/${uid}`);
    };

    doSetPreferences = (uid, prefs) => this.db.ref(`UserPreferences/${uid}`).set(prefs);

    doUploadAvatar = (image, avatarCallback) => {
        try{
            if(image != null){
                const uploadTask = this.storage.ref(`images/${image.name}`).put(image).then(response => {
                    this.storage.ref("images").child(image.name).getDownloadURL().then(photoURL => {
                        this.auth.currentUser.updateProfile({photoURL})
                        avatarCallback(photoURL);
                        return photoURL;
                    });
                });
            }
        }
        catch{
            //console.log("Error with uploading image");
            return null;
        }
        return null;
    };

    doSetProfile = (displayName) => {
        this.auth.currentUser.updateProfile({displayName})
    }
     
    user = uid => this.db.ref(`users/${uid}`);

    benzingaNews = () => { 
        try{
        return this.db.ref("/benzNews/").limitToLast(80).orderByChild('time');
        }catch(ex){
        }
    };
    stockActivity = (date) => { 
        try{
            return this.db.ref("/stockalerts/").child(moment(date).format("YYYY-MM-DD"));
        }catch(ex){
           return null;
        }
    };

    activity = date => { 
        try{
        if (date == undefined) return this.db.ref();
        return this.db.ref(`${date.format("YYYY-MM-DD")}`)
        }catch{
            var today = momenttz().tz('America/Los_Angeles');
            if (!moment(today).isBusinessDay() && !moment(date).isHoliday()) today = moment(today).prevBusinessDay();
            return this.db.ref(`${today.format("YYYY-MM-DD")}`);
        }
    };

    activitySample = async date => { 
        try{
            const res = await fetch("https://us-central1-awesomestocks-b4d80.cloudfunctions.net/app/freemium");
            const data = await res.json();
            const optionsList = Object.keys(data).map(key => ({
                ...data[key],
                }));
            return optionsList;
        }catch(ex){
            //console.log(ex);
        }
    };

    news = () => { 
        return this.db.ref(`News/`).limitToLast(20);
    };
}
 
export default Firebase;