import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
import typeStyles from "../../assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";


const useStyles = makeStyles(styles, typeStyles);

const PasswordForgetPage = (props) =>{
  
  const classes = useStyles();
  return(
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm {...props} classes={classes}/>
  </div>)
};

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.toggleView();
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <Button variant="outlined"
                    enabled={this.isValid}
                    color="#3f79b5"
                    size="lg" 
                    onClick={this.onSubmit}>
              Reset Password
          </Button>
 
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };