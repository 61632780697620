import React from 'react';
import { Input, CustomInput } from 'reactstrap';
import * as moment from 'moment';

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5, textAlign: 'center', color: 'white' }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
    id
  },
}) => {
  return (
    <Input name="select"
      style={{marginRight: '15px', textAlign: 'center', color: 'white' }}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    var overrideVal = null;
    preFilteredRows.forEach((row) => {
        if(id != "premium" && id != "sweepscore"){
            if(row.values[id] != null && row.values[id] != "" && row.values[id] != undefined){
                options.add(row.values[id]);
            }
        }else if(id == "sweepscore"){
          options.add("Above at ask");
          options.add("At the ask");
          options.add("Near the ask");
          options.add("At the mid");
          options.add("Near the bid");
          options.add("At the bid");
          options.add("Below the bid");
        }
        else if(id == "premium"){
          options.add("< 10k");
          options.add("10k-50k");
          options.add("50k-100k");
          options.add("100k-200k");
          options.add("200k-500k");
          options.add("500k-1M");
          options.add("> 1M");
        }
    });
    const values = [...options.values()];
    if(id === "date_expiration"){
      return values.sort((a, b) => new Date(a) - new Date(b))
    }else if (id=== "ticker"){
      return values.sort();
    }else if(id==="strike"){
      var strikeValues = values.sort((a, b) => parseFloat(a) - parseFloat(b));
      strikeValues.splice(0, 0, "All OTM");
      strikeValues.splice(0, 0, "All ITM");
      return strikeValues;
    }else if (id == "OI"){
      return values.sort((a, b) => parseFloat(b) - parseFloat(a));
    }else{
      return values;
    }
  }, [id, preFilteredRows]);

  return (
    <Input type="select" name="select"
      style={{marginRight: '15px', textAlign: 'center', color: 'white' }}
      id='custom-select'
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option style={{backgroundColor:"#262A2D"}} value=''>All</option>
      {options.map((option) => (
        <option style={{backgroundColor:"#262A2D"}} key={option} value={option}>
          {option}
        </option>
      ))}
    </Input>
  );
};