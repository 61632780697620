
import '../../assets/css/fonts.css';
import React, { Component, useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import logo from '../../assets/img/sweepcast_logo.png';
import * as ROUTES from '../../constants/routes';
import { withRouter } from '../App';
import {withFirebase} from '../Firebase'
import { compose } from 'recompose';
import { Grid, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      paddingRight: 30,
      paddingBottom: 20,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

function MenuComponent(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  
  const { ...rest } = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuLogOut = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuLogOut = () => {
      handleMenuClose();
      rest.firebase.doSignOut();
      navigate(ROUTES.LOGIN);
    };
  const handleMenuAccount = () => {
      handleMenuClose();
      navigate(ROUTES.ACCOUNT);
  };

  const handleTerms = () =>{
    window.open("https://www.sweepcast.com/terms");
    handleMenuClose();
  }
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'mobile-account-menu';
  const profileRef = React.createRef();
  const moreRef = React.createRef();
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuAccount}>My Account</MenuItem>
      <MenuItem onClick={handleTerms}>Terms</MenuItem>
      <MenuItem onClick={handleMenuLogOut}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div style={{height: 50}}>
      <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <img src={logo} alt="Logo" height="90" style={{paddingTop: 0, paddingLeft:0, marginBottom:10}}/>
          
          <div className={classes.sectionDesktop}>
            
          </div>
          <div className={classes.sectionMobile}>
          </div>
        </div>
      {renderMenu}
    </div>
  );
}

function AccountComponent(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  
  const { ...rest } = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const goBack = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div style={{height: 70}}>
      <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <img onClick={goBack} src={logo} alt="Logo" height="110" style={{paddingTop: 0, paddingLeft:20, marginBottom:10, cursor: 'pointer'}}/>
      </div>
    </div>
  );
}

const PrimarySearchAppBar = compose(
    withRouter,
    withFirebase,
  )(MenuComponent);

const SecondarySearchAppBar = compose(
    withRouter,
    withFirebase,
  )(AccountComponent);

export default PrimarySearchAppBar;

export { SecondarySearchAppBar };
  